<template>
	<div>
		<main class="bg-gray-50">
			<div>
				<slot />
			</div>
		</main>
	</div>
</template>

<script setup>
  
</script>