<template>
	<div :class="{'border-l-2 ml-2 sm:ml-4.5 border-gray-300 pt-4': related && !hideLine}">
		<router-link
			:to="{name: 'JobDetails', params: {recordId: job.recordId}}">
			<div 
				class="card group hover:bg-gray-50 cursor-pointer relative p-4"
				:class="{
					'rounded-br-none': job.relatedMeetings?.length, 
					'rounded-bl-none sm:rounded-bl-md': job.relatedMeetings?.length && related, 
					'ml-4.5 before:pointer-events-none before:absolute before:left-0 before:-ml-7 before:top-1/2 before:-translate-y-1/2 before:h-4 before:w-4 before:rounded-full before:bg-white before:border-gray-400 before:border-2': related && !hideLine}">
				<div class="grid grid-cols-12 gap-2 xl:gap-y-0">
					<div
						class="col-span-11 xl:col-span-3">
						<div
							class="flex justify-start items-center gap-2"
							:class="[related ? 'text-indigo-500 fill-indigo-500 group-hover:fill-indigo-600 group-hover:text-indigo-600' : 'text-brand-500 fill-brand-500 group-hover:fill-brand-600 group-hover:text-brand-600']">
							<img
								:class="[related ? 'grayed-icon' : 'linked-icon', 'h-6 w-6']"
								src="@/assets/icons/solid/jobs-fill.svg"
								alt="jobs icon">
							<h2
								class="text-lg truncate"
								:title="job.name">
								{{ job.name ?? job['job__JOBS__jobID::name_bride_groom_display'] }}
							</h2>
						</div>
						<div class="flex">
							<p class="font-bold text-sm">
								{{ job.date_start ?? job['job__JOBS__jobID::date_start'] }} <span>&#x2022;</span>
							</p>
							<p class="text-sm italic ml-1">
								{{ job.type_display ?? job['job__JOBS__jobID::type_display'] }} <span class="font-bold not-italic">&#x2022;</span>
							</p>
							<p class="text-sm italic ml-1">
								ID: {{ job.job_id ?? job.recordId }}
							</p>
						</div>
					</div>
					<div
						v-if="job?.relatedJobs?.length || job?.relatedMeetings?.length"
						class="col-span-1 xl:hidden lg:col-span-0 relative w-full h-full">
						<button 
							class="absolute top-0 right-0 p-4 -mt-4 -mr-4"
							@click.prevent="relatedExpanded = !relatedExpanded">
							<ChevronDownIcon
								class="w-5 h-5 transition-all ease-in-out"
								:class="{'-rotate-90': !relatedExpanded}" />
						</button>
					</div>

					<div class="col-span-4 lg:col-span-2 xl:col-span-1">
						<!-- category -->
						<data-display
							:label="store.getLabel('11')"
							:value="(job.category_display ?? job['job__JOBS__jobID::category_display']) || '—'" />
					</div>
					<div class="col-span-4 lg:col-span-2 xl:col-span-2">
						<!-- service -->
						<data-display
							:label="store.getLabel('173')"
							:value="(job.service ?? job['job__jobs__CONTACTS_SERVICES__contactServicesID::name_display']) || '—'" />
					</div>
					<div class="col-span-4 lg:col-span-2 xl:col-span-1">
						<!-- style -->
						<data-display
							:label="store.getLabel('129')"
							:value="job.style_name_display_override || job.style_name_display || job['job__jobs__contact_service__STYLE__styleID::name_display'] || job['jobs__jobs__contacts_services__services__STYLES__stylesID:name_display'] || '—'" />
					</div>
					<div class="col-span-4 lg:col-span-2 xl:col-span-1">
						<!-- theme -->
						<data-display
							:label="store.getLabel('130')"
							:value="job.theme_name_display_override || job.theme_name_display || job['job__jobs__contact_service__THEMES__themeID::name_display'] || job['jobs__jobs__contacts_services__services__THEMES__themesID::name_display'] || '—'" />
					</div>
					<div class="col-span-4 lg:col-span-2 xl:col-span-1">
						<!-- start time -->
						<data-display
							:label="store.getLabel('144')"
							:value="$filters.hour24Time(job.time_start || job['job__JOBS__jobID::time_start'])" />
					</div>
					<div class="col-span-4 lg:col-span-2 xl:col-span-2">
						<!-- end time -->
						<data-display
							:label="store.getLabel('322')"
							:value="$filters.hour24Time(job.time_end || job['job__JOBS__jobID::time_end'])" />
					</div>
					<div 
						v-if="job?.relatedJobs?.length || job?.relatedMeetings?.length"
						class="col-span-4 lg:col-span-3 hidden xl:block xl:col-span-1">
						<button 
							class="float-right h-full px-4 -mr-4"
							@click.prevent="relatedExpanded = !relatedExpanded">
							<ChevronDownIcon
								class="w-5 h-5 transition-all ease-in-out"
								:class="{'-rotate-90': !relatedExpanded}" />
						</button>
					</div>
				</div>
			</div>
		</router-link>	
	</div>

	<!-- Meetings -->
	<div class="overflow-hidden">
		<div
			class="transition-all duration-500 ease-in-out"
			:class="{'-mt-1000%': !relatedExpanded, 'mt-0': relatedExpanded}">
			<div
				v-for="(meeting, i) in job.relatedMeetings"
				:key="i">
				<div :class="{'border-l-2 ml-2 sm:ml-4.5 border-gray-300': job?.relatedJobs?.length}">
					<Meeting
						:meeting="meeting"
						class="rounded-t-none"
						:class="{'rounded-b-none': i < job.relatedMeetings.length - 1, 'ml-4.5 sm:ml-13.5': job?.relatedJobs?.length, 'ml-7 sm:ml-20': !job?.relatedJobs?.length}" />
				</div>
			</div>
		
			<!-- Related Jobs -->
			<div
				v-for="(relatedJob, i) in job.relatedJobs"
				:key="i">
				<Job
					:job="relatedJob"
					related />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobCard' } // name the component
</script>

<script setup lang="ts">
	import Meeting from './Meeting.vue' // components
	
	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		computed,
		ref
	} from 'vue'

	const props = defineProps({ 
		job: {
			type: Object,
			default: () => {return {}}
		},
		related: {
			type: Boolean,
			default: false
		},
		hideLine: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const relatedExpanded = ref<boolean>(true)
</script>