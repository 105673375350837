<template>
	<div class="-mt-10 -mb-4">
		<tw-table
			:placeholder="t('placeholders.noItems')"
			:data="groupedData"
			:loading="loading">
			<!-- borrowed from -->
			<tw-table-column 
				:header="store.getLabel('159')">
				<template #default="{row}">
					{{ row['JOBS::name_bride_display'] ||
						row['JOBS::name_groom_display'] ||
						row['job__borrowed__CONTACTS__contactID_employee::name_display'] ||
						row['job__borrowed__CONTACTS__contactID_planner::name_display']
					}}
				</template>
			</tw-table-column>
			<!-- description -->
			<tw-table-column 
				:header="store.getLabel('190')">
				<template #default="{row}">
					{{ row['job__BORROWED__jobID_isBorrowedBrideGroom::borrowed_description_display'] ||
						row['job__BORROWED__jobID_isBorrowedEmployee::borrowed_description_display'] ||
						row['job__BORROWED__jobID_isBorrowedPlanner::borrowed_description_display']
					}}
				</template>
			</tw-table-column>
			<!-- cd -->
			<tw-table-column 
				:header="store.getLabel('157')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__BORROWED__jobID_isBorrowedBrideGroom::cd'] ||
							row['job__BORROWED__jobID_isBorrowedEmployee::cd'] ||
							row['job__BORROWED__jobID_isBorrowedPlanner::cd']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>
			<!-- recieved by -->
			<tw-table-column 
				:header="store.getLabel('161')">
				<template #default="{row}">
					{{ row['job__borrowed__CONTACTS__contactID_receivedBrideGroom::name_display'] ||
						row['job__borrowed__CONTACTS__contactID_receivedEmployee::name_display'] ||
						row['job__borrowed__CONTACTS__contactID_receivedPlanner::name_display']
					}}
				</template>
			</tw-table-column>
			<!-- date recieved -->
			<tw-table-column 
				:header="store.getLabel('163')">
				<template #default="{row}">
					{{ row['job__BORROWED__jobID_isBorrowedBrideGroom::borrowed_date_received'] || 
						row['job__BORROWED__jobID_isBorrowedEmployee::borrowed_date_received'] ||
						row['job__BORROWED__jobID_isBorrowedPlanner::borrowed_date_received']
					}}
				</template>
			</tw-table-column>
			<!-- returned to -->
			<tw-table-column 
				:header="store.getLabel('162')">
				<template #default="{row}">
					{{ row['job__BORROWED__jobID_isBorrowedBrideGroom::borrowed_name_display'] ||
						row['job__BORROWED__jobID_isBorrowedEmployee::borrowed_name_display'] ||
						row['job__BORROWED__jobID_isBorrowedPlanner::borrowed_name_display']
					}}
				</template>
			</tw-table-column>
			<!-- date returned -->
			<tw-table-column 
				:header="store.getLabel('164')">
				<template #default="{row}">
					{{ row['job__BORROWED__jobID_isBorrowedBrideGroom::borrowed_date_returned'] ||
						row['job__BORROWED__jobID_isBorrowedEmployee::borrowed_date_returned'] ||
						row['job__BORROWED__jobID_isBorrowedPlanner::borrowed_date_returned']
					}}
				</template>
			</tw-table-column>
		</tw-table>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobBorrowedItems' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		computed
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const {t} = useI18n()

	const groupedData = computed(() => {
		let headings = []

		if(props?.job?.portalData?.portal_borrowed_bride_groom?.length) {
			headings.push(
				{subheading: 'Bride & Groom'},
				...props.job.portalData.portal_borrowed_bride_groom
			)
		}

		if(props?.job?.portalData?.portal_borrowed_employee?.length) {
			headings.push(	
				{subheading: 'Employees'},
				...props.job.portalData.portal_borrowed_employee
			)
		}

		if(props?.job?.portalData?.portal_borrowed_planner?.length) {
			headings.push(
				{subheading: 'Planners'},
				...props.job.portalData.portal_borrowed_planner
			)
		}

		return headings
	})


</script>