<template>
	<div 
		class="h-full w-full"
		:class="[props.noFill ? '' : 'card']">
		<button 
			@click="expanded = !expanded || fixed" 
			class="p-4 pb-0 w-full group cursor-pointer flex justify-between items-center">
			<h2 class="section-title group-hover:text-brand-600">
				{{ props.title }}
			</h2>
			<ChevronDownIcon
				v-if="!fixed"
				class="stroke-black stroke-2 ml-1 w-5 h-5 transition-all text-brand-500 ease-in-out group-hover:stroke-brand-600"
				:class="{'-rotate-90': !expanded}" />
		</button>
		<div class="overflow-auto pb-4">
			<div 
				class="accordian pt-4 w-full"
				:class="{'-mt-1000%': !expanded, 'mt-0': expanded}">
				<slot />
			</div>
		</div>
	</div>
</template>

<script lang="ts">
// name the component
	export default { name: 'CollapsableCard' }
</script>

<script setup lang="ts">

	// #region imports
	// Vue
	import {
		ref
	} from 'vue'
	// #endregion

	const props = defineProps({
		title: {
			type: String,
			default: ""
		},
		openByDefault: {
			type: Boolean,
			default: true
		},
		noFill: {
			type: Boolean,
			default: false
		},
		fixed: {
			type: Boolean,
			default: false
		}
	})

	const expanded = ref<boolean>(props.openByDefault)


</script>