<template>
	<div :class="[_userStore.getContactType == 'Staff' ? 'grid grid-cols-5 gap-4 px-4 mt-3' : 'grid grid-cols-4 gap-4 px-4 mt-3']">
		<div class="col-span-4 row-span-2 lg:col-span-2">
			<!-- Address -->
			<data-display				
				:label="store.getLabel('5')"
				value-class="whitespace-pre-line"				
				:value="addressDisplay || '-'" />
		</div>
		<!-- Date -->
		<div class="col-span-2 lg:col-span-1">
			<data-display
				:label="store.getLabel('361')"				
				:value="meeting?.fieldData?.StartDate" />
		</div>
		<!-- Type -->
		<div class="col-span-2 lg:col-span-1">
			<data-display
				:label="store.getLabel('1')"				
				:value="meeting?.fieldData?.Type_display" />
		</div>
		<!-- Status -->
		<div 
			v-if="_userStore.getContactType == 'Staff'"
			class="col-span-2 lg:col-span-1">
			<data-display
				:label="store.getLabel('196')"
				:value="meeting?.fieldData?.status_display" />
		</div>
		<!-- Start Time -->
		<div class="col-span-2 lg:col-span-1">
			<data-display
				:label="store.getLabel('144')"				
				:value="$filters.hour24Time(meeting?.fieldData?.StartTime) || '—'" />
		</div>
		<!-- End Time -->
		<div class="col-span-2 lg:col-span-1">
			<data-display
				:label="store.getLabel('322')"
				:value="$filters.hour24Time(meeting?.fieldData?.EndTime) || '—'" />
		</div>
		<!-- Completion Time -->
		<div 
			v-if="_userStore.getContactType == 'Staff'"
			class="col-span-2 lg:col-span-1">
			<data-display
				:label="store.getLabel('405')"
				:value="meeting?.fieldData?.CompletetionTime || '—'" />
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'MeetingDetailsLocation' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as userStore
	} from '@/store/user'

	import { // vue
		computed
	} from 'vue'

	const props = defineProps({
		meeting: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const _userStore = userStore()

	const addressDisplay = computed(() => {
		let address = []
		address.push(props.meeting?.fieldData?.building_name_to_display)
		address.push(props.meeting?.fieldData?.address_to_display)
		address.push(props.meeting?.fieldData?.city_to_display + " " + props.meeting?.fieldData?.state_to_display + " " + props.meeting?.fieldData?.postal_code_to_display)
		address.push(props.meeting?.fieldData?.country_to_display)

		let addressDisplay = address.filter(Boolean).join("\n")

		return addressDisplay
	})
</script>