<template>
	<!-- Global notification live region, render this permanently at the end of the document -->
	<div
		aria-live="assertive"
		class="fixed inset-0 flex px-6 py-2 pointer-events-none items-start z-50">
		<div class="w-full flex flex-col items-center space-y-4 sm:items-end">
			<!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
			<transition
				enter-active-class="transform ease-out duration-300 transition"
				enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
				enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
				leave-active-class="transition ease-in duration-100"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0">
				<div
					v-if="show"
					class="rounded-md bg-white border border-gray-200 max-w-md w-full shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
					<div class="p-4">
						<div class="flex items-start">
							<div
								v-if="icon == 0"
								class="flex-shrink-0">
								<CheckCircleIcon
									class="h-8 w-8 text-brand-500"
									aria-hidden="true" />
							</div>
							<div
								v-if="icon == 1"
								class="flex-shrink-0">
								<ExclamationCircleIcon
									class="h-8 w-8 text-red-500"
									aria-hidden="true" />
							</div>
							<div class="ml-3 w-0 flex-1">
								<p class="font-bold text-gray-900">
									{{ title }}
								</p>
								<p class="text-gray-500">
									{{ subtitle }}
								</p>
							</div>
							<div class="ml-4 flex flex-shrink-0">
								<button
									type="button"
									@click="close"
									class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-brand-500 focus:ring-offset-2">
									<span class="sr-only">Close</span>
									<XMarkIcon
										class="h-5 w-5"
										aria-hidden="true" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script lang="ts">
	// name the component
	export default { name: "TwNotification" }
</script>

<script setup lang="ts">
	// icons
	import {
		ExclamationCircleIcon,
		CheckCircleIcon
	} from '@heroicons/vue/24/outline'

	// vue
	import {
		defineEmits,
		defineProps,
		ref 
	} from 'vue'

	const props = defineProps({
		duration: {
			type: Number,
			default: 5000
		},
		icon: {
			type: Number,
			default: 1,
		},
		title: {
			type: String,
			default: ""
		},
		subtitle: {
			type: String,
			default: ""
		}
	})

	const emit = defineEmits([
		'close'
	])

	const show = ref(true)

	setTimeout(() => {
		close()
	}, props.duration)

	function close() {
		show.value = false
		emit('close')
	}
</script>
