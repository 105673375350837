<template>
	<div>
		<div>
			<tw-tabs-underline
				ref="childTabs"
				:tabs="tabs" />
		</div>
		<div v-show="childTabs?._tabs?.[showCovidRestrictions() ? 0 : 5]?.current">
			<div class="mt-6">
				<RoleCovidRestrictions
					:role="role" />
			</div>
		</div>
		<!-- hotel -->
		<div 
			v-if="_userStore.getContactType === 'Staff' && showCovidRestrictions() "
			v-show="childTabs?._tabs?.[showCovidRestrictions() ? 1 : 5]?.current">
			<div class="mt-6">
				<h2 class="section-title mt-4">
					{{ _localesStore.getLabel('685') }}
				</h2>
				<div class="grid grid-cols-4 gap-4 w-3/4 mt-1">
					<div class="col-span-1">
						<!-- hotel name -->				
						<data-display
							:label="_localesStore.getLabel('149')"
							:value="hotelName" />
					</div>					
					<div class="col-span-1">
						<!-- hotel address -->				
						<data-display
							:label="_localesStore.getLabel('5')"
							:value="hotelAddress" />
					</div>
					<div class="col-span-1">
						<!-- hotel phone -->				
						<data-display
							:label="_localesStore.getLabel('561')"
							:value="hotelPhone" />
					</div>
					<div class="col-span-1 mt-5">
						<!-- payment -->				
						<div
							class="flex justify-start gap-2 items-center"
							:class="{'opacity-30': !hotelPayment}">
							<CheckIconMini
								v-if="hotelPayment"
								class="h-5 w-5" />
							<MinusIconMini
								v-else
								class="h-5 w-5" />
							<span class="font-medium">{{ _localesStore.getLabel('448') }}</span>
						</div>
					</div>
				</div>
				<h2 class="section-title mt-12">
					{{ _localesStore.getLabel('185') }}
				</h2>
				<div class="grid grid-cols-3 gap-4 mt-1">
					<div class="border-r-2">
						<div class="grid grid-cols-2 gap-4">
							<!-- Transportation -->
							<div>
								<data-display
									:label="_localesStore.getLabel('185')"
									:value="transportationType" />
							</div>
							
							<!-- Transportation cost-->
							<div>				
								<data-display
									:label="_localesStore.getLabel('45')"
									:value="transportationCost || transportationCost === 0 ? '¥' + transportationCost : null" />
							</div>
						</div>
						<div class="grid grid-cols-3 mt-6">
							<!-- time-->
							<div>
								<data-display
									:label="_localesStore.getLabel('332')"
									:value="$filters.timeDuration(time)" />
							</div>
							
							<!-- Agreement confirmation -->
							<div v-if="!transportationType?.includes('Emergency')">
								<data-display
									:label="_localesStore.getLabel('32')"
									:value="agreement" />
							</div>

							<!-- Primary -->
							<div
								class="mt-5"
								v-if="!transportationType?.includes('Emergency')">
								<div
									class="flex justify-start gap-2 items-center"
									:class="{'opacity-30': !primary}">
									<CheckIconMini
										v-if="primary"
										class="h-5 w-5" />
									<MinusIconMini
										v-else
										class="h-5 w-5" />
									<span class="font-medium">{{ _localesStore.getLabel('292') }}</span>
								</div>
							</div>
						</div>

						<!-- multiple transportation table -->
						<div v-if="transportationMultiple.length">
							<tw-table
								:data="transportationMultiple">
								<!-- Type -->
								<tw-table-column
									:header="_localesStore.getLabel('1')">
									<template #default="{row}">
										{{ row["jobs_roles__TRANSPORTATION__multipleID::type_display"] }}
									</template>
								</tw-table-column>

								<!-- Cost -->
								<tw-table-column
									:header="_localesStore.getLabel('229')">
									<template #default="{row}">
										¥{{ row["jobs_roles__TRANSPORTATION__multipleID::cost"] }}
									</template>
								</tw-table-column>

								<!--Time-->
								<tw-table-column
									:header="_localesStore.getLabel('332')">
									<template #default="{row}">
										{{ $filters.timeDuration(row["jobs_roles__TRANSPORTATION__multipleID::time"]) }}
									</template>
								</tw-table-column>
							</tw-table>
						</div>

						<!-- emergency transportation table -->
						<div v-if="transportationType.includes('Emergency') && transportationEmergency.length">
							<tw-table
								:data="transportationEmergency">
								<!-- Type -->
								<tw-table-column
									:header="_localesStore.getLabel('1')">
									<template #default="{row}">
										{{ row["jobs_roles__TRANSPORTATION__jobsRolesID::type_display"] }}
									</template>
								</tw-table-column>

								<!-- Cost -->
								<tw-table-column
									:header="_localesStore.getLabel('229')">
									<template #default="{row}">
										¥{{ row["jobs_roles__TRANSPORTATION__jobsRolesID::cost"] }}
									</template>
								</tw-table-column>

								<!--Time-->
								<tw-table-column
									:header="_localesStore.getLabel('332')">
									<template #default="{row}">
										{{ $filters.timeDuration(row["jobs_roles__TRANSPORTATION__jobsRolesID::time"]) }}
									</template>
								</tw-table-column>
							</tw-table>
						</div>
					</div>		
					<div class="grid grid-cols-2 text-right m-auto border-r-2 mt-0">
						<!-- Role Cost-->			
						<data-display
							:label="_localesStore.getLabel('43')"				
							:value="$filters.jpy(roleCost)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Other-->
						<data-display
							:label="_localesStore.getLabel('716') + ' (1)'"
							:value="$filters.jpy(other1)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Total Before Taxes-->
						<data-display
							:label="_localesStore.getLabel('718')"
							:value="$filters.jpy(totalBeforeTaxes)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Consumption Tax-->
						<data-display
							:label="_localesStore.getLabel('372')"
							:value="$filters.jpy(consumptionTax)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Total Before Witholding-->
						<data-display
							:label="_localesStore.getLabel('717')"
							:value="$filters.jpy(totalBeforeWithholding)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Witholding Tax-->
						<data-display
							:label="_localesStore.getLabel('715')"
							:value="$filters.jpy(withholdingTax)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Subtotal-->
						<data-display
							:label="_localesStore.getLabel('373')"
							:value="$filters.jpy(subtotal)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Other -->
						<data-display
							:label="_localesStore.getLabel('716') + ' (2)'"
							:value="$filters.jpy(other2)"
							value-class="text-right pr-18"
							label-class="-ml-4" />

						<!-- Total Before Taxes-->
						<data-display
							:label="_localesStore.getLabel('374')"
							:value="$filters.jpy(grandTotal)"
							value-class="text-right pr-18"
							label-class="-ml-4" />
					</div>
					<div>
						<div class="grid grid-cols-1 gap-4">
							<!-- Memo 1-->
							<div>
								<data-display
									:label="_localesStore.getLabel('726') + ' (1)'"
									:value="memo1" />
							</div>
							<!-- Memo 2-->
							<div>
								<data-display
									:label="_localesStore.getLabel('726') + ' (2)'"
									:value="memo2" />
							</div>			
						</div>
						<div class="grid grid-cols-3 gap-4 mt-4 ">
							<div>
								<data-display
									:label="t('roles.additionalHotel')"
									:value="other2AdditionalHotel || other2AdditionalHotel === 0 ? '¥' + other2AdditionalHotel : null" />
							</div>
							<div>				
								<data-display
									:label="t('roles.additionalMeal')"
									:value="other2AdditionalMeals || other2AdditionalMeals === 0 ? '¥' + other2AdditionalMeals : null" />
							</div>
							<div>				
								<data-display
									:label="t('roles.additionalOther')"
									:value="other2AdditionalOther || other2AdditionalOther === 0 ? '¥' + other2AdditionalOther : null" />
							</div>
						</div>
						<div class="grid grid-cols-2 gap-4 mt-4 ">
							<div
								v-for="document in taxDocuments"
								:key="document.recordId">
								<a
									target="_blank"
									class="w-full truncate hover:opacity-70"
									:href="document?.signedUrl"
									download>
									<div
										v-if="document.ext == 'jpeg' || document.ext == 'jpg' || document.ext == 'png' || document.ext == 'gif'"
										class="flex bg-gray-100 border-2 border-gray-200  w-full h-24 m-auto text-brand-500 rounded-md">
										<img
											:src="document.signedUrl"
											class="w-full h-full object-cover rounded-md">
									</div>
									<div
										v-else
										class="flex bg-gray-100 border-2 border-gray-200  w-full h-24 m-auto text-brand-500 rounded-md">
										<img
											class="linked-icon w-12 m-auto"
											src="@/assets/icons/solid/document-fill.svg"
											alt="document icon">
									</div>
									<div class="text-gray-900 font-medium mt-2">
										{{ document['jobs_roles__DOCUMENTS__jobsRolesTaxesID::name_display'] }}
									</div>
									<div class="text-sm">
										{{ document['jobs_roles__DOCUMENTS__jobsRolesTaxesID::description_display'] }}
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- photo -->
		<div v-show="childTabs?._tabs?.[_userStore.getContactType === 'Client' ? 1 : showCovidRestrictions() && _userStore.getContactType == 'Staff' ? 2 : 0]?.current">
			<div class="mt-6">
				<data-display
					:label="_localesStore.getLabel('293')">
					<template #custom>
						<div
							v-if="loading"
							class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
						<img
							v-else-if="signedUrl"
							class="object-cover w-96 h-75 rounded-md"
							:src="signedUrl">
						<div
							v-else
							class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
							<img
								class="grayed-icon h-10 w-10"
								src="@/assets/icons/solid/image-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</data-display>
			</div>
		</div>

		<!-- Notes-->
		<div 
			v-if="_userStore.getContactType === 'Staff'"
			v-show="childTabs?._tabs?.[showCovidRestrictions() ? 3 : 5]?.current">
			<div class="mt-6">
				<data-display
					:label="_localesStore.getLabel('205')"
					:value="notes" />
			</div>
			<tw-table
				:loading="loadingRoleDocuments"
				:data="documentList">
				<!--Document-->
				<tw-table-column
					:header="_localesStore.getLabel('202')">
					<template #default="{row}">
						{{ row["jobs_roles__DOCUMENTS__jobsRolesID::name_display"] }}
					</template>
				</tw-table-column>

				<!--Description-->
				<tw-table-column
					:header="_localesStore.getLabel('190')">
					<template #default="{row}">
						{{ row["jobs_roles__DOCUMENTS__jobsRolesID::description_display"] }}
					</template>
				</tw-table-column>

				<!--created-->
				<tw-table-column
					:header="_localesStore.getLabel('445')">
					<template #default="{row}">
						{{ row["jobs_roles__DOCUMENTS__jobsRolesID::stamp_create"] }}
					</template>
				</tw-table-column>

				<!-- download -->
				<tw-table-column>
					<template #default="{row}">
						<a
							target="_blank"
							class="cursor-pointer"
							:href="row?.signedUrl"
							download>
							<img
								class="linked-icon h-6 w-6"
								src="@/assets/icons/solid/download-fill.svg"
								alt="download icon">
						</a>
					</template>
				</tw-table-column>
			</tw-table>
		</div>

		<!-- instructions -->
		<div 
			v-if="_userStore.getContactType === 'Staff'"
			v-show="childTabs?._tabs?.[showCovidRestrictions() ? 4 : 5]?.current">
			<tw-table
				:placeholder="t('placeholders.noInstructions')"
				:loading="loading"
				:data="instructionsList">
				<!-- name -->
				<tw-table-column 
					:header="_localesStore.getLabel('149')" 
					prop="jobs_roles__instructions_join_jobs__INSTRUCTIONS__instructionsID::name_display" />		

				<!-- Instructions -->
				<tw-table-column 
					:header="_localesStore.getLabel('181')"
					prop="jobs_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::instructions_display_edit" />

				<!-- picture -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div
							class="h-6 w-6 cursor-pointer"
							@click="showInstructions(row)">
							<img
								:class="[hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/image-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</tw-table-column>

				<!-- video -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div
							class="h-6 w-6 cursor-pointer"
							@click="showInstructions(row)">
							<img
								:class="[hasVideo(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/video-fill.svg"
								alt="video icon">
						</div>
					</template>
				</tw-table-column>
			</tw-table>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'RoleDetails' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getRoleDetailsByRecordId
	} from '../../../queries'

	import RoleCovidRestrictions from './RoleCovidRestrictions.vue' // components

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as userStore
	} from '@/store/user'

	import { // vue
		inject,
		onMounted,
		ref, 
		computed
	} from 'vue'

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		showInstructions:{
			type: Function,
			default: () => {return}
		},
		role: {
			type: Object,
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()
	const _userStore = userStore()

	const {t} = useI18n()

	interface tab {
		current: boolean,
		name: string
	}
	const tabs = ref<tab[]>()

	if(_userStore.getContactType === 'Staff') {		
		if(showCovidRestrictions()) {
			tabs.value = [
				{ name: _localesStore.getLabel('278'), current: false }, // COVID Restrictions
				{ name: _localesStore.getLabel('685') + " / " + _localesStore.getLabel('185'), current: false }, // Hotel and Transportation
				{ name: _localesStore.getLabel('293'), current: false },  // Picture
				{ name: _localesStore.getLabel('205') + " / " + _localesStore.getLabel('203'), current: false }, // Notes and Documents
				{ name: _localesStore.getLabel('181'), current: false }  // Instructions
			]
		}
		else{
			tabs.value =  [
				{ name: _localesStore.getLabel('293'), current: false },  // Picture
			]
		}
	} 
	else 
	{
		tabs.value =  [
			{ name: _localesStore.getLabel('278'), current: false }, // COVID Restrictions
			{ name: _localesStore.getLabel('293'), current: false },  // Picture
		]
	}

	function showCovidRestrictions() {
		if(_userStore.getContactType === 'Staff') {
			return props.role?.['job__JOBS_ROLES__liveJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId ||
				props.role?.['job__JOBS_ROLES__standardJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId
		}else{
			return true
		}
	}

	const childTabs = ref<{_tabs: []}>({_tabs: []})

	const instructionsList = ref()
	const documentList = ref()
	const taxDocuments = ref()
	const transportationMultiple = ref([])
	const transportationEmergency = ref([])
	
	const loading = ref(true)
	const loadingTaxDocuments = ref(true)
	const loadingRoleDocuments = ref(true)
	const s3: any = inject('s3')
	const fetchS3File: any = inject('fetchS3File')
	const signedUrl = ref('')

	onMounted(async () => {
		loading.value = true

		// set active tab
		tabs.value[props.openToTab].current = true

		try { // fetching role details
			if(props.role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId) {
				const scriptParams = JSON.stringify({
					language: _localesStore.getFmLanguage,
				})

				const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
				let res = await getRoleDetailsByRecordId(props?.role?.recordId, params)
				const record = res.response.data[0]

				instructionsList.value = record.portalData.jobs_roles__INSTRUCTIONS_JOIN_JOBS__jobsRolesID
				documentList.value = record.portalData.jobs_roles__DOCUMENTS__jobsRolesID
				taxDocuments.value = record.portalData.jobs_roles__DOCUMENTS__jobsRolesTaxesID
				transportationMultiple.value = record.portalData.jobs_roles__TRANSPORTATION__multipleID
				transportationEmergency.value = record.portalData.jobs_roles__TRANSPORTATION__jobsRolesID
			}
		}
		catch(e) {
			console.log(e)
		}

		try { // loading image from s3
			const s3Json = props.role?.['job__job_role__CONTACTS_ROLES__contactRoleID_standard_gStaffID::s3JSONPicture'] || 
				props.role?.['job__job_role__CONTACTS_ROLES__contactRoleID_instrumental_gStaffID::s3JSONPicture'] ||
				props.role?.['job__job_role__CONTACTS_ROLES__contactRoleID_live_gStaffID::s3JSONPicture'] || 
				props.role?.['job__job_role__CONTACTS_ROLES__contactRoleID_standard::s3JSONPicture'] || 
				props.role?.['job__job_role__CONTACTS_ROLES__contactRoleID_instrumental::s3JSONPicture'] ||
				props.role?.['job__job_role__CONTACTS_ROLES__contactRoleID_live::s3JSONPicture']
			signedUrl.value = await fetchS3File(s3Json)
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}

		fetchTaxDocuments()
		fetchRoleDocuments()
	})

	function fetchTaxDocuments() {
		try { // fetching tax document files from s3
			loadingRoleDocuments.value = true
			taxDocuments.value?.forEach((document: any) => {
				const documentS3Json = JSON.parse(document?.["jobs_roles__DOCUMENTS__jobsRolesTaxesID::s3JSONDocument"] || "{}")
				const documentKey = documentS3Json?.s3FilePathShortRaw
				const ext = documentS3Json?.fileExt
				document.ext = ext

				if(documentKey) {
					let params = {
						Bucket: process.env.VUE_APP_S3_BUCKET,
						Key: documentKey
					}
		
					s3.getSignedUrlPromise('getObject', params).then(
						function (url: string) {
							document.signedUrl = url
						}, 
						function (err: Error) { 
							throw err
						}
					)
				}
			})
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loadingRoleDocuments.value = false
		}
	}

	function fetchRoleDocuments() {
		try { // fetching role document files from s3
			loadingTaxDocuments.value = true
			documentList.value?.forEach((document: any) => {
				const documentS3Json = JSON.parse(document?.["jobs_roles__DOCUMENTS__jobsRolesID::s3JSONDocument"] || "{}")
				const documentKey = documentS3Json?.s3FilePathShortRaw
				const ext = documentS3Json?.fileExt
				document.ext = ext

				if(documentKey) {
					let params = {
						Bucket: process.env.VUE_APP_S3_BUCKET,
						Key: documentKey
					}
		
					s3.getSignedUrlPromise('getObject', params).then(
						function (url: string) {
							document.signedUrl = url
						}, 
						function (err: Error) { 
							throw err
						}
					)
				}
			})
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loadingTaxDocuments.value = false
		}
	}

	const transportationType = computed(() => {
		return emergencyTransportation.value ? _localesStore.getLabel('684') : props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_instrumental_transportationID::type_display"] || 
			props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_standard_transportationID::type_display"] || 
			props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_live_transportationID::type_display"]
	})

	const transportationCost = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::cost_transportation"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::cost_transportation"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::cost_transportation"] ||
			props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::cost_emergency"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::cost_emergency"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::cost_emergency"]
	})

	const time = computed(() => {
		if(props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_instrumental_transportationID::type_display"] === 'Multiple' || 
			props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_standard_transportationID::type_display"] === 'Multiple' || 
			props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_live_transportationID::type_display"] === 'Multiple') {

			return props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_instrumental_transportationID::time_multiple"] || 
				props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_standard_transportationID::time_multiple"] || 
				props.role?.["job_jobs_roles__TRANSPORTATION__gStaffID_live_transportationID::time_multiple"]
		}
		else {
			return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::time"] || 
				props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::time"] || 
				props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::time"] ||
				props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::time_emergency"] || 
				props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::time_emergency"] || 
				props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::time_emergency"]
				
		}
	})

	const agreement = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::date_last_checked"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::date_last_checked"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::date_last_checked"]
	})

	const primary = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::primary"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::primary"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::primary"]
	})

	const emergencyTransportation = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::emergency_transport"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::emergency_transport"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::emergency_transport"]
	})

	const roleCost = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::role_cost"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::role_cost"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::role_cost"]
	})

	const other1 = computed(() => {
		if(props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::other1_withholding"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::other1_withholding"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::other1_withholding"]) {

			return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::other1_withholding"] ??
				props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::other1_withholding"] ??
				props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::other1_withholding"]
		}else{
			return null
		}		
	})

	const other2 = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::other2_withholding_total"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::other2_withholding_total"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::other2_withholding_total"]
	})

	const totalBeforeTaxes = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::total_before_tax"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::total_before_tax"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::total_before_tax"]
	})

	const consumptionTax = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::consumption_withholding"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::consumption_withholding"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::consumption_withholding"]
	})

	const totalBeforeWithholding = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::total_before_taxes_witholding"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::total_before_taxes_witholding"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::total_before_taxes_witholding"]
	})

	const withholdingTax = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::withholding_amount"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::withholding_anmount"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::withholding_amount"]
	})

	const subtotal = computed(() => {
		return props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::subtotal_withholding"] ??
			props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::subtotal_withholding"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::subtotal_withholding"]
	})

	const grandTotal = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::grand_total_withholding"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::grand_total_withholding"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::grand_total_withholding"]
	})

	const memo1 = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::memo_display_other1"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::memo_display_other1"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::memo_display_other1"]
	})

	const memo2 = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::memo_display_other2"] ??
			props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::memo_display_other2"] ??
			props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::memo_display_other2"]
	})

	const hotelName = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::hotel_name"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::hotel_name"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::hotel_name"]
	})

	const hotelAddress = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::hotel_address"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::hotel_address"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::hotel_address"]
	})

	const hotelPhone = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::hotel_phone"] ??
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::hotel_phone"] ??
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::hotel_phone"]
	})

	const hotelPayment = computed(() => {
		if(props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::hotel_payment"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::hotel_payment"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::hotel_payment"]) {

			return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::hotel_payment"] ??
				props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::hotel_payment"] ??
				props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::hotel_payment"]
		}
		else{
			return null
		}
	})

	const notes = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::notes_display"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::notes_display"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::notes_display"]
	})

	const other2AdditionalHotel = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::hotel"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::hotel"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::hotel"]
	})

	const other2AdditionalMeals = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::meals"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::meals"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::meals"]
	})

	const other2AdditionalOther = computed(() => {
		return props.role?.["job__JOBS_ROLES__instrumentalJobID_gStaffID::other2_withholding"] || 
			props.role?.["job__JOBS_ROLES__standardJobID_gStaffID::other2_withholding"] || 
			props.role?.["job__JOBS_ROLES__liveJobID_gStaffID::other2_withholding"]
	})

	function hasPicture(row: any) {
		return row?.['jobs_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::s3JSONPicture']
	}

	function hasVideo(row: any) {
		return row?.['jobs_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::s3JSONVideo']
	}
</script>