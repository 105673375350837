<template>
	<div class="-mt-10 -mb-4">
		<tw-table
			:placeholder="t('placeholders.noRoles')"
			:data="groupedData"
			:loading="loading">
			<!-- role -->
			<tw-table-column 
				:header="_localesStore.getLabel('102')">
				<template #default="{row}">
					{{ row['job__job_role__ROLES__roleID_standard::name_display'] ||
						row['job__job_role__ROLES__roleID_instrumental::name_display'] ||
						row['job__job_role__ROLES__roleID_live::name_display'] ||
						row['job__job_role__ROLES__roleID_standard_gStaffID::name_display'] ||
						row['job__job_role__ROLES__roleID_instrumental_gStaffID::name_display'] ||
						row['job__job_role__ROLES__roleID_live_gStaffID::name_display']

					}}
				</template>
			</tw-table-column>

			<!-- name  -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'" 
				:header="_localesStore.getLabel('149')">
				<template #default="{row}">
					<div>			
						{{ row['job__job_role__CONTACTS__instrumental_staff_contactsPrimaryId::name_display'] ||
							row['job__job_role__CONTACTS__live_staff_contactsPrimaryId::name_display'] ||
							row['job__job_role__CONTACTS__standard_staff_contactsPrimaryId::name_display'] ||
							row['job__job_role__CONTACTS__instrumental_contactsPrimaryId::name_display'] ||
							row['job__job_role__CONTACTS__live_contactsPrimaryId::name_display'] ||
							row['job__job_role__CONTACTS__standard_contactsPrimaryId::name_display'] || "—"
						}}
					</div>
				</template>
			</tw-table-column>

			<!-- standard -->
			<tw-table-column 
				:header="_localesStore.getLabel('411')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__JOBS_ROLES__standardJobID::standard'] ||
							row['job__JOBS_ROLES__instrumentalJobID::standard'] ||
							row['job__JOBS_ROLES__liveJobID::standard'] ||
							row['job__JOBS_ROLES__standardJobID_gStaffID::standard'] ||
							row['job__JOBS_ROLES__instrumentalJobID_gStaffID::standard'] ||
							row['job__JOBS_ROLES__liveJobID_gStaffID::standard']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<!-- status  -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'" 
				:header="_localesStore.getLabel('196')">
				<template #default="{row}">
					<div>			
						{{ row['job__JOBS_ROLES__standardJobID::status_display'] ||
							row['job__JOBS_ROLES__instrumentalJobID::status_display'] ||
							row['job__JOBS_ROLES__liveJobID::status_display'] ||
							row['job__JOBS_ROLES__standardJobID_gStaffID::status_display'] ||
							row['job__JOBS_ROLES__instrumentalJobID_gStaffID::status_display'] ||
							row['job__JOBS_ROLES__liveJobID_gStaffID::status_display'] || "—"
						}}
					</div>
				</template>
			</tw-table-column>

			<!-- payment -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'" 
				:header="_localesStore.getLabel('448')">
				<template #default="{row}">
					<div>
						{{ $filters.jpy(row['job__JOBS_ROLES__instrumentalJobID_gStaffID::role_cost'] ||
							row['job__JOBS_ROLES__standardJobID_gStaffID::role_cost'] ||
							row['job__JOBS_ROLES__liveJobID_gStaffID::role_cost']) || "—"
						}}
					</div>
				</template>
			</tw-table-column>

			<!-- confirmed -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'" 
				:header="_localesStore.getLabel('262')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__JOBS_ROLES__standardJobID::confirmed'] ||
							row['job__JOBS_ROLES__instrumentalJobID::confirmed'] ||
							row['job__JOBS_ROLES__liveJobID::confirmed'] ||
							row['job__JOBS_ROLES__standardJobID_gStaffID::confirmed'] ||
							row['job__JOBS_ROLES__instrumentalJobID_gStaffID::confirmed'] ||
							row['job__JOBS_ROLES__liveJobID_gStaffID::confirmed']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<!-- PICKUP -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'" 
				:header="_localesStore.getLabel('415')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__JOBS_ROLES__standardJobID::pickup'] ||
							row['job__JOBS_ROLES__instrumentalJobID::pickup'] ||
							row['job__JOBS_ROLES__liveJobID::pickup'] ||
							row['job__JOBS_ROLES__standardJobID_gStaffID::pickup'] ||
							row['job__JOBS_ROLES__instrumentalJobID_gStaffID::pickup'] ||
							row['job__JOBS_ROLES__liveJobID_gStaffID::pickup']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<!-- time left -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'" 
				:header="_localesStore.getLabel('263')">
				<template #default="{row}">
					<div>
						{{ $filters.hour24Time(row['job__JOBS_ROLES__standardJobID::time_left'] ||
							row['job__JOBS_ROLES__instrumentalJobID::time_left'] ||
							row['job__JOBS_ROLES__liveJobID::time_left'] ||
							row['job__JOBS_ROLES__standardJobID_gStaffID::time_left'] ||
							row['job__JOBS_ROLES__instrumentalJobID_gStaffID::time_left'] ||
							row['job__JOBS_ROLES__liveJobID_gStaffID::time_left']) || "—"
						}}
					</div>
				</template>
			</tw-table-column>

			<!-- time arrived -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'" 
				:header="_localesStore.getLabel('264')">
				<template #default="{row}">
					<div>					
						{{ $filters.hour24Time(row['job__JOBS_ROLES__standardJobID::time_arrived'] ||
							row['job__JOBS_ROLES__instrumentalJobID::time_arrived'] ||
							row['job__JOBS_ROLES__liveJobID::time_arrived'] ||
							row['job__JOBS_ROLES__standardJobID_gStaffID::time_arrived'] ||
							row['job__JOBS_ROLES__instrumentalJobID_gStaffID::time_arrived'] ||
							row['job__JOBS_ROLES__liveJobID_gStaffID::time_arrived']) || "—"
						}}
					</div>
				</template>
			</tw-table-column>

			<!-- covid restrictions -->
			<tw-table-column				
				align-text="right">
				<template #default="{row}">
					<div
						v-if="isSignedInStaffRole(row)"
						:class="[hasCovidRestrictions(row) ? 'link' : 'text-gray-500', 'cursor-pointer']"
						@click="openModalDetails(row, 0)">
						{{ _localesStore.getLabel('278') }}...
					</div>
				</template>
			</tw-table-column>

			<!-- hotel -->
			<tw-table-column
				v-if="_userStore.getContactType === 'Staff'"
				class-name="w-6 "
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						v-show="isSignedInStaffRole(row)"
						class="h-6 w-6 cursor-pointer"
						@click="restrictedModalOpen(row, isSignedInStaffRole(row) ? 1 : 0)">
						<img
							:class="hasTransportation(row) ? 'linked-icon' : 'grayed-icon'"
							src="@/assets/icons/solid/transportation-fill.svg"
							alt="list icon">
					</div>
				</template>
			</tw-table-column>
					
			<!-- picture -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(row, _userStore.getContactType === 'Client' ? 1 : isSignedInStaffRole(row) ? 2 : 0)">
						<img
							:class="[hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</tw-table-column>

			<!-- documents -->
			<tw-table-column
				v-if="_userStore.getContactType === 'Staff'"
				class-name="w-6 "
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						v-show="isSignedInStaffRole(row)"
						class="h-6 w-6 cursor-pointer"
						@click="restrictedModalOpen(row, isSignedInStaffRole(row) ? 3 : 1)">
						<img
							:class="[hasDocuments(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/document-fill.svg"
							alt="document icon">
					</div>
				</template>
			</tw-table-column>

			<!-- instructions -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff' "
				class-name="w-5"
				header-class-name="w-5"
				align-text="right">
				<template #default="{row}">
					<div
						v-show="isSignedInStaffRole(row)"
						class="w-6 h-6"
						@click="restrictedModalOpen(row, 4)">
						<img
							:class="[hasInstructions(row) ? 'linked-icon cursor-pointer ' : 'grayed-icon']"
							src="@/assets/icons/solid/list-bullet-fill.svg"
							alt="list icon">
					</div>
				</template>
			</tw-table-column>
		</tw-table>

		<tw-modal
			:title="modalTitle"
			:breadcrumb="roleDetailsBreadcrumb"
			:close-modal="modalClose"
			ref="roleDetailsModal">
			<RoleDetails
				v-if="currentModal === 'roleDetails'" 
				:role="selectedRole"
				:show-instructions="showInstructions"
				:open-to-tab="openToTab" />
			<InstructionDetails
				v-else-if="currentModal === 'instructionDetails'" 
				:instruction="currentInstruction" 
				:show-details="openModalDetails"
				:role="selectedRole" />
		</tw-modal>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobRoles' } // name the component
</script>

<script setup lang="ts">
	import RoleDetails from './modals/roles/RoleDetails.vue' // components
	import InstructionDetails from './modals/roles/InstructionsDetails.vue'

	import { // language
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as userStore
	} from '@/store/user'

	import { // vue
		computed,
		ref
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})
	
	const _localesStore = localesStore()
	const _userStore = userStore()

	const {t} = useI18n()

	const roleDetailsBreadcrumb = computed(() => {
		if(currentModal.value === 'roleDetails') {
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.job?.fieldData?.name_bride_groom_display, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('179'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // Roles
			]
		}else{
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.job?.fieldData?.name_bride_groom_display, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('179'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // Roles
				{label: roleTitle, to: ""}, // Roles
				{label: _localesStore.getLabel('181'), to: ""}, // Roles
			]
		}
		
	})

	const selectedRole = ref({})
	const openToTab = ref(0)
	const roleDetailsModal = ref<{open: boolean}>({open: false})
	const currentModal = ref("roleDetails")
	const currentInstruction = ref()

	function showInstructions(row: any) {
		currentInstruction.value = row
		currentModal.value = "instructionDetails"
	}	

	const groupedData = computed(() => {
		let headings = []

		if(props?.job?.portalData?.portal_standard_roles?.length || props?.job?.portalData?.portal_standard_roles_staff?.length) {
			headings.push(
				{subheading: _localesStore.getLabel('393')},
				...props?.job?.portalData?.portal_standard_roles ?? [],
				...props?.job?.portalData?.portal_standard_roles_staff ?? []
			)
		}

		if(props?.job?.portalData?.portal_musician_roles?.length || props?.job?.portalData?.portal_musician_role_staff?.length) {
			headings.push(
				{subheading: _localesStore.getLabel('390')},
				...props?.job?.portalData?.portal_musician_roles ?? [],
				...props?.job?.portalData?.portal_musician_role_staff ?? []
			)
		}

		if(props?.job?.portalData?.portal_live_roles?.length || props?.job?.portalData?.portal_live_role_staff?.length) {
			headings.push(
				{subheading: _localesStore.getLabel('563')},
				...props?.job?.portalData?.portal_live_roles ?? [],
				...props?.job?.portalData?.portal_live_role_staff ?? []
			)
		}
		return headings
	})

	const modalTitle = computed(() => {
		if(currentModal.value === 'roleDetails') {
			return  selectedRole.value?.['job__job_role__ROLES__roleID_standard::name_display'] ||
				selectedRole.value?.['job__job_role__ROLES__roleID_instrumental::name_display'] ||
				selectedRole.value?.['job__job_role__ROLES__roleID_live::name_display'] || 
				selectedRole.value?.['job__job_role__ROLES__roleID_standard_gStaffID::name_display'] ||
				selectedRole.value?.['job__job_role__ROLES__roleID_instrumental_gStaffID::name_display'] ||
				selectedRole.value?.['job__job_role__ROLES__roleID_live_gStaffID::name_display']
		}
		else {
			return currentInstruction.value?.['jobs_roles__instructions_join_jobs__INSTRUCTIONS__instructionsID::name_display']
		}		
	})

	const roleTitle = computed(() => {
		return selectedRole.value?.['job__job_role__ROLES__roleID_standard::name_display'] ||
			selectedRole.value?.['job__job_role__ROLES__roleID_instrumental::name_display'] ||
			selectedRole.value?.['job__job_role__ROLES__roleID_live::name_display'] || 
			selectedRole.value?.['job__job_role__ROLES__roleID_standard_gStaffID::name_display'] ||
			selectedRole.value?.['job__job_role__ROLES__roleID_instrumental_gStaffID::name_display'] ||
			selectedRole.value?.['job__job_role__ROLES__roleID_live_gStaffID::name_display']		
	})
	
	function openModalDetails(row: any, tab: number) {
		selectedRole.value = row
		openToTab.value = tab
		currentModal.value = "roleDetails"
		roleDetailsModal.value.open = true
	}

	function restrictedModalOpen(row: any, tab: number) {
		if(isSignedInStaffRole(row)) {
			selectedRole.value = row
			openToTab.value = tab
			currentModal.value = "roleDetails"
			roleDetailsModal.value.open = true
		}
	}
	function modalClose() {
		roleDetailsModal.value.open = false
	}
	
	function hasCovidRestrictions(row: any) {
		if(_userStore.getContactType === 'Staff') {
			return row?.['job__JOBS_ROLES__standardJobID_gStaffID::covid_service'] ||
				row?.['job__JOBS_ROLES__standardJobID_gStaffID::covid_rehearsal'] ||
				row?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::covid_rehearsal'] ||
				row?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::covid_service'] ||
				row?.['job__JOBS_ROLES__liveJobID_gStaffID::covid_rehearsal'] ||
				row?.['job__JOBS_ROLES__liveJobID_gStaffID::covid_service']
		}
		else {
			return row?.['job__JOBS_ROLES__standardJobID::covid_service'] ||
				row?.['job__JOBS_ROLES__instrumentalJobID::covid_service'] ||
				row?.['job__JOBS_ROLES__liveJobID::covid_service'] || 
				row?.['job__JOBS_ROLES__standardJobID::covid_rehearsal'] ||
				row?.['job__JOBS_ROLES__instrumentalJobID::covid_rehearsal'] ||
				row?.['job__JOBS_ROLES__liveJobID::covid_rehearsal'] 
		}
	}
	
	function isSignedInStaffRole(row: any) {
		if(_userStore.getContactType === 'Staff') {
			return row?.['job__JOBS_ROLES__liveJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId ||
				row?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId ||
				row?.['job__JOBS_ROLES__standardJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId
		}
		else {
			return true
		}
	}

	function hasPicture(row: any) {
		return row?.['job__job_role__CONTACTS_ROLES__contactRoleID_standard_gStaffID::s3JSONPicture'] || 
			row?.['job__job_role__CONTACTS_ROLES__contactRoleID_instrumental_gStaffID::s3JSONPicture'] || 
			row?.['job__job_role__CONTACTS_ROLES__contactRoleID_live_gStaffID::s3JSONPicture'] || 
			row?.['job__job_role__CONTACTS_ROLES__contactRoleID_live::s3JSONPicture'] || 
			row?.['job__job_role__CONTACTS_ROLES__contactRoleID_standard::s3JSONPicture'] || 
			row?.['job__job_role__CONTACTS_ROLES__contactRoleID_instrumental::s3JSONPicture']
	}

	function hasInstructions(row: any) {
		return row?.["job__job_role__INSTRUCTIONS_JOIN_JOBS__jobsRolesID_live_gStaffID::_kp_instructions_join_jobs_id"] ||
			row?.["job__job_role__INSTRUCTIONS_JOIN_JOBS__jobsRolesID_standard_gStaffID::_kp_instructions_join_jobs_id"] ||
			row?.["job__job_role__INSTRUCTIONS_JOIN_JOBS__jobsRolesID_instrumental_gStaffID::_kp_instructions_join_jobs_id"] 
	}

	function hasDocuments(row: any)	{
		return row?.["job__job_role__DOCUMENTS__contactRoleID_instrumental_gStaffID::_kp_documents_id"] ||
			row?.["job__job_role__DOCUMENTS__contactRoleID_live_gStaffID"] ||
			row?.["job__job_role__DOCUMENTS__contactRoleID_standard_gStaffID"] 
	}
	

	function hasTransportation(row: any) {
		return row?.['job_jobs_roles__TRANSPORTATION__gStaffID_instrumental_transportationID::type_display'] || 
			row?.['job_jobs_roles__TRANSPORTATION__gStaffID_standard_transportationID::type_display'] || 
			row?.['job_jobs_roles__TRANSPORTATION__gStaffID_live_transportationID::type_display'] || 
			row?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::hotel'] || 
			row?.['job__JOBS_ROLES__standardJobID_gStaffID::hotel'] || 
			row?.['job__JOBS_ROLES__liveJobID_gStaffID::hotel']
	}
</script>