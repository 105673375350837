import { 
	Route
 } from '@/types' // must be imported since file contains an export statement
 import {
	config
} from "./config"


export const route: Route[] = [
    {
		path: config.mainRoute.path,	
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			layout: config.mainRoute.layout,
			module: config.module
		}
    }
]