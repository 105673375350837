<template>
	<div 
		class="link">
		<div
			@click="showDetails(parent, 'optionsTabs', 1, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToRoles") }}</span>
			</div>
		</div>
	</div>
	<tw-table
		:placeholder="t('placeholders.noInstructions')"
		:data="instructionList"
		:loading="loading">
		<!-- name -->
		<tw-table-column 
			:header="_localesStore.getLabel('149')"
			prop="jobs_scenes_options_roles__instructions_join_jobs__INSTRUCTIONS__instructionID::name_display" />
		
		<!-- Instructions -->
		<tw-table-column 
			:header="_localesStore.getLabel('181')"
			prop="jobs_scenes_options_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesOptionsRolesID::instructions_display_edit" />

		<!-- picture -->
		<tw-table-column
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<div
					class="h-6 w-6 cursor-pointer"
					@click="showDetails(row, 'opRolesInstructions', null, data)">
					<img
						:class="[hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
						src="@/assets/icons/solid/image-fill.svg"
						alt="picture icon">
				</div>
			</template>
		</tw-table-column>

		<!-- video -->
		<tw-table-column
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<div
					class="h-6 w-6 cursor-pointer"
					@click="showDetails(row, 'opRolesInstructions', null, data)">
					<img
						:class="[hasVideo(row) ? 'linked-icon' : 'grayed-icon']"
						src="@/assets/icons/solid/video-fill.svg"
						alt="picture icon">
				</div>
			</template>
		</tw-table-column>	
	</tw-table>
</template>

<script lang="ts">
	export default { name: 'InstructionsList' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneOptionsRolesInstructionsByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // vue
		ref,
		onMounted
	} from 'vue'

	import { // store
		localesStore
	} from '@/store/locales'

	const props = defineProps({
		data: {
			type: Object,
			default: () => {return {}}
		},
		showDetails: {
			type: Function, 
			default: () => {return}
		},
		previous: {
			type: Object, 
			default: () => {return {}}
		},
		parent: {
			type: Object, 
			default: () => {return {}}
		}
	})

	const {t} = useI18n()

	const instructionList = ref()
	const loading = ref(false)

	onMounted(async () => {
		try { // getting instructions
			loading.value = true
			const recordId = props.data.recordId

			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			let res = await getSceneOptionsRolesInstructionsByRecordId(recordId, params)
			const record = res.response.data[0]
						
			instructionList.value = record.portalData.jobs_scenes_options_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesOptionsRolesID
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})

	const _localesStore = localesStore()
	
	
	function hasPicture(row: any) {
		return row?.['jobs_scenes_options_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesOptionsRolesID::s3JSONPicture']
	}

	function hasVideo(row: any) {
		return row?.['jobs_scenes_options_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesOptionsRolesID::s3JSONVideo']
	}
</script>