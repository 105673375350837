<template>
	<div class="-mt-10 -mb-4">
		<tw-table
			:placeholder="t('placeholders.noItems')"
			:data="props?.job?.portalData?.portal_items"
			:loading="loading">
			<!-- item -->
			<tw-table-column 
				:header="store.getLabel('186')" 
				prop="job__job_item__ITEMS_UNIQUE__itemsUniqueID::item_display" />
			<!-- standard -->
			<tw-table-column 
				:header="store.getLabel('411')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__job_item__CONTACTS_SERVICES_ITEMS__contactServicesItemID::standard']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>
		</tw-table>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobItems' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const {t} = useI18n()
</script>