<template>
	<div 
		class="link">
		<div
			@click="showDetails(previous, 'opRolesInstructionsList', null, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToInstructions") }}</span>
			</div>
		</div>
	</div>
	<div class="my-8">
		<data-display
			:label="_localesStore.getLabel('181')"
			:value="instruction['jobs_scenes_options_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesOptionsRolesID::instructions_display_edit'] || '—'" />
	</div>
	<div class="grid grid-cols-3 gap-6">
		<div class="col-span-3 lg:col-span-1">
			<!-- photo -->
			<data-display
				:label="_localesStore.getLabel('293')">
				<template #custom>
					<div
						v-if="loading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<img
						v-else-if="signedUrlPhoto"
						class="object-cover w-96 h-75 rounded-md"
						:src="signedUrlPhoto">
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-3 lg:col-span-1">
			<!-- video -->
			<data-display
				:label="_localesStore.getLabel('294')">
				<template #custom>
					<div
						v-if="loading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<video
						v-else-if="signedUrlVideo"
						controls
						class="object-cover w-96 h-75 rounded-md">
						<source
							type="video/mp4"
							:src="signedUrlVideo">
					</video>
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/video-fill.svg"
							alt="video icon">
					</div>
				</template>
			</data-display>
		</div>	
	</div>
</template>

<script lang="ts">
	export default { name: 'InstructionDetails' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store 
		localesStore
	} from '@/store/locales'

	import { // vue
		onMounted,
		ref,
		inject
	} from 'vue'

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		instruction: {
			type: Object,
			default: () => {return {}}
		},
		previous: {
			type: Object,
			default: () => {return {}}
		},
		showDetails: {
			type: Function, 
			default: () => {return}
		}
	})

	const _localesStore = localesStore()

	const {t} = useI18n()

	const loading = ref(false)
	const fetchS3File: any = inject('fetchS3File')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	onMounted(async () => {
		try { // getting files from s3
			loading.value = true
			const s3PhotoField = props.instruction?.['jobs_scenes_options_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesOptionsRolesID::s3JSONPicture']
			const s3VideoField = props.instruction?.['jobs_scenes_options_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesOptionsRolesID::s3JSONVideo']
			signedUrlPhoto.value = await fetchS3File(s3PhotoField)
			signedUrlVideo.value = await fetchS3File(s3VideoField)
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loading.value = false
		}
		
	})
</script>