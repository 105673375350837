<template>
	<div>
		<h1 class="text-2xl font-medium text-gray-900 ">
			{{ t('updatePassword.title') }}
		</h1>
		<p class="mt-4 ">
			{{ t('updatePassword.instructions') }}
		</p>
	</div>

	<form
		class="mt-8"
		@submit.prevent="updatePassword">
		<div class="space-y-4">
			<div class=" text-sm">
				<tw-input
					v-model="email"
					id="email"
					:required="true"
					type="email"
					:label="t('login.email')"
					:validation="t('login.emailValidation')"
					class="text-md" />
			</div>
			<div class=" text-sm">
				<tw-input
					v-model="currentPassword"
					id="currentPassword"
					ref="inputPassword"
					:required="true"
					type="password"
					:label="t('updatePassword.currentPassword')"
					:validation="t('updatePassword.currentPasswordValidation')"
					class="text-md">
					<template #trailing_inline>
						<button
							type="button"
							@click="toggleShowCurrentPassword" 
							class="border-none bg-transparent hover:bg-transparent ">
							<EyeIcon
								v-if="!showCurrentPassword"
								class="h-5" />
							<EyeSlashIcon
								v-else
								class="h-5" />
						</button>
					</template>
				</tw-input>
			</div>
			<div class=" text-sm">
				<tw-input
					v-model="newPassword"
					id="newPassword"
					ref="inputNewPassword"
					:required="true"
					type="password"
					:label="t('updatePassword.newPassword')"
					:validation="t('updatePassword.newPasswordValidation')"
					class="text-md">
					<template #trailing_inline>
						<button
							type="button"
							@click="toggleShowNewPassword" 
							class="border-none bg-transparent hover:bg-transparent ">
							<EyeIcon
								v-if="!showNewPassword"
								class="h-5" />
							<EyeSlashIcon
								v-else
								class="h-5" />
						</button>
					</template>
				</tw-input>
			</div>
			<div class=" text-sm">
				<tw-input
					v-model="verifyNewPassword"
					id="verifyNewPassword"
					:label="t('updatePassword.confirmNewPassword')"
					ref="inputNewPasswordConfirm"
					:required="true"
					type="password"
					:validation="passwordConfirmMessage"
					class="text-md">
					<template #trailing_inline>
						<button
							type="button"
							@click="toggleShowVerifyPassword" 
							class="border-none bg-transparent hover:bg-transparent ">
							<EyeIcon
								v-if="!showVerifyPassword"
								class="h-5" />
							<EyeSlashIcon
								v-else
								class="h-5" />
						</button>
					</template>
				</tw-input>
			</div>
		</div>

		<div 
			v-show="error" 
			class="text-red-500 mt-3 mb-3 absolute">
			<p>{{ errorMsg }}</p>
		</div>

		<tw-button 
			class="w-full btn-brand mt-10"
			type="submit" 
			:disabled="formLoading">
			{{ t('resetPassword.submit') }}
		</tw-button>

		<div class="flex items-center mt-3 text-sm  text-brand-600">
			<div class="link">
				<router-link
					:to="{name: 'Login'}"
					class="link">
					{{ t('resetPassword.returnToLoginScreen') }}
				</router-link>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
	export default { name: 'UpdatePasswordForm' } // name the component
</script>

<script setup lang="ts">
	// api
	import { 
		Auth
	} from '@/api/Auth'

	// languages
	import { 
		useI18n
	} from 'vue-i18n'

	// router
	import {
		useRouter
	} from 'vue-router'

	// vue
	import {
		ref
	} from 'vue'

	const {t} = useI18n()

	const router = useRouter()
	
	const email = ref("")
	const currentPassword = ref("")
	const newPassword = ref("")
	const verifyNewPassword = ref("")
	const error = ref(false)
	const errorMsg = ref('')
	const formLoading = ref(false)
	const inputEmail = ref<null | {valid: Boolean}>(null)
	const inputPassword = ref<null | {valid: Boolean}>(null)
	const inputNewPassword = ref<null | {valid: Boolean}>(null)
	const inputNewPasswordConfirm = ref<null | {valid: Boolean}>(null)
	const passwordConfirmMessage = ref(t('updatePassword.newPasswordValidation'))

	async function updatePassword() {
		formLoading.value = true
		let formError = false
		if (newPassword.value != verifyNewPassword.value) {
			document.getElementById('verifyNewPassword')?.focus()
			passwordConfirmMessage.value = t('updatePassword.passwordMismatch')
			if(inputNewPasswordConfirm.value)inputNewPasswordConfirm.value.valid = false
			formError = true
		}
		if (verifyNewPassword.value == "") {
			document.getElementById('verifyNewPassword')?.focus()
			if(inputNewPasswordConfirm.value)inputNewPasswordConfirm.value.valid = false
			formError = true
		}
		if (newPassword.value == "") {
			document.getElementById('newPassword')?.focus()
			if(inputNewPassword.value)inputNewPassword.value.valid = false
			formError = true
		}
		if (currentPassword.value == "") {
			document.getElementById('currentPassword')?.focus()
			if(inputPassword.value)inputPassword.value.valid = false
			formError = true
		}
		if (email.value == "") {
			document.getElementById('email')?.focus()
			if(inputEmail.value)inputEmail.value.valid = false
			formError = true
		}
		
		if(formError) {
			formLoading.value = false
			return
		}

		try {
			let response = await Auth.changePassword(email.value, currentPassword.value, newPassword.value)
			if(response.success === false) {
				error.value = true
				errorMsg.value = response.messages[0].message || t('updatePassword.error')
				formLoading.value = false
				return
			}
			formLoading.value = false
			router.push({name: 'Jobs'})
		} 
		catch(e) {
			console.log(e)
			// do something with the error
		}
	}

	const showCurrentPassword = ref(false)

	function toggleShowCurrentPassword() {
		const passwordField = document.querySelector('#currentPassword')

		if(passwordField?.getAttribute('type') === 'password') {
			passwordField.setAttribute('type', 'text')
			showCurrentPassword.value = true
		}
		else{
			passwordField?.setAttribute('type', 'password')
			showCurrentPassword.value = false
		}
	}

	const showNewPassword = ref(false)

	function toggleShowNewPassword() {
		const passwordField = document.querySelector('#newPassword')

		if(passwordField?.getAttribute('type') === 'password') {
			passwordField.setAttribute('type', 'text')
			showNewPassword.value = true
		}
		else{
			passwordField?.setAttribute('type', 'password')
			showNewPassword.value = false
		}
	}

	const showVerifyPassword = ref(false)

	function toggleShowVerifyPassword() {
		const passwordField = document.querySelector('#verifyNewPassword')

		if(passwordField?.getAttribute('type') === 'password') {
			passwordField.setAttribute('type', 'text')
			showVerifyPassword.value = true
		}
		else{
			passwordField?.setAttribute('type', 'password')
			showVerifyPassword.value = false
		}
	}
</script>