import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex overflow-hidden bg-gray-50" }
const _hoisted_2 = { class: "flex flex-col w-0 flex-1 overflow-hidden" }
const _hoisted_3 = { class: "flex-1 relative overflow-y-auto focus:outline-none" }
const _hoisted_4 = { class: "py-6 min-h-screen" }
const _hoisted_5 = { class: "max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 min-h-full" }
const _hoisted_6 = { class: "min-h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tw_navbar = _resolveComponent("tw-navbar")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_tw_navbar, { id: "page-heading" }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("main", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _renderSlot(_ctx.$slots, "default")
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}