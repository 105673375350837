import axios from 'axios'


function getMultilinguals() {
	return axios.get(`proxy.php?/layouts/languages/records?_offset=1&_limit=1`)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

function getValueLists() {
	return axios.get(`proxy.php?/layouts/value-lists/script/SetPreferencesConstant`)
	.then((response) => {
		return axios.get(`proxy.php?/layouts/value-lists`)
			.then((response) => {
				return response.data
			})
			.catch((e) => {
				if(e.response.data.messages[0].message) {
					// Return FM Error Response
					return e.response.data
				}else{
					// Format Unknown Error Like a FM Error
					return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
				}
			})
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

export { getMultilinguals, getValueLists }