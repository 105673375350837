<template>
	<div 
		class="link">
		<div
			@click="showDetails(null, 'sceneDetails', 5, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToSound") }}</span>
			</div>
		</div>
	</div>
	<div>
		<tw-table
			:placeholder="t('placeholders.noSongs')"
			:data="songList"
			:loading="loading">
			<!-- Score -->
			<tw-table-column 
				:header="_localesStore.getLabel('183')" 
				prop="jobs_songs__jobs_songs_scores__SCORES__scoreID::name_display" />

			<!-- key range -->
			<tw-table-column 
				:header="_localesStore.getLabel('125')" 
				prop="jobs_songs__jobs_songs_scores__SCORES__scoreID::key_range" />

			<!-- version -->
			<tw-table-column 
				:header="_localesStore.getLabel('510')" 
				prop="jobs_songs__jobs_songs_scores__SCORES__scoreID::version_display" />

			<!-- description -->
			<tw-table-column 
				:header="_localesStore.getLabel('190')" 
				prop="jobs_songs__jobs_songs_scores__SCORES__scoreID::description_display" />

			<!-- song -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div 
						@click="showDetails(row, 'songScoreFiles', null, song)"
						class="h-6 w-6 cursor-pointer">
						<img
							:class="[hasFiles(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/play-fill.svg"
							alt="play icon">
					</div>
				</template>
			</tw-table-column>

			<!-- score -->
			<tw-table-column
				v-if="isStaff"
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div 
						@click="showDetails(row, 'songScoreInstruments', null, song)"
						class="h-6 w-6 cursor-pointer">
						<img
							:class="[hasInstrument(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/playlist-fill.svg"
							alt="music icon">
					</div>
				</template>
			</tw-table-column>
		</tw-table>
	</div>
</template>

<script lang="ts">
	export default { name: 'SceneSoundScores' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneSongsByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import {  // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { // vue
		onMounted,
		ref
	} from 'vue'	
	
	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		showDetails:{
			type: Function,
			default: () => {return}
		},
		song: {
			type: Object,
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()
	const userStore = _userStore()

	const {t} = useI18n()

	const songList = ref()
	const loading = ref(true)
	const isStaff = ref(userStore.getContactType === 'Staff')

	onMounted(async () => {
		try { // fetching scene songs by record id
			loading.value = true

			const recordId = props.song.recordId

			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			let res = await getSceneSongsByRecordId(recordId, params)
			const record = res.response.data[0]
			
			songList.value = record.portalData.jobs_songs__JOBS_SONGS_SCORES__jobsSongsID
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false			
		}
	})

	function hasFiles(row: any) {
		return row?.['jobs_songs__jobs_songs_scores__SCORES__scoreID::s3JSONkaraokeM4A'] ||
			row?.['jobs_songs__jobs_songs_scores__SCORES__scoreID::s3JSONscorePDF'] ||
			row?.['jobs_songs__jobs_songs_scores__SCORES__scoreID::s3JSONscoreSONG']
	}

	function hasInstrument(row: any) {
		return row?.['jobs_songs__jobs_scongs_scores__INSTRUMENT_JOBS__jobsSongsScoresID::_kp_instruments_jobs_id']
	}
</script>