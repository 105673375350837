<template>
	<div class="-mt-10 -mb-4">
		<tw-table
			:placeholder="t('placeholders.noVariations')"
			:data="props?.job?.portalData?.portal_variations"
			:loading="loading">
			<!-- variation -->
			<tw-table-column 
				:header="store.getLabel('395')" 
				prop="job__job_secene__job_variation__VARIATIONS__variationID::name_display" />

			<!-- standard -->
			<tw-table-column 
				:header="store.getLabel('411')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__job_scene__JOBS_VARIATIONS__jobSceneID::standard']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<!-- roles -->
			<tw-table-column
				v-if="_userStore.getContactType == 'Staff'"
				:header="store.getLabel('179')"
				prop="job__job_scene__JOBS_VARIATIONS__jobSceneID::roles_display" />
		</tw-table>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobVariations' } // name the component
</script>

<script setup lang="ts">
	import { // language
		useI18n
	} from 'vue-i18n'

	// store
	import { 
		localesStore
	} from '@/store/locales'

	import { 
		store as userStore
	} from '@/store/user'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()
	const _userStore = userStore()

	const {t} = useI18n()
</script>