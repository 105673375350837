<template>
	<div class="-mt-10 -mb-4">
		<tw-table
			:placeholder="t('placeholders.noParticipants')"
			:data="groupedData"
			:loading="loading">
			<!-- name -->
			<tw-table-column 
				:header="store.getLabel('149')">
				<template #default="{row}">
					{{ row['job__PARTICIPANTS__witnessID::name_display'] || 
						row['job__PARTICIPANTS__bearerID::name_display'] || 
						row['job__PARTICIPANTS__participantID::name_display'] ||
						row['job__participant__CONTACTS__contactID::name_display']
					}}
				</template>
			</tw-table-column>
			<!-- unknown -->
			<tw-table-column 
				:header="store.getLabel('386')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__PARTICIPANTS__witnessID::unknown_checkbox'] ||
							row['job__PARTICIPANTS__bearerID::unknown_checkbox'] ||
							row['job__PARTICIPANTS__participantID::unknown_checkbox']"
						class="h-5 w-5 text-black" />
					<div v-else-if="row['job__participant__ROLES__roleID::name_display']">
						{{ row['job__participant__ROLES__roleID::name_display'] }}
					</div>
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>
			<!-- side -->
			<tw-table-column 
				:header="store.getLabel('150')">
				<template #default="{row}">
					{{ row['job__PARTICIPANTS__witnessID::side_display'] ||
						row['job__PARTICIPANTS__bearerID::side_display'] ||
						row['job__PARTICIPANTS__participantID::side_display'] ||
						row['job__PARTICIPANTS__observerID::side_display'] || "—"
					}}
				</template>
			</tw-table-column>
			<!-- rehearsal -->
			<tw-table-column 
				:header="store.getLabel('387')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__PARTICIPANTS__witnessID::rehearsal_checkbox'] ||
							row['job__PARTICIPANTS__bearerID::rehearsal_checkbox'] ||
							row['job__PARTICIPANTS__participantID::rehearsal_checkbox'] ||
							row['job__PARTICIPANTS__observerID::rehearsal_checkbox']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>
		</tw-table>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobParticipants' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		computed
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const {t} = useI18n()

	const groupedData = computed(() => {
		let headings = []

		if(props?.job?.portalData?.portal_witness_particpants?.length) {
			headings.push(
				{subheading: store.getLabel('148')},
				...props.job.portalData.portal_witness_particpants
			)
		}

		if(props?.job?.portalData?.portal_bearer_participants?.length) {
			headings.push(
				{subheading: store.getLabel('151')},
				...props.job.portalData.portal_bearer_participants
			)
		}

		if(props?.job?.portalData?.portal_participant_participants?.length) {
			headings.push(
				{subheading: store.getLabel('156')},
				...props.job.portalData.portal_participant_participants
			)
		}

		if(props?.job?.portalData?.portal_observer_participants?.length) {
			headings.push(
				{subheading: store.getLabel('391')},
				...props.job.portalData.portal_observer_participants
			)
		}

		return headings
	})
</script>