
import JobList from './JobsMain.vue'

const config = {
	mainRoute: {
		name: "Jobs",
		path: "/jobs",
		component: JobList
	},
	nav: {
		name: 'nav.jobsMeetings',
		to: "Jobs",
		position: 1,
		show: true
	},
	module: "jobs"
}

export { config }