<template>
	<div 
		class="link">
		<div
			@click="showDetails(null, 'sceneDetails', 5, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToSound") }}</span>
			</div>
		</div>
	</div>
	<tw-table
		:placeholder="t('placeholders.noSongs')"
		:data="songList"
		:loading="loading">
		<!-- song -->
		<tw-table-column 
			:header="_localesStore.getLabel('177')" 
			prop="jobs_bgms__jobs_bgms_songs__SONGS__songsID::name_display" />

		<!-- album -->
		<tw-table-column 
			:header="_localesStore.getLabel('281')" 
			prop="jobs_bgms__jobs_bgms_songs__songs__ALBUM__albumsID::name_display" />

		<!-- artist -->
		<tw-table-column 
			:header="_localesStore.getLabel('282')" 
			prop="jobs_bgms__jobs_bgms_songs__songs__ARTISTS__artistsID::artist_display" />

		<!-- song -->
		<tw-table-column
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<audio
					controls
					:src="row.signedUrl">
					<source
						:src="row.signedUrl"
						type="audio/mpeg">
					{{ t("placeholders.audioNotSupported") }}
				</audio>
			</template>
		</tw-table-column>
	</tw-table>
</template>

<script lang="ts">
	export default { name: 'SceneSoundBgmSongs' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneBGMsByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		inject,
		onMounted,
		ref
	} from 'vue'


	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		showDetails:{
			type: Function,
			default: () => {return}
		},
		song: {
			type: Object,
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()

	const {t} = useI18n()

	const songList = ref()
	const loading = ref(true)
	const s3: any = inject('s3')

	onMounted(async () => {
		try { // fetching scene bgm songs by record id
			loading.value = true
			
			const recordId = props.song.recordId

			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			let res = await getSceneBGMsByRecordId(recordId, params)
			const record = res.response.data[0]
			
			songList.value = record.portalData.jobs_bgms__JOBS_BGMS_SONGS__jobsBgmsID
		}
		catch(e) {
			console.error(e)
		}

		try { // fetching song files from s3
			songList.value.forEach((sound: any) => {
				const soundS3Json = JSON.parse(sound?.['jobs_bgms__jobs_bgms_songs__songs__SONGS_INFO__songsID::s3JSONSong'] || "{}")
				const soundKey = soundS3Json?.s3FilePathShortRaw
				
				if(soundKey) {
					let params = {
						Bucket: process.env.VUE_APP_S3_BUCKET,
						Key: soundKey
					}
		
					s3.getSignedUrlPromise('getObject', params).then(
						function (url: string) {
							sound.signedUrl = url
						}, 
						function (err: Error) { 
							throw err
						}
					)
				}
			})
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loading.value = false
		}
	})
</script>