<template>
	<div 
		class="link">
		<div
			@click="showDetails(previous, 'liveScore', null, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToScores") }}</span>
			</div>
		</div>
	</div>
	<!-- song -->
	<h3 class="section-title mt-8">
		{{ _localesStore.getLabel('177') }}
	</h3>
	<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
		<!-- Score -->
		<div>
			<data-display
				:loading="loading"
				:label="_localesStore.getLabel('183')">
				<template #custom>
					<audio
						controls
						:src="signedUrlSong">
						<source
							:src="signedUrlSong"
							type="audio/mpeg">
						{{ t("placeholders.audioNotSupported") }}
					</audio>
				</template>
			</data-display>
		</div>

		<!-- Karaoke -->
		<div v-if="isStaff">
			<data-display
				:loading="loading"
				:label="_localesStore.getLabel('446')">
				<template #custom>
					<audio
						controls
						:src="signedUrlKaraoke">
						<source
							:src="signedUrlKaraoke"
							type="audio/mpeg">
						{{ t("placeholders.audioNotSupported") }}
					</audio>
				</template>
			</data-display>
		</div>

		<!-- PDF -->
		<div v-if="isStaff">
			<data-display
				:loading="loading"
				:label="_localesStore.getLabel('286')">
				<template #custom>
					<a
						target="_blank"
						class="w-full truncate hover:opacity-70 "
						:href="signedUrlPdf"
						download>
						<div
							class="flex bg-gray-100 border-2 border-gray-200 w-54 h-48 text-brand-500 rounded-md">
							<img
								class="linked-icon w-16 m-auto"
								src="@/assets/icons/solid/document-fill.svg"
								alt="document icon">
						</div>
						<div class="text-gray-900 w-54 truncate font-medium mt-2">
							{{ pdf?.s3FilePathShortRaw?.split('/')[3] }}
						</div>
					</a>
				</template>
			</data-display>
		</div>
	</div>

	<!-- instruments -->
	<h3 class="section-title mt-8">
		{{ _localesStore.getLabel('642') }}
	</h3>
	<div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
		<!-- Score -->
		<div>
			<data-display
				:loading="loading"
				:label="_localesStore.getLabel('183')">
				<template #custom>
					<audio
						controls
						:src="instrumentSignedUrlSong">
						<source
							:src="instrumentSignedUrlSong"
							type="audio/mpeg">
						{{ t("placeholders.audioNotSupported") }}
					</audio>
				</template>
			</data-display>
		</div>

		<!-- Karaoke -->
		<div v-if="isStaff">
			<data-display
				:loading="loading"
				:label="_localesStore.getLabel('446')">
				<template #custom>
					<audio
						controls
						:src="instrumentSignedUrlKaraoke">
						<source
							:src="instrumentSignedUrlKaraoke"
							type="audio/mpeg">
						{{ t("placeholders.audioNotSupported") }}
					</audio>
				</template>
			</data-display>
		</div>

		<!-- PDF -->
		<div v-if="isStaff">
			<data-display
				:loading="loading"
				:label="_localesStore.getLabel('286')">
				<template #custom>
					<a
						target="_blank"
						class="w-full truncate hover:opacity-70 "
						:href="instrumentSignedUrlPdf"
						download>
						<div
							class="flex bg-gray-100 border-2 border-gray-200 w-54 h-48 text-brand-500 rounded-md">
							<img
								class="linked-icon w-16 m-auto"
								src="@/assets/icons/solid/document-fill.svg"
								alt="document icon">
						</div>
						<div class="text-gray-900 w-54 truncate font-medium mt-2">
							{{ instrumentPdf?.s3FilePathShortRaw?.split('/')[3] }}
						</div>
					</a>
				</template>
			</data-display>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'SceneSoundLiveScoreFiles' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { // types
		S3Json
	} from '@/types'

	import { // vue
		inject,
		onMounted,
		ref
	} from 'vue'

	const props = defineProps({
		files: {
			type: Object,
			default: () => {return {}}
		},
		showDetails: {
			type: Function, 
			default: () => {return}
		},
		previous: {
			type: Object, 
			default: () => {return {}}
		}
	})
	
	const _localesStore = localesStore()
	const userStore = _userStore()

	const {t} = useI18n()

	const loading = ref(true)
	const fetchS3File: any = inject('fetchS3File')
	const signedUrlSong = ref('')
	const signedUrlPdf = ref('')
	const signedUrlKaraoke = ref('')
	const pdf = ref<S3Json>({} as S3Json)
	const instrumentSignedUrlSong = ref('')
	const instrumentSignedUrlPdf = ref('')
	const instrumentSignedUrlKaraoke = ref('')
	const instrumentPdf = ref<S3Json>({} as S3Json)
	const isStaff = ref(userStore.getContactType === 'Staff')

	onMounted(async () => {
		try { // fetching score files from s3
			loading.value = true

			signedUrlSong.value = await fetchS3File(props.files?.['live_songs__live_scores__SCORES__scoreID::s3JSONscoreSONG'])
			signedUrlPdf.value = await fetchS3File(props.files?.['live_songs__live_scores__SCORES__scoreID::s3JSONscorePDF'])
			signedUrlKaraoke.value = await fetchS3File(props.files?.['live_songs__live_scores__SCORES__scoreID::s3JSONkaraokeM4A'])
			pdf.value = JSON.parse(props.files?.['live_songs__live_scores__SCORES__scoreID::s3JSONscorePDF'] || "{}")

			instrumentSignedUrlSong.value = await fetchS3File(props.files?.['live_songs__live_scores__INSTRUMENTS__roleID_scoresID::s3JSONscoreSONG'])
			instrumentSignedUrlPdf.value = await fetchS3File(props.files?.['live_songs__live_scores__INSTRUMENTS__roleID_scoresID::s3JSONscorePDF'])
			instrumentSignedUrlKaraoke.value = await fetchS3File(props.files?.['live_songs__live_scores__INSTRUMENTS__roleID_scoresID::s3JSONkaraokeM4A'])
			instrumentPdf.value = JSON.parse(props.files?.['live_songs__live_scores__INSTRUMENTS__roleID_scoresID::s3JSONscorePDF'] || "{}")
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loading.value = false
		}
	})
</script>