
import Login from './LoginMain.vue'
import AuthLayout from '@/layouts/AuthLayout.vue'

const config = {
	mainRoute: {
		name: "Login",
		path: "/",
		component: Login,
		layout: AuthLayout
	},
	resetPassword: {
		path: '/reset-password',
		name: 'ResetPassword',
		component: Login,
		layout: AuthLayout
	},
	updatePassword: {
		path: '/update-password',
		name: 'UpdatePassword',
		component: Login,
		layout: AuthLayout
	},
	resetPasswordSuccess: {
		path: '/password-success',
		name: 'ResetPasswordSuccess',
		component: Login,
		layout: AuthLayout
	}
}

export { config }