import { 
	Route
 } from '@/types' // must be imported since file contains an export statement
 import {
	config
} from "./config"


export const route: Route[] = [
    {
      path: config.mainRoute.path,	
      name: config.mainRoute.name,
      component: config.mainRoute.component,
      meta: {
          layout: config.mainRoute.layout
      }
    },
    {
      path: config.resetPassword.path,	
      name: config.resetPassword.name,
      component: config.resetPassword.component,
      meta: {
          layout: config.resetPassword.layout
      }
    },
    {
      path: config.updatePassword.path,	
      name: config.updatePassword.name,
      component: config.updatePassword.component,
      meta: {
          layout: config.updatePassword.layout
      }
    },
    {
      path: config.resetPasswordSuccess.path,	
      name: config.resetPasswordSuccess.name,
      component: config.resetPasswordSuccess.component,
      meta: {
          layout: config.resetPasswordSuccess.layout
      }
    }
]