<template>
	<div 
		class="link">
		<div
			@click="showDetails(parent, 'varRoleDetails', null, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToRoles") }}</span>
			</div>
		</div>
	</div>
	<tw-table
		:placeholder="t('placeholders.noInstructions')"
		:data="instructionList"
		:loading="loading">
		<!-- Instructions -->
		<tw-table-column 
			:header="_localesStore.getLabel('149')"
			prop="jobs_variations_roles__instructions_join_jobs__INSTRUCTIONS__instructionID::name_display" />
		
		<tw-table-column 
			:header="_localesStore.getLabel('181')"
			prop="jobs_variations_roles__INSTRUCTIONS_JOIN_JOBS_jobsVariationsRoleID::instructions_display_edit" />

		<!-- picture -->
		<tw-table-column
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<div 				
					class="h-6 w-6 cursor-pointer"
					@click="showDetails(row, 'varRolesInstructions', null, data)">
					<img
						:class="[ hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
						src="@/assets/icons/solid/image-fill.svg"
						alt="list icon">
				</div>
			</template>
		</tw-table-column>

		<!-- video -->
		<tw-table-column
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<div 
					@click="showDetails(row, 'varRolesInstructions', null, data)"
					class="h-6 w-6 cursor-pointer">
					<img
						:class="[ hasVideo(row) ? 'linked-icon' : 'grayed-icon']"
						src="@/assets/icons/solid/video-fill.svg"
						alt="list icon">
				</div>
			</template>
		</tw-table-column>		
	</tw-table>
</template>

<script lang="ts">
	export default { name: 'InstructionsList' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneVariationRolesInstructionsByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'
	
	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		ref,
		onMounted
	} from 'vue'

	const props = defineProps({
		data: {
			type: Object,
			default: () => {return {}}
		},
		showDetails: {
			type: Function, 
			default: () => {return}
		},
		previous: {
			type: Object, 
			default: () => {return {}}
		},
		parent: {
			type: Object, 
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()

	const {t} = useI18n()

	const instructionList = ref()
	const loading = ref(true)

	onMounted(async () => {
		try { // fetching varition role's instructions by record id
			loading.value = true

			const recordId = props.data.recordId

			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			
			let res = await getSceneVariationRolesInstructionsByRecordId(recordId, params)
			const record = res.response.data[0]
			
			instructionList.value = record.portalData.jobs_variations_roles__INSTRUCTIONS_JOIN_JOBS_jobsVariationsRoleID
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})
	
	function hasPicture(row: any) {
		return row?.['jobs_variations_roles__INSTRUCTIONS_JOIN_JOBS_jobsVariationsRoleID::s3JSONPicture'] ||
			row?.['jobs_variations_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::s3JSONPictureRole']
	}

	function hasVideo(row: any) {
		return row?.['jobs_variations_roles__INSTRUCTIONS_JOIN_JOBS_jobsVariationsRoleID::s3JSONVideo'] ||
			row?.['jobs_variations_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::s3JSONVideoRole']
	}
</script>