<template>
	<nav
		class="flex"
		aria-label="Breadcrumb">
		<ol
			role="list"
			class="flex-wrap lg:flex-nowrap flex items-center space-x-1">
			<li
				v-for="(crumb, i) in crumbs"
				
				:key="crumb.label">
				<div class="flex items-center">
					<svg
						v-if="style == 'slashes' && i != 0"
						class="h-5 w-5 flex-shrink-0 text-gray-700"
						fill="currentColor"
						viewBox="0 0 20 20"
						aria-hidden="true">
						<path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
					</svg>
					<router-link
						v-if="crumb.to"
						class="font-medium text-sm"
						:class="{'ml-1': i != 0, 'link': crumbs.length > 0 && i != crumbs.length - 1}"
						:to="crumb.to"
						@click="closeModal()">
						{{ crumb.label }}
					</router-link>
					<div 
						v-else
						class="text-sm">
						{{ crumb.label }}
					</div>
				</div>
			</li>
		</ol>
	</nav>
</template>

<script lang="ts">
// name the component
	export default { name: 'TwBreadcrumb' }
</script>

<script lang="ts" setup>
	// eslint-disable-next-line
	const props = defineProps({
		style: {
			type: String,
			default: 'slashes'
		},
		crumbs: {
			type: Array,
			default: () => {return []}
		},
		closeModal:{
			type: Function,
			default: () => {return}
		}
	})
  </script>