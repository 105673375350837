<template>
	<div 
		class="link">
		<div
			@click="showDetails(parent, 'liveSong', null, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToSong") }}</span>
			</div>
		</div>
	</div>
	<tw-table
		:placeholder="t('placeholders.noSongs')"
		:data="songList"
		:loading="loading">
		<!-- name -->
		<!-- jobs_scenes__jobs_songs__SONGS__songsId::name_display -->
		<tw-table-column 
			:header="_localesStore.getLabel('183')" 
			prop="live_songs__live_scores__SCORES__scoreID::name_display" />

		<!-- key range -->
		<tw-table-column 
			:header="_localesStore.getLabel('125')" 
			prop="live_songs__live_scores__SCORES__scoreID::key_range" />

		<!-- version -->
		<tw-table-column 
			:header="_localesStore.getLabel('510')" 
			prop="live_songs__live_scores__SCORES__scoreID::version_display" />

		<!-- description -->
		<tw-table-column 
			:header="_localesStore.getLabel('190')" 
			prop="live_songs__live_scores__SCORES__scoreID::description_display" />

		<!-- song -->
		<tw-table-column
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<div 
					@click="showDetails(row, 'liveScoreFiles', null, song)"
					class="h-6 w-6 cursor-pointer">
					<img
						:class="[hasFiles(row) ? 'linked-icon' : 'grayed-icon']"
						src="@/assets/icons/solid/play-fill.svg"
						alt="play icon">
				</div>
			</template>
		</tw-table-column>
	</tw-table>
</template>

<script lang="ts">
	export default { name: 'SceneSoundLiveScores' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneLiveScoresByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'

	// store
	import { 
		localesStore
	} from '@/store/locales'

	// vue
	import {
		onMounted,
		ref
	} from 'vue'

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		showDetails:{
			type: Function,
			default: () => {return}
		},
		song: {
			type: Object,
			default: () => {return {}}
		},
		previous: {
			type: Object,
			default: () => {return {}}
		},
		parent: {
			type: Object,
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()

	const {t} = useI18n()

	const songList = ref()
	const loading = ref(true)

	onMounted(async () => {
		try {
			loading.value = true
			
			const recordId = props.song.recordId

			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			let res = await getSceneLiveScoresByRecordId(recordId, params)
			const record = res.response.data[0]

			songList.value = record.portalData.live_songs__LIVE_SCORES__songID
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loading.value = false
		}
	})

	function hasFiles(row: any) {
		return row?.['live_songs__live_scores__SCORES__scoreID::s3JSONkaraokeM4A'] ||
			row?.['live_songs__live_scores__SCORES__scoreID::s3JSONscorePDF'] ||
			row?.['live_songs__live_scores__SCORES__scoreID::s3JSONscoreSONG'] ||
			row?.['live_songs__live_scores__INSTRUMENTS__roleID_scoresID::s3JSONkaraokeM4A'] ||
			row?.['live_songs__live_scores__INSTRUMENTS__roleID_scoresID::s3JSONscorePDF'] ||
			row?.['live_songs__live_scores__INSTRUMENTS__roleID_scoresID::s3JSONscoreSONG']
	}
</script>