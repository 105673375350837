<template>
	<div 
		class="link">
		<div
			@click="showDetails(previous, 'songScore', null, null, 1)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToScores") }}</span>
			</div>
		</div>
	</div>
	<tw-table
		:placeholder="t('placeholders.noSongs')"
		:data="instrumentsList"
		:loading="loading">
		<!-- name -->
		<tw-table-column 
			:header="_localesStore.getLabel('149')" 
			prop="jobs_scongs_scores__instrument_jobs__ROLES__roleID::name_display" />

		<!-- description -->
		<tw-table-column 
			:header="_localesStore.getLabel('190')" 
			prop="jobs_scongs_scores__instrument_jobs__INSTRUMENTS__instrumentsID::description_display" />

		<!-- song -->
		<tw-table-column
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<div 
					@click="showDetails(row, 'songInstrumentFiles', null, song)"
					class="h-6 w-6 cursor-pointer">
					<img
						:class="[ hasScore(row) ? 'linked-icon' : 'grayed-icon']"
						src="@/assets/icons/solid/play-fill.svg"
						alt="View files">
				</div>
			</template>
		</tw-table-column>
	</tw-table>
</template>

<script lang="ts">
	export default { name: 'SceneSoundSongInstruments' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneSongScoresByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'
	
	import { // store
		localesStore
	} from '@/store/locales'

	import {
		store as _userStore
	} from '@/store/user'
	
	import { // vue
		onMounted,
		ref
	} from 'vue'

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		showDetails:{
			type: Function,
			default: () => {return}
		},
		song: {
			type: Object,
			default: () => {return {}}
		},
		previous: {
			type: Object,
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()
	const userStore = _userStore()

	const {t} = useI18n()

	const instrumentsList = ref()
	const loading = ref(true)

	onMounted(async () => {
		try{ // fetching instruments by song record id
			loading.value = true
			const recordId = props.song.recordId

			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
				contactId: userStore.user.contactId
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
		
			let res = await getSceneSongScoresByRecordId(recordId, params)
			const record = res.response.data[0]
			
			instrumentsList.value = record.portalData.jobs_scongs_scores__INSTRUMENT_JOBS__jobsSongsScoresID
		}
		catch(e) {
			console.log(e)
		}
		finally{
			loading.value = false
			
		}
	})

	function hasScore(row: any) {
		return row?.['jobs_scongs_scores__instrument_jobs__INSTRUMENTS__instrumentsID::s3JSONscoreSONG'] ||
			row?.['jobs_scongs_scores__instrument_jobs__INSTRUMENTS__instrumentsID::s3JSONscorePDF'] ||
			row?.['jobs_scongs_scores__instrument_jobs__INSTRUMENTS__instrumentsID::s3JSONkaraokeM4A']
	}
</script>