<template>
	<div class="-mt-10">
		<tw-table
			:placeholder="t('placeholders.noDocuments')"
			:loading="loading"
			:data="attendee.portalData?.['attendees__DOCUMENTS__attendeesID']">
			<!--Document-->
			<tw-table-column
				:header="store.getLabel('202')">
				<template #default="{row}">
					{{ row["attendees__DOCUMENTS__attendeesID::name_display"] }}
				</template>
			</tw-table-column>

			<!--Description-->
			<tw-table-column
				:header="store.getLabel('190')">
				<template #default="{row}">
					{{ row["attendees__DOCUMENTS__attendeesID::description_display"] }}
				</template>
			</tw-table-column>

			<!--Created-->
			<tw-table-column
				:header="store.getLabel('445')">
				<template #default="{row}">
					{{ row["attendees__DOCUMENTS__attendeesID::stamp_create"] }}
				</template>
			</tw-table-column>
			<tw-table-column>
				<template #default="{row}">
					<a
						target="_blank"
						class="cursor-pointer"
						:href="row?.signedUrl"
						download>
						<img
							class="linked-icon h-6 w-6"
							src="@/assets/icons/solid/download-fill.svg"
							alt="download icon">
					</a>
				</template>
			</tw-table-column>
		</tw-table>	
	</div>
</template>
<script lang="ts">
	export default { name: 'DocumentDetails' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'
	
	import { // store
		localesStore
	} from '@/store/locales'

	const props = defineProps({
		attendee: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const {t} = useI18n()
</script>