<template>
	<div class="-mt-8">
		<tw-table
			:placeholder="t('placeholders.noAttendees')"
			:loading="loading"
			:data="meeting?.portalData?.portal_attendees">
			<!-- Attendees -->
			<tw-table-column 
				:header="_store.getLabel('408')">
				<template #default="{row}">
					{{ row["fc_calendar_sample_event__attendee__CONTACTS__contactID::name_display"] }}
				</template>
			</tw-table-column>

			<!-- Role -->
			<tw-table-column 
				:header="_store.getLabel('102')">
				<template #default="{row}">
					{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
				</template>
			</tw-table-column>

			<!-- Position -->
			<tw-table-column 
				:header="_store.getLabel('113')">
				<template #default="{row}">
					{{ row["fc_calendar_sample_event__attendee__contact_employee__POSITIONS__positionID::name_display"] }}
				</template>
			</tw-table-column>

			<!-- Pay Type -->
			<tw-table-column
				v-if="userStore.getContactType == 'Staff'" 
				:header="_store.getLabel('606')">
				<template #default="{row}">
					{{ row["fc_calendar_sample_event__ATTENDEES__calendarID::pay_type_display"] }}
				</template>
			</tw-table-column>

			<!-- Hourly Wage -->
			<tw-table-column
				v-if="userStore.getContactType == 'Staff'"  
				:header="_store.getLabel('324') + ' / ' + _store.getLabel('410')">
				<template #default="{row}">
					{{ row["fc_calendar_sample_event__ATTENDEES__calendarID::hourly_rate"] ? '¥' + row["fc_calendar_sample_event__ATTENDEES__calendarID::hourly_rate"] : "-" + (row["fc_calendar_sample_event__ATTENDEES__calendarID::quantity"] ? " /" : " / -") + row["fc_calendar_sample_event__ATTENDEES__calendarID::quantity"] }}
				</template>
			</tw-table-column>

			<!-- Payment -->
			<tw-table-column
				v-if="userStore.getContactType == 'Staff'" 
				:header="_store.getLabel('448')">
				<template #default="{row}">
					{{ $filters.jpy(row["fc_calendar_sample_event__ATTENDEES__calendarID::cost_total"]) }}
				</template>
			</tw-table-column>

			<!-- Status -->
			<tw-table-column 
				:header="_store.getLabel('196')">
				<template #default="{row}">
					{{ row["fc_calendar_sample_event__ATTENDEES__calendarID::status_display"] }}
				</template>
			</tw-table-column>

			<!-- Meeting With -->
			<tw-table-column 
				:header="_store.getLabel('619')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['fc_calendar_sample_event__ATTENDEES__calendarID::meeting_with']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<!-- Primary -->
			<tw-table-column 
				:header="_store.getLabel('292')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['fc_calendar_sample_event__ATTENDEES__calendarID::primary']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<tw-table-column
				v-if="userStore.getContactType == 'Staff'">
				<template #default="{row}">
					<div 
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetail(row, 0)">
						<img
							:class="row['fc_calendar_sample_event__ATTENDEES__calendarID::description_display'] || row['fc_calendar_sample_event__ATTENDEES__calendarID::emergency_transport'] ? 'linked-icon' : 'grayed-icon'"
							src="@/assets/icons/solid/maps-fill.svg"
							alt="maps icon">
					</div>			
				</template>
			</tw-table-column>

			<tw-table-column>
				<template #default="{row}">
					<div 
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetail(row, userStore.getContactType == 'Client' ? 0 : 1)">
						<img
							:class="row['fc_calendar_sample_event__attendee__DOCUMENTS__attendeeID::_kp_documents_id'] ? 'linked-icon' : 'grayed-icon'"
							src="@/assets/icons/solid/document-fill.svg"
							alt="list icon">
					</div>					
				</template>
			</tw-table-column>
		</tw-table>
		<tw-modal
			:title="selectedAttendee?.['fc_calendar_sample_event__attendee__CONTACTS__contactID::name_display']"
			:breadcrumb="breadcrumb"
			ref="attendeeDetailsModal">
			<AttendeesDetailsModal
				:attendee="selectedAttendee" 
				:open-to-tab="openToTab" />
		</tw-modal>
	</div>
</template>

<script lang="ts">
	export default { name: 'AttendeesDetails' } // name the component
</script>

<script setup lang="ts">
	import AttendeesDetailsModal from './AttendeesDetails.vue' // components

	import { // languages
		useI18n
	} from 'vue-i18n'
	
	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store
	} from '@/store/user'

	import { // vue
		ref
	} from 'vue'

	const props = defineProps({
		meeting: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		},
		breadcrumb: {
			type: Array,
			default: () => {return []}
		},
	})

	const _store = localesStore()
	const userStore = store()

	const {t} = useI18n()

	const openToTab = ref(1)

	const selectedAttendee = ref({})
	const attendeeDetailsModal = ref<{open: boolean}>({open: false})

	function openModalDetail(row: any, tab: number) {
		selectedAttendee.value = row
		openToTab.value = tab
		attendeeDetailsModal.value.open = true	
	}
</script>