import axios from 'axios'

import { 
	FindParameters
 } from '@/types' // must be imported since file contains an export statement

function getMeetingByRecordId(params: FindParameters) {
	// return axios.get(`proxy.php?/layouts/meeting-search/records/${recordId}`)
    //     .then((response) => {
    //         return response.data
    //     })
    //     .catch((e) => {
    //         if(e.response.data.messages[0].message) {
    //             // Return FM Error Response
    //             return e.response.data
    //         }else{
    //             // Format Unknown Error Like a FM Error
    //             return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
    //         }
    //     })
	return axios.post(`proxy.php?/layouts//meeting-search/_find`, params)
		.then((response) => {
			return response.data
		})
		.catch((e) => {
			if(e.response.data.messages[0].message) {
				// Return FM Error Response
				return e.response.data
			}else{
				// Format Unknown Error Like a FM Error
				return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
			}
		})
}

function getAttendeeByRecordId(recordId: string) {
	return axios.get(`proxy.php?/layouts/attendee-search/records/${recordId}`)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}


 export { getMeetingByRecordId, getAttendeeByRecordId }