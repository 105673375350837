<template>
	<DialogDescription
		as="h3"
		class="mb-6">
		{{ t('changePassword.description') }}
	</DialogDescription>
	<form @submit.prevent="updatePassword">
		<div class="space-y-4">
			<tw-input
				v-model="currentPassword"
				id="currentPassword"
				ref="inputPassword"
				:required="true"
				type="password"
				:label="t('updatePassword.currentPassword')"
				:validation="t('updatePassword.currentPasswordValidation')"
				class="text-md" />

			<tw-input
				v-model="newPassword"
				id="newPassword"
				ref="inputNewPassword"
				:required="true"
				type="password"
				:label="t('updatePassword.newPassword')"
				:validation="passwordValidateMessage"
				class="text-md" />

			<tw-input
				v-model="confirmNewPassword"
				id="confirmNewPasword"
				ref="inputConfirmNewPassword"
				:required="true"
				type="password"
				:label="t('updatePassword.confirmNewPassword')"
				:validation="passwordConfirmMessage" />
		</div>
		<div
			v-if="error"
			class="text-red-500 mt-4 -mb-16">
			{{ errorMsg }}
		</div>
		<div class="mt-20">
			<tw-button
				:disabled="formLoading"
				:loading="formLoading"
				type="submit"
				class="btn-base btn-brand w-full"
				@click="updatePassword">
				{{ t('changePassword.title') }}
			</tw-button>
		</div>
	</form>
</template>

<script lang="ts">
	export default { name: "ChangePasswordForm" } // name the component
</script>

<script setup lang="ts">
	import { // auth
		Auth
	} from '@/api/Auth'

	import { // components
		Dialog, 
		DialogTitle, 
		DialogDescription, 
		DialogOverlay,
		TransitionRoot, 
		TransitionChild
	} from '@headlessui/vue'
	import {
		XMarkIcon
	} from '@heroicons/vue/24/outline'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // router
		useRouter
	} from 'vue-router'
	
	import { // stores
		store as _userStore
	} from '@/store/user'

	import { // types
		Notification
	} from '@/types'

	import { // vue
		ref
	} from 'vue'

	
	const userStore = _userStore()
	const router = useRouter()
	
	const {t} = useI18n()

	let email = userStore.user.email
	
	const currentPassword = ref("")
	const newPassword = ref("")
	const confirmNewPassword = ref("")
	const error = ref(false)
	const errorMsg = ref('')
	const formLoading = ref(false)
	const inputPassword = ref<null | {valid: Boolean}>(null)
	const inputNewPassword = ref<null | {valid: Boolean}>(null)
	const inputConfirmNewPassword = ref<null | {valid: Boolean}>(null)
	const passwordConfirmMessage = ref(t('updatePassword.confirmPasswordError'))
	const passwordValidateMessage = ref(t('updatePassword.confirmPasswordError'))

	const emit = defineEmits(['closePasswordModal'])

	async function updatePassword() {
		formLoading.value = true
		let formError = false
		
		if (currentPassword.value == "") {
			document.getElementById('currentPassword')?.focus()
			if(inputPassword.value)inputPassword.value.valid = false
			formError = true
		}
		if (newPassword.value == "") {
			document.getElementById('newEmail')?.focus()
			passwordValidateMessage.value = t('updatePassword.newPasswordValidation')
			if(inputNewPassword.value)inputNewPassword.value.valid = false
			formError = true
		}
		if (newPassword.value == currentPassword.value && newPassword.value != null && newPassword.value != "") {
			document.getElementById('newEmail')?.focus()
			passwordValidateMessage.value = t('updatePassword.differentPasswordError')
			if(inputNewPassword.value)inputNewPassword.value.valid = false
			formError = true
		}
		if (confirmNewPassword.value == "") {
			document.getElementById('email')?.focus()
			if(inputConfirmNewPassword.value)inputConfirmNewPassword.value.valid = false
			formError = true
		}
		if (newPassword.value != confirmNewPassword.value) {
			document.getElementById('confirmNewEmail')?.focus()
			passwordConfirmMessage.value = t('updatePassword.passwordMismatch')
			if(inputConfirmNewPassword.value)inputConfirmNewPassword.value.valid = false
			formError = true
		}	
		
		if(formError) {
			formLoading.value = false
			return
		}

		try {
			error.value = false
			errorMsg.value = ''

			let response = await Auth.changePassword(email, currentPassword.value, newPassword.value)

			if(!response.success) {
				error.value = true
				errorMsg.value = response?.messages?.[0]?.message ?? t('updatePassword.error')
				throw errorMsg.value
			}
			
			const notification: Notification = {
				title: t('placeholders.success'),
				subtitle: t('updatePassword.passwordUpdated'),
				icon: 0
			}

			emit('closePasswordModal', notification)
			currentPassword.value = ""
			newPassword.value = ""
			confirmNewPassword.value = ""
			router.push({name: 'Account'})
		} 
		catch(e) {
			console.log(e)
		}
		finally {
			formLoading.value = false
		}
	}
</script>