<template>
	<TransitionRoot
		as="template"
		:show="open">
		<Dialog
			as="div"
			class="relative z-40"
			@close="open = false">
			<TransitionChild
				as="template"
				enter="ease-out duration-300"
				enter-from="opacity-0"
				enter-to="opacity-100"
				leave="ease-in duration-200"
				leave-from="opacity-100"
				leave-to="opacity-0">
				<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
			</TransitionChild>
  
			<div class="fixed inset-0 z-50 w-screen overflow-y-auto">
				<div class="flex min-h-full items-end justify-center text-center sm:items-center">
					<TransitionChild
						as="template"
						enter="ease-out duration-300"
						enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enter-to="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leave-from="opacity-100 translate-y-0 sm:scale-100"
						leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
						<DialogPanel :class="[modalMaxWidth,'relative transform overflow-x-auto overflow-y-auto rounded-lg bg-white h-200 max-h-screen px-4 pb-4 text-left shadow-xl transition-all w-full sm:px-6 sm:pb-4']">
							<div class="flex justify-between items-start gap-2 sticky top-0 left-0 pt-4 sm:pt-6 bg-white lg:bg-transparent z-50">
								<div>
									<tw-breadcrumb 
										:crumbs="breadcrumb" 
										:close-modal="closeModal" />
									<DialogTitle class="page-title">
										{{ title }}
									</DialogTitle>
								</div>
								<XMarkIcon 
									class="h-5 w-5 text-gray-700 hover:text-brand-500 cursor-pointer flex-none"
									@click="open = false" />
							</div>
							<div id="modal-content">
								<slot />
							</div>
						</DialogPanel>
					</TransitionChild>
				</div>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

  <script lang="ts">
  // name the component
	export default { name: 'TwModal' }
  </script>

  <script lang="ts" setup>
	import {
		defineExpose,
		ref
	} from 'vue'
	import {
		Dialog,
		DialogPanel,
		DialogTitle,
		TransitionChild,
		TransitionRoot
	} from '@headlessui/vue'

	/*eslint-disable*/
	const props = defineProps({
		breadcrumb: {
			type: Array,
			default: () => {return []}
		},
		description: {
			type: String,
			default: ""
		},
		modalMaxWidth: {
			type: String,
			default: 'max-w-screen-xl'
		},
		title: {
			type: String,
			default: ""
		},
		closeModal:{
			type: Function,
			default: () => {return}
		}
	})
  
	const open = ref(false)

	defineExpose({
		open
	})
  </script>

<style>
	#modal-content #tabs-select{
		@apply sticky top-0 left-0;
	}
	#modal-content div{
		@apply overflow-x-auto relative;
	}
</style>