import MeetingDetails from './MeetingDetails.vue'

const config = {
	mainRoute: {
		name: "MeetingDetail",
		path: "/meetings",
		component: MeetingDetails
	},
	module: "meetings"
}

export { config }