<template>
	<div>
		<div 
			class="link">
			<div
				@click="showDetails(null, 'sceneDetails', 3, null)"
				class="flex justify-start items-center cursor-pointer">			
				<ArrowLeftIcon
					class="w-5 h-5" />
				<div
					class="truncate inline-block space-x-1 text-sm w-full">
					<span>{{ t("nav.returnToOptions") }}</span>
				</div>
			</div>
		</div>
		<tw-tabs-underline
			class="mb-6"
			ref="childTabs"
			:tabs="tabs" />
		<div v-show="childTabs?._tabs?.[0]?.current">
			<div class="grid grid-cols-3 gap-6">
				<div class="col-span-3 lg:col-span-1">
					<!-- photo -->
					<data-display
						:label="_localesStore.getLabel('293')">
						<template #custom>
							<div
								v-if="filesLoading"
								class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
							<img
								v-else-if="signedUrlPhoto"
								class="object-cover w-96 h-75 rounded-md"
								:src="signedUrlPhoto">
							<div
								v-else
								class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
								<img
									class="grayed-icon h-10 w-10"
									src="@/assets/icons/solid/image-fill.svg"
									alt="picture icon">
							</div>
						</template>
					</data-display>
				</div>
				<div class="col-span-3 lg:col-span-1">
					<!-- video -->
					<data-display
						:label="_localesStore.getLabel('294')">
						<template #custom>
							<div
								v-if="filesLoading"
								class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
							<video
								v-else-if="signedUrlVideo"
								controls
								class="object-cover w-96 h-75 rounded-md">
								<source
									type="video/mp4"
									:src="signedUrlVideo">
							</video>
							<div
								v-else
								class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
								<img
									class="grayed-icon h-10 w-10"
									src="@/assets/icons/solid/video-fill.svg"
									alt="video icon">
							</div>
						</template>
					</data-display>
				</div>		
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[1]?.current">
			<tw-table
				:loading="loading"
				:placeholder="t('placeholders.noRoles')"
				:data="groupedData">
				<!-- name -->
				<tw-table-column 
					:header="_localesStore.getLabel('149')" 
					prop="jobs_scenes_options__jobs_scenes_options_roles__ROLES__roleID::name_display" />	

				<!-- instructions -->
				<tw-table-column
					v-if="isStaff"
					class-name="w-6 "
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div
							class="h-6 w-6 cursor-pointer"
							@click="showDetails(row, 'opRolesInstructionsList', null, option)">
							<img
								:class="[hasInstructions(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/list-bullet-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</tw-table-column>
			</tw-table>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'SceneOptionsTabs' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneOptionsRolesByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { // vue
		inject,
		onMounted,
		ref, 
		computed
	} from 'vue'

	const _localesStore = localesStore()
	const userStore = _userStore()

	const {t} = useI18n()

	const childTabs = ref<{_tabs: []}>({_tabs: []})

	const isStaff = ref(userStore.getContactType === 'Staff')

	const groupedData = computed(() => {
		let headings = []

		if(standardRoles.value?.length) {
			headings.push(
				{subheading: 'Standard Roles'},
				...standardRoles.value
			)
		}

		if(musicianRoles.value?.length) {
			headings.push(
				{subheading: 'Musician Roles'},
				...musicianRoles.value
			)
		}
		
		return headings
	})

	const tabs = ref(
		isStaff.value ? [
			{ name: _localesStore.getLabel('293') + "/" + _localesStore.getLabel('294'), current: false },
			{ name: _localesStore.getLabel('179'), current: false }
		] :
		[
			{ name: _localesStore.getLabel('293') + "/" + _localesStore.getLabel('294'), current: false },
		])

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		showDetails:{
			type: Function,
			default: () => {return}
		},
		option: {
			type: Object,
			default: () => {return {}}
		}
	})

	const standardRoles = ref()
	const musicianRoles = ref()

	const loading = ref(false)
	const filesLoading = ref(false)
	const fetchS3File: any = inject('fetchS3File')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	onMounted(async () => {
		tabs.value[props.openToTab].current = true

		try { // getting option details
			loading.value = true

			const recordId = props.option.recordId

			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			let res = await getSceneOptionsRolesByRecordId(recordId, params)
			const record = res.response.data[0]
			
			standardRoles.value = record.portalData.jobs_scenes_options__JOBS_SCENES_OPTIONS_ROLES__standardID
			musicianRoles.value = record.portalData.jobs_scenes_options__JOBS_SCENES_OPTIONS_ROLES__instrumentalID
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loading.value = false
		}

		try { // getting files from s3
			filesLoading.value = true
			const s3PhotoField = props.option?.['jobs_scenes__jobs_scenes_options__CONTACTS_SCENES_OPTIONS__contactsScenesOptionsID::s3JSONPicture'] || props.option?.['jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::s3JSONPicture']
			const s3VideoField = props.option?.['jobs_scenes__jobs_scenes_options__CONTACTS_SCENES_OPTIONS__contactsScenesOptionsID::s3JSONVideo'] || props.option?.['jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::s3JSONVideo']
			signedUrlPhoto.value = await fetchS3File(s3PhotoField)
			signedUrlVideo.value = await fetchS3File(s3VideoField)
		}
		catch(e) {
			console.error(e)
		}
		finally {
			filesLoading.value = false
		}
		
	})

	function hasInstructions(row: any) {
		return row?.['jobs_scenes_options__jobs_scenes_options_s_roles__INSTRUCTIONS_JOIN_JOBS__jobsScenesOptionsRolesID::_kp_instructions_join_jobs_id'] ||
			row?.['jobs_scenes_options__jobs_scenes_options_m_roles__INSTRUCTIONS_JOIN_JOBS__jobsScenesOptionsRolesID::_kp_instructions_join_jobs_id']
	}

</script>