import axios from 'axios'

function getContactByRecordId(recordId: string, params: string) {
	return axios.get(`proxy.php?/layouts/contact-search/records/${recordId}${params}`)
        .then((response) => {
            return response.data
        })
        .catch((e) => {
            if(e.response.data.messages[0].message) {
                // Return FM Error Response
                return e.response.data
            }else{
                // Format Unknown Error Like a FM Error
                return  `{ messages:[message: ${e.message}, code: -1],response: {}}`
            }
        })
}

export { getContactByRecordId }