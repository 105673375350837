import {
	Route
} from "@/types" // must be imported since file contains an export statement
import {
	config
} from "./config"
import MeetingDetails from './MeetingDetails.vue'

export const route: Route[] = [
	// main route
	{
		path: config.mainRoute.path,
		name: config.mainRoute.name,
		component: config.mainRoute.component,
		meta: {
			module: config.module
		}
	},
	// other routes below
	{
		path: `${config.mainRoute.path}/:recordId`,
		name: 'MeetingDetails',
		component: MeetingDetails,
		meta: {
			module: config.module
		},
		props: true
	}
]