<template>
	<div class="card">
		<div
			@click="openModalDetails(scene, 0)" 
			class="shadow-[inset_0_-3px_6px_rgba(95,77,63,0.16)] p-3 cursor-pointer">
			<div 
				v-if="loading" 
				class="h-60 w-full flex justify-center items-center">
				<tw-loading />
			</div>
			<img 
				v-else-if="signedUrl"
				:src="signedUrl"
				class="h-60 w-full flex justify-center items-center object-cover">
			<div
				v-else
				class="flex justify-center items-center object-cover h-60">
				<img
					class="grayed-icon h-10 w-10"
					src="@/assets/icons/solid/image-fill.svg"
					alt="image icon">
			</div>
		</div>
		<div class="px-3 pb-3 truncate w-full">
			<div
				@click="openModalDetails(scene, 0)"
				class="flex justify-start items-center cursor-pointer">
				<div
					class="truncate inline-block space-x-1 text-lg w-full">
					<span>{{ props.scene['job__JOBS_SCENES__jobID::scene_number'] }}. </span>
					<span>{{ props.scene['job__job_scene__SCENES__sceneID::name_display'] }}</span>
				</div>
				<ArrowRightIcon
					class="w-5 h-5" />
			</div>
			<div class=" italic text-sm text-gray-500">
				<div>{{ props.scene['job__JOBS_SCENES__jobID::placement_display'] }}</div>
				<div
					:class="{'link': props.scene['job__JOBS_SCENES__jobID::sound_choice_display_first']}"
					@click="openModalDetails(scene, userStore.getContactType === 'Staff' ? 5 : 4)">
					{{ props.scene['job__JOBS_SCENES__jobID::sound_choice_display_first'] || '—' }}
				</div>
			</div>
			<div class="flex justify-between items-center mt-3">
				<div class="flex justify-between items-center w-full">
					<img
						@click="openModalDetails(scene, 0)"
						class="h-6 w-6"
						:class="[props.scene['job__job_scene__CONTACTS_SCENES__contactSceneID::s3JSONPicture'] ? 'linked-icon' : 'grayed-icon', 'cursor-pointer']"
						src="@/assets/icons/solid/image-fill.svg"
						alt="image icon">
					<img
						@click="openModalDetails(scene, 0)"
						class="h-6 w-6"
						:class="[props.scene['job__job_scene__CONTACTS_SCENES__contactSceneID::s3JSONVideo'] ? 'linked-icon' : 'grayed-icon', 'cursor-pointer']"
						src="@/assets/icons/solid/video-fill.svg"
						alt="video icon">
				
					<img
						@click="openModalDetails(scene, 1)"
						class="h-6 w-6"
						:class="[props.scene['job__job_scene__JOBS_PARTICIPANT__sceneID::_kp_jobs_participants_id'] ? 'linked-icon' : 'grayed-icon', 'cursor-pointer']"
						src="@/assets/icons/solid/participants-fill.svg"
						alt="video icon">
					<img
						@click="openModalDetails(scene, 2)"
						class="h-6 w-6"
						:class="[props.scene['job__job_scene__JOBS_VARIATIONS__jobSceneID::_kp_jobs_variations_id'] ? 'linked-icon' : 'grayed-icon', 'cursor-pointer']"
						src="@/assets/icons/solid/variation-fill.svg"
						alt="variation icon">
					<img
						@click="openModalDetails(scene, 3)"
						class="h-6 w-6"
						:class="[props.scene['job__job_scene__JOBS_SCENES_OPTIONS__jobSceneID::_kp_jobs_scenes_options_id'] ? 'linked-icon' : 'grayed-icon', 'cursor-pointer']"
						src="@/assets/icons/solid/option-fill.svg"
						alt="option icon">
					<img
						v-if="userStore.getContactType === 'Staff'"
						@click="openModalDetails(scene, 4)"
						class="h-6 w-6"
						:class="[hasRoles ? 'linked-icon' : 'grayed-icon', 'cursor-pointer']"
						src="@/assets/icons/solid/roles-fill.svg"
						alt="roles icon">
				</div>
			</div>
		</div>	
	</div>

	<tw-modal
		:title="modalTitle"
		:breadcrumb="sceneDetailsBreadcrumb"
		:close-modal="modalClose"
		ref="sceneDetailsModal">
		<SceneDetails
			v-if="currentModal === 'sceneDetails'"
			:scene="selectedScene"
			:show-details="showDetails"
			:open-to-tab="openToTab" />
		
		<SceneVarRoles 
			v-else-if="currentModal === 'varRoleDetails'" 
			:variation="currentDetails"
			:show-details="showDetails" />
		
		<SceneVarRolesList
			v-else-if="currentModal === 'varRolesInstructionsList'" 
			:data="currentDetails"			 
			:previous="previousData"
			:parent="parentData" 
			:show-details="showDetails" />

		<SceneVarRolesInstructions
			v-else-if="currentModal === 'varRolesInstructions'" 
			:instruction="currentDetails"			 
			:previous="previousData" 
			:show-details="showDetails" />

		<SceneOptionsTabs 
			v-else-if="currentModal === 'optionsTabs'" 
			:option="currentDetails"
			:show-details="showDetails"
			:open-to-tab="openToTab" />

		<SceneOptionsRolesList
			v-else-if="currentModal === 'opRolesInstructionsList'" 
			:data="currentDetails"			 
			:previous="previousData"
			:parent="parentData" 
			:show-details="showDetails" />

		<SceneOptionsRolesInstructions
			v-else-if="currentModal === 'opRolesInstructions'" 
			:instruction="currentDetails"			 
			:previous="previousData" 
			:show-details="showDetails" />
			
		<SceneRolesList
			v-else-if="currentModal === 'scnRolesInstructionsList'" 
			:data="currentDetails"			 
			:previous="previousData"
			:parent="parentData" 
			:show-details="showDetails" />

		<SceneRolesInstructions
			v-else-if="currentModal === 'scnRolesInstructions'" 
			:instruction="currentDetails"			 
			:previous="previousData" 
			:show-details="showDetails" />		

		<SceneSoundSongScores 
			v-else-if="currentModal === 'songScore'" 
			:song="currentDetails"
			:show-details="showDetails" />
			
		<SceneSoundSongScoreFiles 
			v-else-if="currentModal === 'songScoreFiles'" 
			:files="currentDetails"
			:previous="previousData"
			:show-details="showDetails" />
		
		<SceneSoundSongInstruments
			v-else-if="currentModal === 'songScoreInstruments'" 
			:song="currentDetails"
			:previous="previousData"
			:show-details="showDetails" />

		<SceneSoundSongInstrumentFiles 
			v-else-if="currentModal === 'songInstrumentFiles'" 
			:files="currentDetails"
			:previous="previousData"
			:show-details="showDetails" />		

		<SceneSoundBGMSongs 
			v-else-if="currentModal === 'bgmSong'" 
			:song="currentDetails"
			:show-details="showDetails" />
		
		<SceneSoundLiveSongs 
			v-else-if="currentModal === 'liveSong'" 
			:song="currentDetails"
			:show-details="showDetails" />
		
		<SceneSoundLiveScore
			v-else-if="currentModal === 'liveScore'" 
			:song="currentDetails"
			:previous="previousData"
			:parent="parentData"
			:show-details="showDetails" />
		
		<SceneSoundLiveScoreFiles
			v-else-if="currentModal === 'liveScoreFiles'" 
			:files="currentDetails"
			:previous="previousData"
			:show-details="showDetails" />			
	</tw-modal>
</template>

<script lang="ts">
	export default { name: 'SceneCard' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneDetailsRolesByRecordId
	} from '../queries'

	import { // language
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import {  
		store as _userStore
	} from '@/store/user'
	
	import { // vue
		computed,
		inject,
		onMounted,
		ref
	} from 'vue'

	import SceneDetails from './modals/scenes/SceneDetails.vue' // components
	import SceneVarRoles from './modals/scenes/variations/SceneVarRoles.vue'
	import SceneVarRolesList from './modals/scenes/variations/VarRolesInstructionsList.vue'
	import SceneVarRolesInstructions from './modals/scenes/variations/VarRoleInstructionsDetails.vue'
	import SceneOptionsTabs from './modals/scenes/options/SceneOptionsTabs.vue'
	import SceneOptionsRolesList from './modals/scenes/options/OptionRolesInstructionsList.vue'
	import SceneOptionsRolesInstructions from './modals/scenes/options/OptionRoleInstructionsDetails.vue'
	import SceneRolesList from './modals/scenes/roles/SceneRolesInstructionsList.vue'
	import SceneRolesInstructions from './modals/scenes/roles/SceneRoleInstructionsDetails.vue'
	import SceneSoundSongScores from './modals/scenes/soundDetails/SceneSoundSongScores.vue'
	import SceneSoundSongInstruments from './modals/scenes/soundDetails/SceneSoundSongInstruments.vue'
	import SceneSoundBGMSongs from './modals/scenes/soundDetails/SceneSoundBGMSongs.vue'
	import SceneSoundLiveScore from './modals/scenes/soundDetails/SceneSoundLiveScores.vue'
	import SceneSoundLiveSongs from './modals/scenes/soundDetails/SceneSoundLiveSongs.vue'
	import SceneSoundSongScoreFiles from './modals/scenes/soundDetails/SceneSoundSongScoresFiles.vue'
	import SceneSoundSongInstrumentFiles from './modals/scenes/soundDetails/SceneSoundSongInstrumentsFiles.vue'
	import SceneSoundLiveScoreFiles from './modals/scenes/soundDetails/SceneSoundLiveScoreFiles.vue'

	const props = defineProps({
		scene: {
			type: Object,
			default: () => {return {}}
		},
		jobName: {
			type: String,
			default: ""
		}
	})

	const userStore = _userStore()
	const _localesStore = localesStore()

	const {t} = useI18n()

	const loading = ref(true)
	const s3: any = inject('s3')
	const signedUrl = ref('')
	const standardRoles = ref([])
	const musicianRoles = ref([])

	onMounted(async () => {
		// load scene roles for staff
		if(userStore.getContactType === 'Staff') {
			try { // fetching scene details	
				const recordId = props.scene.recordId			
				let res = await getSceneDetailsRolesByRecordId(recordId, "")
				const record = res.response.data[0]				
				standardRoles.value = record.portalData.standard_roles_staff
				musicianRoles.value = record.portalData.musician_roles_staff
			}
			catch(e) {
				console.error(e)
			}
		}
		// load image from s3
		try {
			const s3Json = JSON.parse(props.scene?.['job__job_scene__CONTACTS_SCENES__contactSceneID::s3JSONPicture'] || "{}")
			const key = s3Json?.thumbnail?.s3FilePathShortRaw
			
			if(key) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: key
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrl.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})

	const modalTitle = computed(() => {
		
		switch(currentModal.value) {
		case 'sceneDetails': 
			return selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display']
		case 'varRoleDetails':
			return currentDetails?.value?.['jobs_scenes__jobs_variations__VARIATIONS__variationID::name_display']
		case 'varRolesInstructionsList':
			return _localesStore.getLabel('181')
		case 'varRolesInstructions':
			return currentDetails?.value?.['jobs_variations_roles__instructions_join_jobs__INSTRUCTIONS__instructionID::name_display']
		case 'optionsTabs':
			return currentDetails?.value?.['jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::name_display']
		case 'opRolesInstructionsList':
			return _localesStore.getLabel('181')
		case 'opRolesInstructions':
			return currentDetails?.value?.['jobs_scenes_options_roles__instructions_join_jobs__INSTRUCTIONS__instructionID::name_display']
		case 'scnRolesInstructionsList':
			return _localesStore.getLabel('181')
		case 'scnRolesInstructions':
			return currentDetails?.value?.['jobs_scenes_roles__instructions_join_jobs__INSTRUCTIONS__instructionID::name_display']
		case 'songScore':
			return _localesStore.getLabel('124')
		case 'songScoreFiles':
			return _localesStore.getLabel('575')
		case 'songScoreInstruments':
			return _localesStore.getLabel('287')
		case 'songInstrumentFiles':
			return 'Instrument Files'
		case 'bgmSong':
			return _localesStore.getLabel('182')
		case 'liveSong':
			return _localesStore.getLabel('182')
		case 'liveScore':
			return _localesStore.getLabel('124')
		case 'liveScoreFiles':
			return _localesStore.getLabel('575')	
		default: 
			return ''
		}
	})

	

	const sceneDetailsBreadcrumb = computed(() => {

		switch(currentModal.value) {
		case 'sceneDetails':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
			]
		case 'varRoleDetails':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('394'), to: "" }
			]
		case 'optionsTabs':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('329'), to: "" }
			]
		case 'varRolesInstructionsList':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('394'), to:"" }, // variations
				{label: _localesStore.getLabel('102'), to: "" }, // roles
				{label: currentDetails?.value?.['jobs_variations__jobs_variations_roles_musician__ROLES__roleID::name_display'] ||
					currentDetails?.value?.['jobs_variations__jobs_variations_roles_standard__ROLES__roleID::name_display'], to:""}  
			]
		case 'varRolesInstructions':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('394'), to:""}, // variations
				{label: _localesStore.getLabel('102'), to:"" }, // roles
				{label: previousData?.value?.['jobs_variations__jobs_variations_roles_musician__ROLES__roleID::name_display'] ||
					previousData?.value?.['jobs_variations__jobs_variations_roles_standard__ROLES__roleID::name_display'], to:"" },
				{label: _localesStore.getLabel('181'), to:"" }, // Instructions  
			]
		case 'opRolesInstructionsList':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('329'), to:"" }, // options
				{label: _localesStore.getLabel('102'), to:"" }, // roles
				{label: currentDetails?.value?.['jobs_scenes_options__jobs_scenes_options_roles__ROLES__roleID::name_display'], to:"" }  
			]
		case 'opRolesInstructions':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('329'), to:"" }, // options
				{label: _localesStore.getLabel('102'), to:""}, // roles
				{label: previousData?.value?.['jobs_scenes_options__jobs_scenes_options_roles__ROLES__roleID::name_display'], to:""},
				{label: _localesStore.getLabel('181'), to:"" }
			]
		case 'scnRolesInstructionsList':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('102'), to:"" }
			]
		case 'scnRolesInstructions':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('102'), to:"" },
				{label: previousData?.value?.['jobs_scenes__jobs_scenes_roles_standard__ROLES_roleID::name_display'] ||
					previousData?.value?.['jobs_scenes__jobs_scenes_roles_musician__ROLES_roleID::name_display'], to:""},
				{label: _localesStore.getLabel('181'), to:""}
			]
		case 'songScore':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('694'), to: ""}, 
				{label: currentDetails?.value?.['jobs_scenes__jobs_songs__SONGS__songsId::name_display'], to: ""},

			]
		case 'songScoreFiles':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: "...", to: ""}, 
				{label:  _localesStore.getLabel('124'), to: ""}, 
				{label: currentDetails?.value?.['jobs_songs__jobs_songs_scores__SCORES__scoreID::name_display'], to: ""},

			]
		case 'songScoreInstruments':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: "...", to: ""}, 
				{label:  _localesStore.getLabel('124'), to: ""}, 
				{label: currentDetails?.value?.['jobs_songs__jobs_songs_scores__SCORES__scoreID::name_display'], to: ""},
			]
		case 'songInstrumentFiles':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: "...", to: ""}, 
				{label:  _localesStore.getLabel('287'), to: ""}, 
				{label: currentDetails?.value?.['jobs_scongs_scores__instrument_jobs__ROLES__roleID::name_display'], to: ""},

			]
		case 'bgmSong':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('694'), to: ""}, 
				{label: currentDetails?.value?.['jobs_scenes__jobs_bgms__BGMS__bgmID::name_display'], to: ""},

			]
		case 'liveSong':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('694'), to: ""}, 
				{label: currentDetails?.value?.['jobs_scenes__jobs_live_ROLES__roleID::name_display'], to: ""}
			]
		case 'liveScore':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: _localesStore.getLabel('694'), to: ""}, 
				{label: previousData?.value?.['jobs_scenes__jobs_live_ROLES__roleID::name_display'], to: ""},
				{label:  _localesStore.getLabel('182'), to: ""},
				{label: currentDetails?.value?.['jobs_live__live_songs__SONGS__songID::name_display'], to: ""}, 
			]
		case 'liveScoreFiles':
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
				{label: props?.jobName, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
				{label: _localesStore.getLabel('178'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#scenes' }}, // scenes
				{label: selectedScene?.value?.['job__JOBS_SCENES__jobID::scene_number'] +  '. ' + selectedScene?.value?.['job__job_scene__SCENES__sceneID::name_display'], to: ""}, 
				{label: "...", to: ""}, 
				{label:  _localesStore.getLabel('124'), to: ""},
				{label: currentDetails?.value?.['live_songs__live_scores__SCORES__scoreID::name_display'], to: ""}, 
			]
		default: 
			return []
		}		
	})

	const selectedScene = ref({})
	const openToTab = ref(0)
	const sceneDetailsModal = ref<{open: boolean}>({open: false})
	const currentModal = ref("sceneDetails")
	const currentDetails = ref()
	const previousData = ref()
	const parentData = ref()
	const modalHistory = ref([])
	
	function showDetails(row: any, screen: string, tab: number, previous: any, direction: number = 0) {

		if(row != null) {
			if(!direction) {
				if(previous)modalHistory.value.push(previous)
				currentDetails.value = row
			}
			else {
				currentDetails.value = modalHistory.value.pop()
			}
		}
		currentModal.value = screen
		if(screen === 'varRoleDetails' || screen == 'optionsTabs' || screen == 'liveSong') {
			parentData.value = row
		}
		if(tab > 4 && userStore.getContactType != 'Staff') {
			openToTab.value = 4
		}
		else {
			openToTab.value = tab
		}
		if(previous != null) {
			previousData.value = previous
		}
	}

	function openModalDetails(row: any, tab: number) {
		selectedScene.value = row
		openToTab.value = tab
		sceneDetailsModal.value.open = true
		currentModal.value = "sceneDetails"
	}

	function modalClose() {
		sceneDetailsModal.value.open = false
	}

	const hasRoles = computed(() => {
		/**
		 * TODO - this is the part that doesn't work
		 */
		return standardRoles.value.length || musicianRoles.value.length
	})
</script>