<template>
	<div 
		class="link">
		<div
			@click="showDetails(null, 'sceneDetails', 2, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToVariations") }}</span>
			</div>
		</div>
	</div>
	<tw-table
		:placeholder="t('placeholders.noRoles')"
		:data="groupedData"
		:loading="loading">
		<!-- role -->
		<tw-table-column 
			:header="_localesStore.getLabel('102')">			
			<template #default="{row}">
				{{ row['jobs_variations__jobs_variations_roles_musician__ROLES__roleID::name_display'] ||
					row['jobs_variations__jobs_variations_roles_standard__ROLES__roleID::name_display'] }}
			</template>
		</tw-table-column>

		<!-- instructions -->
		<tw-table-column
			v-if="isStaff"
			class-name="w-6"
			header-class-name="w-6"
			align-text="right">
			<template #default="{row}">
				<div
					class="h-5 w-5 cursor-pointer"
					@click="showDetails(row, 'varRolesInstructionsList', null, variation)">
					<img
						:class="[ hasInstructions(row) ? 'linked-icon' : 'grayed-icon']"
						src="@/assets/icons/solid/list-bullet-fill.svg"
						alt="list icon">
				</div>
			</template>
		</tw-table-column>
	</tw-table>
</template>

<script lang="ts">
	export default { name: 'SceneVarRoles' } // name the component
</script>

<script setup lang="ts">
	import { // api
		getSceneVariationRolesByRecordId
	} from '../../../../queries'

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	// vue
	import {
		computed, 
		ref,
		onMounted
	} from 'vue'

	const props = defineProps({
		showDetails:{
			type: Function,
			default: () => {return}
		},
		variation: {
			type: Object,
			default: () => {return {}}
		} 
	})

	const userStore = _userStore()
	const _localesStore = localesStore()

	const {t} = useI18n()

	const musicianRoles = ref()
	const standardRoles = ref()
	const loading = ref(true)
	const isStaff = ref(userStore.getContactType === 'Staff')

	onMounted(async () => {
		try {
			loading.value = true

			const recordId = props.variation.recordId
			const contactId = userStore.user.contactId
			const jobId = props.variation['JOBS_SCENES::_kf_jobs_id']
			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
				contactId: contactId,
				jobId: jobId
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			// fetch job by record id
			let res = await getSceneVariationRolesByRecordId(recordId, params)
			const record = res.response.data[0]
			
			musicianRoles.value = userStore.getContactType == 'Staff' ? record.portalData.musician_roles_staff : record.portalData.jobs_variations__JOBS_VARIATIONS_ROLES__jobsVariationsInstrumentalID
			standardRoles.value = userStore.getContactType == 'Staff' ? record.portalData.standard_roles_staff : record.portalData.jobs_variations__JOBS_VARIATIONS_ROLES__jobsVariationsStandardID		
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})

	const groupedData = computed(() => {
		let headings = []

		if(standardRoles?.value?.length) {
			headings.push(
				{subheading: 'Standard Roles'},
				...standardRoles.value
			)
		}

		if(musicianRoles?.value?.length) {
			headings.push(
				{subheading: 'Musician Roles'},
				...musicianRoles.value
			)
		}
		
		return headings
	})

	function hasInstructions(row: any) {
		return row?.['jobs_variations__jobs_variations_roles__INSTRUCTIONS_JOIN_JOBS_jobsVariationsRoleID_instrumental::_kp_instructions_join_jobs_id'] || 
			row?.['jobs_variations__jobs_variations_roles__INSTRUCTIONS_JOIN_JOBS_jobsVariationsRoleID_standard::_kp_instructions_join_jobs_id'] 
	}

</script>