<template>
	<div class="grid grid-cols-2 gap-4 px-4">
		<div class="col-span-1">
			<data-display>
				<template #custom>
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !job?.fieldData?.introductions}">
						<CheckIconMini
							v-if="job?.fieldData?.introductions"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<!-- introductions -->
						<span class="font-medium">{{ store.getLabel('347') }}</span>
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-1">
			<data-display>
				<template #custom>
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !job?.fieldData?.introductions_participate}">
						<CheckIconMini
							v-if="job?.fieldData?.introductions_participate"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<!-- bride/groom participate -->
						<span class="font-medium">{{ store.getLabel('348') }}</span>
					</div>
				</template>
			</data-display>
		</div>		
		<div class="col-span-1">
			<!-- groom count -->
			<data-display
				:loading="loading"
				:label="store.getLabel('240')"
				:value="job?.fieldData?.introductions_groom || '—'" />
		</div>
		<div class="col-span-1">
			<!-- representation -->
			<data-display
				:loading="loading"
				:label="store.getLabel('346')"
				:value="job?.fieldData?.representative_groom_eng || '—'" />
		</div>
		<div class="col-span-1">
			<!-- bride count -->
			<data-display
				:loading="loading"
				:label="store.getLabel('241')"
				:value="job?.fieldData?.introductions_bride || '—'" />
		</div>
		<div class="col-span-1">
			<!-- representation -->
			<data-display
				:loading="loading"
				:label="store.getLabel('346')"
				:value="job?.fieldData?.representative_bride_eng || '—'" />
		</div>
		<div class="col-span-1">
			<data-display>
				<template #custom>
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !job?.fieldData?.firstMeet}">
						<CheckIconMini
							v-if="job?.fieldData?.firstMeet"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<!-- bride/groom participate -->
						<span class="font-medium">{{ store.getLabel('809') }}</span>
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-1">
			<data-display>
				<template #custom>
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !job?.fieldData?.familyMeet}">
						<CheckIconMini
							v-if="job?.fieldData?.familyMeet"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<!-- bride/groom participate -->
						<span class="font-medium">{{ store.getLabel('810') }}</span>
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-1">
			<!-- representation -->
			<data-display
				:loading="loading"
				:label="store.getLabel('332')"
				:value="$filters.hour24Time(job?.fieldData?.timeFirstTime) || '—'" />
		</div>
		<div class="col-span-1">
			<!-- representation -->
			<data-display
				:loading="loading"
				:label="store.getLabel('332')"
				:value="$filters.hour24Time(job?.fieldData?.timeFamilyTime) || '—'" />
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobIntroductions' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()
</script>