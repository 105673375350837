<template>
	<div class="grid grid-cols-3 gap-4">
		<div class="border-r-2 mx-1">
			<div class="grid grid-cols-2 gap-4">
				<!-- Type Display -->
				<div>
					<data-display
						:loading="loading"
						:label="store.getLabel('185')"
						:value="transportationType" />
				</div>
				
				<!-- Transportation cost-->
				<div>				
					<data-display
						:loading="loading"
						:label="store.getLabel('45')"
						:value="$filters.jpy(transportationCost)" />
				</div>
			</div>
			<div class="grid grid-cols-3 mt-6">
				<!-- time-->
				<div>
					<data-display
						:loading="loading"
						:label="store.getLabel('332')"
						:value="$filters.timeDuration(time)" />
				</div>

				<!-- Agreement confirmation -->
				<div v-if="!transportationType?.includes('Emergency')">
					<data-display
						:loading="loading"
						:label="store.getLabel('32')"
						:value="attendee?.fieldData?.date_last_checked" />
				</div>

				<!-- Primary -->
				<div
					class="mt-5"
					v-if="!transportationType?.includes('Emergency')">
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !attendee?.fieldData?.primary}">
						<CheckIconMini
							v-if="attendee?.fieldData?.primary"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<span class="font-medium">{{ store.getLabel('292') }}</span>
					</div>
				</div>
			</div>

			<!-- multiple transportation table -->
			<div v-if="attendee?.portalData?.attendees__TRANSPORTATION__multipleID?.length">
				<tw-table
					:data="attendee?.portalData?.attendees__TRANSPORTATION__multipleID">
					<!-- Type -->
					<tw-table-column
						:header="store.getLabel('1')">
						<template #default="{row}">
							{{ row["attendees__TRANSPORTATION__multipleID::type_display"] }}
						</template>
					</tw-table-column>

					<!-- Cost -->
					<tw-table-column
						:header="store.getLabel('229')">
						<template #default="{row}">
							{{ $filters.jpy(row["attendees__TRANSPORTATION__multipleID::cost"]) }}
						</template>
					</tw-table-column>

					<!--Time-->
					<tw-table-column
						:header="store.getLabel('332')">
						<template #default="{row}">
							{{ $filters.timeDuration(row["attendees__TRANSPORTATION__multipleID::time"]) }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>

			<!-- emergency transportation table -->
			<div v-if="transportationType?.includes('Emergency') && attendee?.portalData?.attendees__TRANSPORTATION__attendeesID?.length">
				<tw-table
					:data="attendee?.portalData?.attendees__TRANSPORTATION__attendeesID">
					<!-- Type -->
					<tw-table-column
						:header="store.getLabel('1')">
						<template #default="{row}">
							{{ row["attendees__TRANSPORTATION__attendeesID::type_display"] }}
						</template>
					</tw-table-column>

					<!-- Cost -->
					<tw-table-column
						:header="store.getLabel('229')">
						<template #default="{row}">
							{{ $filters.jpy(row["attendees__TRANSPORTATION__attendeesID::cost"]) }}
						</template>
					</tw-table-column>

					<!--Time-->
					<tw-table-column
						:header="store.getLabel('332')">
						<template #default="{row}">
							{{ $filters.timeDuration(row["attendees__TRANSPORTATION__attendeesID::time"]) }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>		
		<div class="grid grid-cols-2 text-right m-auto border-r-2 mt-0">
			<!-- Role Cost-->			
			<data-display
				:loading="loading"
				:label="store.getLabel('43')"				
				:value="$filters.jpy(attendee?.fieldData?.cost)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Other-->
			<data-display
				:loading="loading"
				:label="store.getLabel('716') + ' (1)'"
				:value="$filters.jpy(attendee?.fieldData?.other1_withholding)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Total Before Taxes-->
			<data-display
				:loading="loading"
				:label="store.getLabel('718')"
				:value="$filters.jpy(attendee?.fieldData?.total_before_tax)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Consumption Tax-->
			<data-display
				:loading="loading"
				:label="store.getLabel('372')"
				:value="$filters.jpy(attendee?.fieldData?.consumption_withholding)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Total Before Witholding-->
			<data-display
				:loading="loading"
				:label="store.getLabel('717')"
				:value="$filters.jpy(attendee?.fieldData?.total_before_taxes_witholding)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Witholding Tax-->
			<data-display
				:loading="loading"
				:label="store.getLabel('715')"
				:value="$filters.jpy(attendee?.fieldData?.withholding_amount)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Subtotal-->
			<data-display
				:loading="loading"
				:label="store.getLabel('373')"
				:value="$filters.jpy(attendee?.fieldData?.subtotal_withholding)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Other -->
			<data-display
				:loading="loading"
				:label="store.getLabel('716') + ' (2)'"
				:value="$filters.jpy(attendee?.fieldData?.other2_withholding)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />

			<!-- Grand Total -->
			<data-display
				:loading="loading"
				:label="store.getLabel('374')"
				:value="$filters.jpy(attendee?.fieldData?.grand_total_withholding)"
				value-class="text-right pr-18" 
				label-class="-ml-4" />
		</div>
		<div class="mx-1 pr-5">
			<div class="grid grid-cols-1 gap-4">
				<!-- Memo 1-->
				<div>
					<data-display
						:loading="loading"
						:label="store.getLabel('726') + ' (1)'"
						:value="attendee?.fieldData?.memo_display_other1" />
				</div>

				<!-- Memo 2-->
				<div>
					<data-display
						:loading="loading"
						:label="store.getLabel('726') + ' (2)'"
						:value="attendee?.fieldData?.memo_display_other2" />
				</div>			
			</div>

			<div class="grid grid-cols-2 gap-4 mt-4 ">
				<div
					v-for="document in attendee?.portalData?.['attendees__DOCUMENTS__attendeesTaxesID']"
					:key="document.recordId">
					<a
						target="_blank"
						class="w-full truncate hover:opacity-70"
						:href="document?.signedUrl"
						download>
						<div
							v-if="document.ext == 'jpeg' || document.ext == 'jpg' || document.ext == 'png' || document.ext == 'gif'"
							class="flex bg-gray-100 border-2 border-gray-200  w-full h-24 m-auto text-brand-500 rounded-md">
							<img
								:src="document.signedUrl"
								class="w-full h-full object-cover rounded-md">
						</div>
						<div
							v-else
							class="flex bg-gray-100 border-2 border-gray-200  w-full h-24 m-auto text-brand-500 rounded-md">
							<img
								class="linked-icon w-12 m-auto"
								src="@/assets/icons/solid/document-fill.svg"
								alt="document icon">
						</div>
						<div class="text-gray-900 font-medium mt-2">
							{{ document?.['attendees__DOCUMENTS__attendeesTaxesID::name_display'] }}
						</div>
						<div class="text-sm">
							{{ document?.['attendees__DOCUMENTS__attendeesTaxesID::description_display'] }}
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts">
	export default { name: 'TransportationDetails' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		computed
	} from 'vue'
	
	const props = defineProps({
		attendee: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()
	
	const transportationType = computed(() => {
		return props.attendee?.fieldData?.emergency_transport ? store.getLabel('684') : props.attendee?.fieldData?.['attendees__TRANSPORTATION__transportationID::type_display']
	})

	const transportationCost = computed(() => {
		return props.attendee?.fieldData?.cost_transportation || 
			props.attendee?.fieldData?.cost_emergency
	})

	const time = computed(() => {
		if(props.attendee?.fieldData?.['attendees__TRANSPORTATION__transportationID::type_display'] === 'Multiple') {
			return props.attendee?.fieldData?.['attendees__TRANSPORTATION__transportationID::time_multiple']
		}
		else {
			return props.attendee?.fieldData?.time || props.attendee?.fieldData?.time_emergency
		}
	})
</script>