import Account from './AccountMain.vue'
import MainLayout from '@/layouts/MainLayout.vue'

const config = {
	mainRoute: {
		name: "Account",
		path: "/account",
		component: Account,
		layout: MainLayout
	},
	nav: {
		name: 'nav.account',
		to: "Account",
		position: 2,
		show: true
	},
	module: "account"
}

export { config }
