<template>
	<div class="grid grid-cols-1 gap-4 lg:gap-6 -mt-10 -mb-4">
		<tw-table
			class="hidden lg:block"
			:placeholder="t('placeholders.noAreas')"
			:data="areas"
			:loading="loading">
			<!-- area -->
			<tw-table-column 
				:header="localesStore.getLabel('17')">
				<template #default="{row}">
					{{ row['contact__are__AREAS_COUNTRIES__areaCountryID::area_display'] }}
				</template>
			</tw-table-column>
			<!-- primary -->
			<tw-table-column 
				:header="localesStore.getLabel('292')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['contact__AREAS__contactID::primary']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else-if="row['contact__AREAS__contactID::primary'] === ''"
						class="h-5 w-5 text-gray-300" />
					<div v-else>
						&nbsp;
					</div>
				</template>
			</tw-table-column>
		</tw-table>

		<tw-table
			class="lg:hidden block"
			:placeholder="t('placeholders.noAreas')"
			:data="contact?.portalData?.portal_areas"
			:loading="loading">
			<!-- area -->
			<tw-table-column 
				:header="localesStore.getLabel('17')">
				<template #default="{row}">
					{{ row['contact__are__AREAS_COUNTRIES__areaCountryID::area_display'] }}
				</template>
			</tw-table-column>
			<!-- primary -->
			<tw-table-column 
				:header="localesStore.getLabel('292')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['contact__AREAS__contactID::primary']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else-if="row['contact__AREAS__contactID::primary'] === ''"
						class="h-5 w-5 text-gray-300" />
					<div v-else>
						&nbsp;
					</div>
				</template>
			</tw-table-column>
		</tw-table>	
	</div>
</template>

<script lang="ts">
// name the component
	export default { name: 'AreaDetails' }
</script>

<script setup lang="ts">
	// languages
	import { 
		useI18n
	} from 'vue-i18n'

	// store
	import { 
		localesStore as _localesStore
	} from '@/store/locales'

	// vue
	import {
		computed
	} from 'vue'
	
	const props = defineProps({
		contact: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const localesStore = _localesStore()

	const {t} = useI18n()

	const areas = computed(() => {
		const numEmail = props?.contact?.portalData?.portal_emails.length
		const numPhone = props?.contact?.portalData?.portal_phone_numbers.length
		const numAreas = props?.contact?.portalData?.portal_areas.length
		const numToAdd = numEmail + numPhone + 2 - numAreas
		let _areas = [...props?.contact?.portalData?.portal_areas || []]
		for(let i = 0 ; i < numToAdd ; i++) {
			_areas.push({})
		}
		return _areas
	})
	
</script>