<template>
	<div>
		<h1 class="text-2xl font-medium text-gray-900 ">
			{{ t('resetPassword.email') }}
		</h1>
		<p class="mt-4 ">
			{{ t('resetPassword.instructions') }}
		</p>
	</div>

	<div class="flex items-center mt-3 text-sm  text-brand-600">
		<router-link
			class="link"
			:to="{name: 'Login'}">
			{{ t('resetPassword.returnToLoginScreen') }}
		</router-link>
	</div>
</template>

<script lang="ts">
	export default { name: 'ResetPasswordSuccess' } // name the component
</script>

<script setup lang="ts">
	import { 
		useI18n
	} from 'vue-i18n'

	const {t} = useI18n()
</script>