<template>
	<div 
		class="grid grid-cols-4 gap-4 px-4">
		<div class="col-span-2 lg:col-span-1">
			<!-- type -->
			<data-display
				:loading="loading"
				:label="store.getLabel('1')"
				:value="job?.fieldData?.type_display || '—'" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- category -->
			<data-display
				:loading="loading"
				:label="store.getLabel('11')"
				:value="job?.fieldData?.category_display || '—'" />
		</div>
		<div class="col-span-2">
			<!-- service -->
			<data-display
				:loading="loading"
				:label="store.getLabel('173')"
				:value="job?.fieldData?.['job__CONTACTS_SERVICES__contactServicesID::name_display'] || '—'" />
		</div>
		<div class="col-span-2">
			<!-- location -->
			<data-display
				:loading="loading"
				:label="store.getLabel('217')"
				:value="job?.fieldData?.['job__CONTACTS__locationID::company_display'] || '—'" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- style -->
			<data-display
				:loading="loading"
				:label="store.getLabel('129')"
				:value="job?.fieldData?.['jobs__contacts_services__services__STYLES__stylesID::name_display'] || '—'" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- theme -->
			<data-display
				:loading="loading"
				:label="store.getLabel('130')"
				:value="job?.fieldData?.['jobs__contacts_services__services__THEMES__themesID::name_display'] || '—'" />
		</div>
		<div class="col-span-2">
			<!-- room -->
			<data-display
				:loading="loading"
				:label="store.getLabel('146')"
				:value="job?.fieldData?.['job__ROOMS__roomID::name_full_display'] || '—'" />
		</div>
		<div
			v-if="userStore.getContactType == 'Staff'" 
			class="col-span-2">
			<!-- area -->
			<data-display
				:loading="loading"
				:label="store.getLabel('17')"
				:value="job?.fieldData?.['job__contacts__AREAS_COUNTRIES__locationID::area_display'] || '—'" />
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobDetailsLocation' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()
	const userStore = _userStore()
</script>