<template>
	<div class="grid grid-cols-3 gap-6 w-full">
		<div class="col-span-3 lg:col-span-1">
			<!-- service restrictions -->
			<data-display
				:label="store.getLabel('260')">
				<template #custom>
					<div class="grid grid-cols-2 gap-4 grid-flow-col grid-rows-4">
						<div
							v-for="restriction in covidService"
							:key="restriction.label"
							class="flex justify-start gap-2 items-center"
							:class="{'opacity-30': !restriction.checked}">
							<CheckIconMini
								v-if="restriction.checked"
								class="h-5 w-5" />
							<MinusIconMini
								v-else
								class="h-5 w-5" />
							<span class="font-medium">{{ restriction.label }}</span>
						</div>
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-3 lg:col-span-1">
			<!-- rehearsal restrictions -->
			<data-display
				:label="store.getLabel('261')">
				<template #custom>
					<div class="grid grid-cols-2 gap-4 grid-flow-col grid-rows-4">
						<div
							v-for="restriction in covidRehearsal"
							:key="restriction.label"
							class="flex justify-start gap-2 items-center"
							:class="{'opacity-30': !restriction.checked}">
							<CheckIconMini
								v-if="restriction.checked"
								class="h-5 w-5" />
							<MinusIconMini
								v-else
								class="h-5 w-5" />
							<span class="font-medium">{{ restriction.label }}</span>
						</div>
					</div>
				</template>
			</data-display>
		</div>
	</div>
	<div class="grid grid-cols-3 gap-6 w-full mt-6">
		<div class="col-span-3 lg:col-span-1">
			<!-- photo -->
			<data-display
				:label="store.getLabel('293')">
				<template #custom>
					<div
						v-if="filesLoading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<img
						v-else-if="signedUrlPhoto"
						class="object-cover w-96 h-75 rounded-md"
						:src="signedUrlPhoto">
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-3 lg:col-span-1">
			<!-- video -->
			<data-display
				:label="store.getLabel('294')">
				<template #custom>
					<div
						v-if="filesLoading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<video
						v-else-if="signedUrlVideo"
						controls
						class="object-cover w-96 h-75 rounded-md">
						<source
							type="video/mp4"
							:src="signedUrlVideo">
					</video>
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/video-fill.svg"
							alt="video icon">
					</div>
				</template>
			</data-display>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'RoleCovidRestrictions' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import {
		store as _valueListStore
	} from '@/store/valueLists'

	import { // vue
		computed,
		inject,
		onMounted,
		ref
	} from 'vue'

	const props = defineProps({
		role: {
			type: Object,
			default: () => {return {}}
		}
	})

	const store = localesStore()
	const valueListStore = _valueListStore()

	const filesLoading = ref(true)
	const fetchS3File: any = inject('fetchS3File')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	onMounted(async () => {
		fetchSceneMedia()
	})

	async function fetchSceneMedia() {
		try { // fetching covid restrictions image and video from s3
			filesLoading.value = true
			const s3JsonPhoto = 
				props.role?.['job__JOBS_ROLES__standardJobID_gStaffID::s3JSONPictureCovid'] ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::s3JSONPictureCovid'] ||
				props.role?.['job__JOBS_ROLES__liveJobID_gStaffID::s3JSONPictureCovid'] ||
				props.role?.['job__JOBS_ROLES__standardJobID::s3JSONPictureCovid'] ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID::s3JSONPictureCovid'] ||
				props.role?.['job__JOBS_ROLES__liveJobID::s3JSONPictureCovid']

			const s3JsonVideo =
				props.role?.['job__JOBS_ROLES__standardJobID_gStaffID::s3JSONVideoCovid'] ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::s3JSONVideoCovid'] ||
				props.role?.['job__JOBS_ROLES__liveJobID_gStaffID::s3JSONVideoCovid'] ||
				props.role?.['job__JOBS_ROLES__standardJobID::s3JSONVideoCovid'] ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID::s3JSONVideoCovid'] ||
				props.role?.['job__JOBS_ROLES__liveJobID::s3JSONVideoCovid']

			signedUrlPhoto.value = await fetchS3File(s3JsonPhoto)
			signedUrlVideo.value = await fetchS3File(s3JsonVideo)
		}
		catch(e) {
			console.error(e)
		}
		finally {
			filesLoading.value = false
		}
	}

	const covidService = computed(() => {
		
		let vl
		switch(store.locales.selected) {
		case 'en':
			vl = valueListStore.valueList.covidRestrictionsEn
			break
		case 'ja':
			vl = valueListStore.valueList.covidRestrictionsJa
			break
		case 'kana':
			vl = valueListStore.valueList.covidRestrictionsKana
			break
		default:
			vl = valueListStore.valueList.covidRestrictionsEn
		}

		let restrictions = []
		for(let i = 0; i < vl.length ; i++) {
			const value = vl[i].value
			restrictions.push({label: value, checked: false},)
		}

		restrictions.forEach(restriction => {
			if(props.role?.['job__JOBS_ROLES__instrumentalJobID::covid_service']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::covid_service']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__standardJobID_gStaffID::covid_service']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__liveJobID_gStaffID::covid_service']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__standardJobID::covid_service']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__liveJobID::covid_service']?.includes(restriction.label)) {
				restriction.checked = true
			}
		})


		return restrictions
	})

	const covidRehearsal = computed(() => {
		let vl
		switch(store.locales.selected) {
		case 'en':
			vl = valueListStore.valueList.covidRehearsalRestrictionsEn
			break
		case 'ja':
			vl = valueListStore.valueList.covidRehearsalRestrictionsJa
			break
		case 'kana':
			vl = valueListStore.valueList.covidRehearsalRestrictionsKana
			break
		default:
			vl = valueListStore.valueList.covidRehearsalRestrictionsEn
		}

		let restrictions = []

		for(let i = 0; i < vl.length ; i++) {
			const value = vl[i].value
			restrictions.push({label: value, checked: false},)
		}

		restrictions.forEach(restriction => {
			if(props.role?.['job__JOBS_ROLES__instrumentalJobID::covid_rehearsal']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::covid_rehearsal']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__standardJobID_gStaffID::covid_rehearsal']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__liveJobID_gStaffID::covid_rehearsal']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__standardJobID::covid_rehearsal']?.includes(restriction.label) ||
				props.role?.['job__JOBS_ROLES__liveJobID::covid_rehearsal']?.includes(restriction.label)) {
				restriction.checked = true
			}
		})

		return restrictions
	})
</script>