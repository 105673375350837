<template>
	<Disclosure
		as="nav"
		class="bg-gray-50 sticky top-0 z-30"
		v-slot="{ open }">
		<div class="mx-auto max-w-screen-2xl px-4 py-6 sm:px-6 lg:px-8">
			<div class="flex justify-between items-center">
				<router-link :to="{name: 'Jobs'}">
					<img
						class="block h-18 w-auto"
						src="@/assets/images/Crystal_Wedding_Logo_Submark_v2.svg"
						alt="Heartful Logo">
				</router-link>
				<div class="flex">
					<div class="hidden sm:ml-6 sm:flex sm:space-x-8">
						<router-link 
							@click="navigate" 
							v-for="item in navigation" 
							:key="item.name" 
							:to="item.to" 
							class="relative font-gotham"
							:class="[$route.meta.module == item.module ? 'text-teal-500 half-border-b' : '', 'hover:half-border-black-b justify-center flex items-center px-2 py-2 font-normal text-sm']">
							<span>{{ t(item.name) }}</span>
						</router-link>
						<a
							href="#"
							@click="signOut"
							class="relative font-gotham hover:half-border-black-b justify-center flex items-center px-2 py-2 font-normal text-sm">{{ t('nav.signOut') }}</a>
					</div>
				</div>
				<div class="-mr-2 flex items-center sm:hidden">
					<!-- Mobile menu button -->
					<DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-brand-500">
						<span class="sr-only">Open main menu</span>
						<Bars3Icon
							v-if="!open"
							class="block h-6 w-6"
							aria-hidden="true" />
						<XMarkIcon
							v-else
							class="block h-6 w-6"
							aria-hidden="true" />
					</DisclosureButton>
				</div>
			</div>
		</div>

		<DisclosurePanel class="sm:hidden">
			<div class="space-y-1 pb-3 pt-2">
				<router-link 
					@click="navigate" 
					v-for="item in navigation" 
					:key="item.name" 
					:to="item.to">
					<DisclosureButton
						as="a"
						href="#"
						class="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700"
						:class="{'block border-l-4 border-brand-500 bg-brand-50 py-2 pl-3 pr-4 text-base font-medium text-brand-700': $route.meta.module == item.module}">
						<span>{{ t(item.name) }}</span>
					</DisclosureButton>
				</router-link>
			</div>
			<div class="border-t border-gray-200 pb-3 pt-4">
				<div
					class="mt-3 space-y-1">
					<DisclosureButton
						as="a"
						href="#"
						class="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
						@click="signOut">
						{{ t('nav.signOut') }}
					</DisclosureButton>
				</div>
			</div>
		</DisclosurePanel>
	</Disclosure>
</template>

<script setup lang="ts">
	import { 
		Auth
	} from '@/api/Auth'

	// tailwind
	import {
		Disclosure,
		DisclosurePanel,
		DisclosureButton
	} from '@headlessui/vue'
	import {
		Bars3Icon,
		XMarkIcon
	} from '@heroicons/vue/24/outline'

	
	// vue
	import {
		onBeforeMount,
		ref
	} from 'vue'

	import { 
		useI18n
	} from 'vue-i18n'


	const emit = defineEmits([
		'on-navigate'
	])

	const {t} = useI18n()

	// Check for logged in user
	const navigation = ref<any[]>([])

	onBeforeMount(async () => {
		// Load file path of all modules named Nav.vue
		const moduleContext = require.context('@/modules', true, /Nav.ts$/i)

		// Import each component
		const modules = moduleContext.keys()
			.map(moduleContext)
			.map(m => m.nav)

		// Sort them based on the position data prop
		navigation.value = modules.filter(m => {
			return m.show
		}).sort((a,b) => {
			let aPos = a.position
			let bPos = b.position
			if(aPos < bPos) {
				return -1
			}else if(aPos > bPos) {
				return 1
			}else{
				return 0
			}
		})
        
	})

	async function signOut() {
		try {
			await Auth.signOut()
		} 
		catch (error) {
			console.log('error signing out: ', error)
		}
	}

	function navigate() {
		emit('on-navigate')
	}

</script>