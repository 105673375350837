<template>
	<div class="-mt-10 -mb-4 min-h-44 max-h-44">
		<tw-table
			:placeholder="t('placeholders.noDiscounts')"
			:data="props?.job?.portalData?.portal_discounts"
			:loading="loading">
			<!-- percentage -->
			<tw-table-column 
				:header="store.getLabel('154')">
				<template #default="{row}">
					{{ $filters.percentage(row['job__DISCOUNTS__jobID::percentage_discount']) }}
				</template>
			</tw-table-column>
			<!-- reason -->
			<tw-table-column 
				:header="store.getLabel('155')"
				prop="job__DISCOUNTS__jobID::reason_display" />
		</tw-table>
	</div>
</template>

<script lang="ts">
// name the component
	export default { name: 'JobDiscounts' }
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'
	
	import { // store
		localesStore
	} from '@/store/locales'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const {t} = useI18n()
</script>