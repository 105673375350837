<template>
	<div class="-mt-10 -mb-4">
		<tw-table
			class="hidden lg:block"
			:placeholder="t('placeholders.noLocations')"
			:data="locations"
			:loading="loading">
			<!-- option -->
			<tw-table-column 
				header="">
				<template #default="{row}">
					{{ row['job__LOCATIONS__jobID::description_display'] }}
				</template>
			</tw-table-column>

			<!-- primary -->
			<tw-table-column 
				:header="_localesStore.getLabel('411')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__LOCATIONS__jobID::primary']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else-if="row['job__LOCATIONS__jobID::primary'] === ''"
						class="h-5 w-5 text-gray-300" />
					<div v-else>
						&nbsp;
					</div>
				</template>
			</tw-table-column>

			<!-- picture -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						v-if="row['recordId']"
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(row)">
						<img
							:class="[hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</tw-table-column>

			<!-- video -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						v-if="row['recordId']"
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(row)">
						<img
							:class="[hasVideo(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/video-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</tw-table-column>
		</tw-table>

		<tw-table
			class="lg:hidden block"
			:placeholder="t('placeholders.noLocations')"
			:data="props?.job?.portalData?.portal_locations"
			:loading="loading">
			<!-- option -->
			<tw-table-column 
				header="" 
				prop="job__LOCATIONS__jobID::description_display" />

			<!-- primary -->
			<tw-table-column 
				:header="_localesStore.getLabel('411')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__LOCATIONS__jobID::primary']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<!-- picture -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(row)">
						<img
							:class="[hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</tw-table-column>

			<!-- video -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(row)">
						<img
							:class="[hasVideo(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/video-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</tw-table-column>
		</tw-table>

		<tw-modal
			:title="selectedLocation?.['job__LOCATIONS__jobID::description_display']"
			:breadcrumb="locationDetailsBreadcrumb"
			ref="locationDetailsModal">
			<div class="grid grid-cols-3 gap-6">
				<div class="col-span-3 lg:col-span-1">
					<!-- photo -->
					<data-display
						:label="_localesStore.getLabel('293')">
						<template #custom>
							<div
								v-if="mediaLoading"
								class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
							<img
								v-else-if="signedUrlPhoto"
								class="object-cover w-96 h-75 rounded-md"
								:src="signedUrlPhoto">
							<div
								v-else
								class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
								<img
									class="grayed-icon h-10 w-10"
									src="@/assets/icons/solid/image-fill.svg"
									alt="picture icon">
							</div>
						</template>
					</data-display>
				</div>
				<div class="col-span-3 lg:col-span-1">
					<!-- video -->
					<data-display
						:label="_localesStore.getLabel('294')">
						<template #custom>
							<div
								v-if="mediaLoading"
								class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
							<video
								v-else-if="signedUrlVideo"
								controls
								class="object-cover w-96 h-75 rounded-md">
								<source
									type="video/mp4"
									:src="signedUrlVideo">
							</video>
							<div
								v-else
								class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
								<img
									class="grayed-icon h-10 w-10"
									src="@/assets/icons/solid/video-fill.svg"
									alt="video icon">
							</div>
						</template>
					</data-display>
				</div>			
			</div>
		</tw-modal>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobLocations' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		inject,
		computed,
		ref
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const _localesStore = localesStore()

	const {t} = useI18n()

	const locationDetailsBreadcrumb = computed(() => {
		return [
			{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
			{label: props?.job?.fieldData?.name_bride_groom_display, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
			{label: _localesStore.getLabel('218'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }} // Locations
		]
	})

	const selectedLocation = ref({})
	const locationDetailsModal = ref<{open: boolean}>({open: false})
	const mediaLoading = ref(false)
	const fetchS3File: any = inject('fetchS3File')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	async function openModalDetails(row: any) {
		selectedLocation.value = row
		locationDetailsModal.value.open = true
		try {
			mediaLoading.value = true
			signedUrlPhoto.value = await fetchS3File(row['job__LOCATIONS__jobID::s3JSONPicture'])
			signedUrlVideo.value = await fetchS3File(row['job__LOCATIONS__jobID::s3JSONVideo'])
		}
		catch(e) {
			console.error(e)
		}
		finally {
			mediaLoading.value = false
		}
	}

	const locations = computed(() => {
		const numItems = props?.job?.portalData?.portal_items.length
		const numLocations = props?.job?.portalData?.portal_locations.length
		const numToAdd = numItems - numLocations
		let _locations = [...props?.job?.portalData?.portal_locations || []]
		for(let i = 0 ; i < numToAdd ; i++) {
			_locations.push({})
		}
		return _locations
	})
	
	function hasPicture(row: any) {
		return row?.['job__LOCATIONS__jobID::s3JSONPicture']
	}

	function hasVideo(row: any) {
		return row?.['job__LOCATIONS__jobID::s3JSONVideo']
	}
</script>