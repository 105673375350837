<template>
	<div class="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-6">
		<div>
			<!-- photo -->
			<data-display
				:label="_localesStore.getLabel('293')">
				<template #custom>
					<div
						v-if="loading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<img
						v-else-if="signedUrlPhoto"
						class="object-cover w-96 h-75 rounded-md"
						:src="signedUrlPhoto">
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</data-display>
		</div>
		<div>
			<!-- video -->
			<data-display
				:label="_localesStore.getLabel('294')">
				<template #custom>
					<div
						v-if="loading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<video
						v-else-if="signedUrlVideo"
						controls
						class="object-cover w-96 h-75 rounded-md">
						<source
							type="video/mp4"
							:src="signedUrlVideo">
					</video>
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/video-fill.svg"
							alt="video icon">
					</div>
				</template>
			</data-display>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'OptionDetails' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		inject,
		ref,
		onMounted
	} from 'vue'

	const props = defineProps({
		option: {
			type: Object,
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()

	const loading = ref(true)
	const s3: any = inject('s3')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	onMounted(() => {
		// load image from s3
		try {
			const pictureField = props.option?.['job__JOBS_OPTIONS__jobID::s3JSONPicture'] ? props.option?.['job__JOBS_OPTIONS__jobID::s3JSONPicture'] : props.option?.['job__job_option__CONTACTS_OPTIONS__contactOptionsID::s3JSONPicture']
			const videoField = props.option?.['job__JOBS_OPTIONS__jobID::s3JSONVideo'] ? props.option?.['job__JOBS_OPTIONS__jobID::s3JSONVideo'] : props.option?.['job__job_option__CONTACTS_OPTIONS__contactOptionsID::s3JSONVideo']

			const photoS3Json = JSON.parse(pictureField ?? "{}")
			const photoKey = photoS3Json?.s3FilePathShortRaw
			const videoS3Json = JSON.parse(videoField ?? "{}")
			const videoKey = videoS3Json?.s3FilePathShortRaw
			
			if(photoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: photoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlPhoto.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
			if(videoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: videoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlVideo.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}

	})
</script>