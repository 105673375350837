<template>
	<div class="-mt-10 -mb-4">
		<tw-table
			:placeholder="t('placeholders.noOptions')"
			:data="props?.job?.portalData?.portal_options"
			:loading="loading">
			<!-- option -->
			<tw-table-column 
				:header="_localesStore.getLabel('329')" 
				prop="job__job_option__SERVICES_OPTIONS_serviceOptionID::name_display" />
				
			<!-- standard -->
			<tw-table-column 
				:header="_localesStore.getLabel('411')">
				<template #default="{row}">
					<CheckIconMini
						v-if="row['job__JOBS_OPTIONS__jobID::standard']"
						class="h-5 w-5 text-black" />
					<MinusIconMini
						v-else
						class="h-5 w-5 text-gray-300" />
				</template>
			</tw-table-column>

			<!-- category -->
			<tw-table-column 
				:header="_localesStore.getLabel('11')"
				prop="job__job_option__contact_option__SERVICES_OPTIONS__serviceOptionID::category_display" />

			<!-- description -->
			<tw-table-column 
				:header="_localesStore.getLabel('190')"
				prop="job__job_option__contact_option__SERVICES_OPTIONS__serviceOptionID::description_display" />

			<!-- picture -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(row)">
						<img
							:class="[hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</tw-table-column>

			<!-- video -->
			<tw-table-column
				class-name="w-6"
				header-class-name="w-6"
				align-text="right">
				<template #default="{row}">
					<div
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(row)">
						<img
							:class="[hasVideo(row) ? 'linked-icon' : 'grayed-icon']"
							src="@/assets/icons/solid/video-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</tw-table-column>
		</tw-table>

		<tw-modal
			:title="selectedOption?.['job__job_option__SERVICES_OPTIONS_serviceOptionID::name_display']"
			:breadcrumb="optionDetailsBreadcrumb"
			ref="optionDetailsModal">
			<OptionDetails 
				:option="selectedOption" />
		</tw-modal>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobOptions' } // name the component
</script>

<script setup lang="ts">	
	import OptionDetails from './modals/OptionDetails.vue' // components

	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'
	
	import { // vue
		computed,
		ref
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const _localesStore = localesStore()

	const {t} = useI18n()

	const optionDetailsBreadcrumb = computed(() => {
		return [
			{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
			{label: props?.job?.fieldData?.name_bride_groom_display, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
			{label: _localesStore.getLabel('175'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }} // options
		]
	})

	const selectedOption = ref({})
	const optionDetailsModal = ref<{open: boolean}>({open: false})


	function openModalDetails(row: any) {
		selectedOption.value = row
		optionDetailsModal.value.open = true
	}
	
	function hasPicture(row: any) {
		return row?.['job__JOBS_OPTIONS__jobID::s3JSONPicture'] || row?.['job__job_option__CONTACTS_OPTIONS__contactOptionsID::s3JSONPicture']
	}

	function hasVideo(row: any) {
		return row?.['job__JOBS_OPTIONS__jobID::s3JSONVideo'] || row?.['job__job_option__CONTACTS_OPTIONS__contactOptionsID::s3JSONVideo']
	}
</script>