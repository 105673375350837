<template>
	<div>
		<tw-tabs-underline
			ref="childTabs"
			:tabs="tabs" />
		<div v-show="childTabs?._tabs?.[0]?.current && userStore.getContactType == 'Staff'">
			<div class="mt-6 ">
				<TransportationDetails 
					:loading="loading"
					:attendee="attendeeRecord" />
			</div>
		</div>
		
		<div v-show="childTabs?._tabs?.[userStore.getContactType == 'Client' ? 0 : 1]?.current">
			<div class="mt-6">
				<DocumentDetails
					:loading="loading"
					:attendee="attendeeRecord" />				
			</div>
		</div>
	</div>
</template>
<script lang="ts">
	export default { name: 'AttendeesDetailsModal' } // name the component
</script>

<script setup lang="ts">
	import { 	// api
		getAttendeeByRecordId
	} from '../queries'
	
	import EventBus from '@/events' // components
	import DocumentDetails from './DocumentDetails.vue'
	import TransportationDetails from './TransportationDetails.vue'

	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { // types
		FileMakerRecord
	} from '@/types'

	import { // vue
		inject,
		onMounted, 
		onBeforeUnmount,
		ref,
		computed
	} from 'vue'

	const props = defineProps({
		attendee: {
			type: Object,
			default: () => {return {}}
		},
		openToTab: {
			type: Number,
			default: 0
		}
	})

	const _localesStore = localesStore()
	const userStore = _userStore()

	const tabs = computed(() => {
		if (userStore.getContactType == 'Staff') {
			return [
				{ name: _localesStore.getLabel('185'), current: false }, // Transportation
				{ name: _localesStore.getLabel('203'), current: false }  // Documents
			]
		}
		else {
			return [
				{ name: _localesStore.getLabel('203'), current: false }  // Documents
			]
		}
	})

	const childTabs = ref<{_tabs: []}>({_tabs: []})
	const loading = ref(false)

	const attendeeRecord = ref<FileMakerRecord>({} as FileMakerRecord)

	onMounted(async () => {
		tabs.value[props.openToTab].current = true

		await getData()

		EventBus.on('change-language', async () => {
			await getData(true)
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('change-language', null)
	})

	async function getData(reset: boolean = false) {
		try { // fetching job by record id
			loading.value = true
					
			const res = await getAttendeeByRecordId(props.attendee.recordId)
			const record = res.response.data[0]
			attendeeRecord.value = record

			fetchTaxDocuments() 
			fetchAttendeeDocuments()
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loading.value = false
		}
	}

	const loadingAttendeeDocuments = ref(true)
	const loadingTaxDocuments = ref(true)
	const s3: any = inject('s3')

	async function fetchTaxDocuments() {
		try { // fetching tax document files from s3
			loadingTaxDocuments.value = true
			attendeeRecord.value?.portalData?.['attendees__DOCUMENTS__attendeesTaxesID'].forEach((document: any) => {
				const documentS3Json = JSON.parse(document?.['attendees__DOCUMENTS__attendeesTaxesID::s3JSONDocument'] || "{}")
				const documentKey = documentS3Json?.s3FilePathShortRaw
				const ext = documentS3Json?.fileExt
				document.ext = ext

				if(documentKey) {
					let params = {
						Bucket: process.env.VUE_APP_S3_BUCKET,
						Key: documentKey
					}
		
					s3.getSignedUrlPromise('getObject', params).then(
						function (url: string) {
							document.signedUrl = url
						}, 
						function (err: Error) { 
							throw err
						}
					)
				}
			})
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loadingTaxDocuments.value = false
		}
	}

	async function fetchAttendeeDocuments() {
		try { // fetching role document files from s3
			loadingAttendeeDocuments.value = true
			attendeeRecord.value?.portalData?.['attendees__DOCUMENTS__attendeesID'].forEach((document: any) => {
				const documentS3Json = JSON.parse(document?.["attendees__DOCUMENTS__attendeesID::s3JSONDocument"] || "{}")
				const documentKey = documentS3Json?.s3FilePathShortRaw

				if(documentKey) {
					let params = {
						Bucket: process.env.VUE_APP_S3_BUCKET,
						Key: documentKey
					}
		
					s3.getSignedUrlPromise('getObject', params).then(
						function (url: string) {
							document.signedUrl = url
						}, 
						function (err: Error) { 
							throw err
						}
					)
				}
			})
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loadingAttendeeDocuments.value = false
		}
	}
</script>