<template>
	<div>
		<div class="flex justify-between items-center pb-3 mb-8 border-b-2 border-teal-400">
			<tw-breadcrumb 
				:crumbs="meetingDetailsBreadcrumb" />
		</div>
		<div v-show="loading">
			<div class="h-6 my-11 rounded-full bg-gray-300 animate-pulse w-1/2" />
		</div>
		<h1
			v-show="!loading"
			class="page-title">
			{{ meeting.fieldData?.Title }}
		</h1>
		<div class="text-sm italic mb-6">
			{{ t('meetings.id') }} {{ meeting.fieldData?._kp_fccalendarevents_id }} - {{ meeting.fieldData?.status_display }}
		</div>
		<div class="w-1/2">
			<collapsable-card 
				:title="t('meetings.meetingDetailsAndLocation')">
				<MeetingDetailsLocation 
					:meeting="meeting" />
			</collapsable-card>
		</div>
		<div class="col-span-2 lg:col-span-1 mt-6">
			<collapsable-card 
				:title="store.getLabel('408')">
				<AttendeesDetails 
					:meeting="meeting"
					:loading="loading"
					:breadcrumb="meetingDetailsBreadcrumb" />
			</collapsable-card>
		</div>
		<div 
			v-if="meeting?.fieldData?.Type_display == 'Sound'"
			class="col-span-2 lg:col-span-1 mt-6">
			<collapsable-card 
				:title="t('meetings.musicDetails')">
				<SoundDetails
					:meeting="meeting" />
			</collapsable-card>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'MeetingDetail' } // name the component
</script>

<script setup lang="ts">
	import { // api
		Auth
	} from '@/api/Auth'

	import { 
		getMeetingByRecordId
	} from './queries'

	import MeetingDetailsLocation from './components/DetailsLocations.vue' // components
	import AttendeesDetails from './components/Attendees.vue'
	import SoundDetails from './components/SoundDetails.vue'

	import EventBus from '../../events'	// events

	import { // language
		useI18n
	} from 'vue-i18n'

	import { // router
		useRoute,
		useRouter
	} from 'vue-router'

	import { // store
		localesStore
	} from '../../store/locales'
	
	import { 
		store as _userStore
	} from '@/store/user'

	import { // types
		FileMakerRecord,
		FindParameters
	} from '@/types'

	import { // vue
		onBeforeUnmount,
		onMounted,
		ref,
		computed
	} from 'vue'	

	const route = useRoute()
	const router = useRouter()

	const store = localesStore()
	const userStore = _userStore()

	const {t} = useI18n()
	
	const loading = ref(false)
	const meeting = ref<FileMakerRecord>({} as FileMakerRecord)

	const meetingDetailsBreadcrumb = computed(() => {
		if(meeting.value.fieldData?.["fc_calendar_sample_event__JOBS__jobID::name_bride_groom_display"]) {
			return [
				{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}},
				{label: meeting.value.fieldData?.["fc_calendar_sample_event__JOBS__jobID::name_bride_groom_display"], to: {name: "JobDetails", params: {recordId: meeting.value.fieldData?.zcnJobRecordId}} },
				{label: store.getLabel('412'), to: {name: 'Jobs'}},
				{label: meeting.value.fieldData?.Title, to: {name: 'MeetingDetails', params: {recordId: meeting.value.recordId}}}
			]
		}
		return [
			{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}},
			{label: meeting.value.fieldData?.Title, to: {name: 'MeetingDetails', params: {recordId: meeting.value.recordId}}}
		]
	})

	onMounted(async () => {
		getData()

		EventBus.on('change-language', async () => {
			await getData(true)
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('change-language', null)
	})

	const query = computed(() => {
		const contactId = userStore.user.contactId
		const isStaff = userStore.getContactType === 'Staff'
		const contactIdField = isStaff ? 'fc_calendar_sample_event__ATTENDEES__calendarID::_kf_contacts_id' : '_kf_contacts_id'

		const querySearch = [
			{
				[contactIdField]: contactId,
				recordId: route.params.recordId
			}
		]

		return querySearch
	})

	async function getData(reset: boolean = false) {
		try {
			loading.value = true

			const contactId = userStore.user.contactId
			const sessionId = userStore.user.sessionId
			const scriptParams = JSON.stringify({
				language: store.getFmLanguage,
				sessionId,
				contactId
			})

			let params: FindParameters = {
				query: query.value,
				limit: 1,
				"script.prerequest": "Set Language",
				"script.prerequest.param": scriptParams
			}
			
			// fetch meeting by record id
			let res = await getMeetingByRecordId(params)
			const record = res.response.data[0]
			meeting.value = record
		}
		catch(e: any) {
			console.error(e)

			// redirect if server session expired 
			if(e?.code == "952") {
				await Auth.signOut()
				router.push({name: "Login"})
			}
		}
		finally {
			loading.value = false
		}
	}
</script>