<template>
	<tw-navbar 
		id="page-heading" />
	<div
		class="flex overflow-hidden bg-gray-50">
		<div class="flex flex-col w-0 flex-1 overflow-hidden">
			<main class="flex-1 relative overflow-y-auto focus:outline-none">
				<div class="py-6 min-h-screen">
					<div class="max-w-screen-2xl mx-auto px-4 sm:px-6 md:px-8 min-h-full">
						<div class="min-h-full">
							<slot />
						</div>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'MainLayout' } // name the component
</script>