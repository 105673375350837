<template>
	<div>
		<h1 class="text-2xl font-medium text-gray-900">
			{{ t('login.title') }}
		</h1>
	</div>

	<form
		class="mt-8"
		@submit.prevent="login">
		<div class="space-y-4">
			<div class="text-sm">
				<tw-input
					v-model="email"
					id="email"
					:required="true"
					type="email"
					:label="t('login.email')"
					:validation="t('login.emailValidation')"
					class="text-md" />
			</div>
			<div class="text-sm">
				<tw-input
					v-model="password"
					id="password"
					:required="true"
					type="password"
					:label="t('login.password')"
					:validation="t('login.passwordValidation')"
					class="text-md">
					<template #trailing_inline>
						<button
							type="button"
							@click="toggleShowPassword" 
							class="border-none bg-transparent hover:bg-transparent ">
							<EyeIcon
								v-if="!showPassword"
								class="h-5" />
							<EyeSlashIcon
								v-else
								class="h-5" />
						</button>
					</template>
				</tw-input>
			</div>

			<div 
				v-show="loginError" 
				class="text-red-500 mt-3">
				<p>{{ t('login.invalidEmailPassword') }}</p>
			</div>
		</div>

		<div class="flex mt-3 text-sm text-brand-600 justify-end">
			<router-link 
				:to="{name: 'ResetPassword'}" 
				class="link">
				{{ t('login.forgotYourPassword') }}
			</router-link>
		</div>

		<div class="mt-4">
			<tw-button 
				type="submit" 
				class="btn-brand font-medium w-full"
				:disabled="loginLoading"
				:loading="loginLoading">
				{{ t('login.login') }}
			</tw-button>
		</div>
	</form>
</template>

<script lang="ts">
	export default { name: 'LoginForm' } // name the component
</script>

<script setup lang="ts">
	// api
	import axios from 'axios'

	import {
		getMultilinguals,
		getValueLists
	} from '../../../queries'

	import { 
		Auth
	} from '@/api/Auth'
	
	// language
	import {
		useI18n
	} from 'vue-i18n'

	// router
	import {
		useRouter,
		useRoute
	} from 'vue-router'

	// store
	import { 
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { 
		store as _valueListStore
	} from '@/store/valueLists'

	// types
	import { 
		LangTypes,
		ValueList
	} from '@/types'

	// vue
	import {
		inject,
		ref
	} from 'vue'

	interface ValueListValue {
		label: string
		value: string
	}

	const userStore = _userStore()
	const valueListStore = _valueListStore()
	const _localesStore = localesStore()

	const router = useRouter()
	const route = useRoute()

	const {t} = useI18n()

	const email = ref("")
	const password = ref("")
	const loginLoading = ref(false)
	const loginError = ref(false)
	const i18n = inject<any>('i18n')

	async function login() {
		loginLoading.value = true

		// check for empty field values
		if (email.value == "") {
			document.getElementById('email')?.focus()
			loginError.value = true
			loginLoading.value = false
			return
		}
		else if (password.value == "") {
			document.getElementById('password')?.focus()
			loginError.value = true
			loginLoading.value = false
			return
		}

		// configure session if not set
		if(localStorage.sessionId == undefined) {
			localStorage.sessionId = Math.random().toString(36).substring(2)
			const sessionId = localStorage.sessionId
			axios.defaults.headers.common['Session-Id'] = sessionId
		}
      
		// attempt login
		let response = await Auth.signIn(email.value, password.value)

		if (!response.authenticated) {
			loginError.value = true
			loginLoading.value = false
			return
		}

		userStore.user = response.user

		// update language
		if(userStore.user.language.includes('Kanji')) {
			i18n.global.locale.value = 'ja'
			_localesStore.locales.selected = 'ja' as LangTypes.ja
		}
		else if(userStore.user.language.includes('Kana')) {
			i18n.global.locale.value = 'kana'
			_localesStore.locales.selected = 'kana' as LangTypes.kana

		}
		else if(userStore.user.language.includes('English')) {
			i18n.global.locale.value = 'en'
			_localesStore.locales.selected = 'en' as LangTypes.en
		}

		try { // getting translations from filemaker	
			let res = await getMultilinguals()
			let vlRes = await getValueLists()

			vlRes.response.valueLists.forEach((list: ValueList) => {
				let lowerName = list.name.toLowerCase()
				let key = lowerName.split("_").reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
				list.values.splice(list.values.findIndex(v => v.value === "-"), 1)
				valueListStore.valueList[key] = list.values.map((option) => {					
					return {
						value: option.value,
						label: option.value
					}		
				})
			})

			_localesStore.locales.en = JSON.parse("{" + res.response.data[0].fieldData.zsJsonEn.replace(/\r/g, ',') + "}")
			_localesStore.locales.ja = JSON.parse("{" + res.response.data[0].fieldData.zsJsonKanji.replace(/\r/g, ',') + "}")
			_localesStore.locales.kana = JSON.parse("{" + res.response.data[0].fieldData.zsJsonKana.replace(/\r/g, ',') + "}")
		}
		catch(e) {
			console.error(e)
		}

		if (response.user?.isPasswordChangeNeeded) {
			loginLoading.value = false
			router.push({name:'UpdatePassword'})
		}
		else {			  
			loginLoading.value = false

			// handle redirects
			router.push(route?.query?.redirect ? String(route?.query?.redirect) : { name: 'Jobs' })
		}
	}
	// #endregion

	const showPassword = ref(false)

	function toggleShowPassword() {
		const passwordField = document.querySelector('#password')

		if(passwordField?.getAttribute('type') === 'password') {
			passwordField.setAttribute('type', 'text')
			showPassword.value = true
		}
		else{
			passwordField?.setAttribute('type', 'password')
			showPassword.value = false
		}
	}
</script>