<template>
	<div 
		class="link">
		<div
			@click="showDetails(previous, 'scnRolesInstructionsList', null, null)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToInstructions") }}</span>
			</div>
		</div>
	</div>
	<div class="my-8">
		<data-display
			:label="_localesStore.getLabel('181')"
			:value="instruction['jobs_scenes_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesRolesID::instructions_display_edit']" />
	</div>
	<div class="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-2 gap-6">
		<div>
			<!-- photo -->
			<data-display
				:label="_localesStore.getLabel('293')">
				<template #custom>
					<div
						v-if="loading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<img
						v-else-if="signedUrlPhoto"
						class="object-cover w-96 h-75 rounded-md"
						:src="signedUrlPhoto">
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</data-display>
		</div>
		<div>
			<!-- video -->
			<data-display
				:label="_localesStore.getLabel('294')">
				<template #custom>
					<div
						v-if="loading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<video
						v-else-if="signedUrlVideo"
						controls
						class="object-cover w-96 h-75 rounded-md">
						<source
							type="video/mp4"
							:src="signedUrlVideo">
					</video>
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/video-fill.svg"
							alt="video icon">
					</div>
				</template>
			</data-display>
		</div>
	</div>
</template>


<script lang="ts">
	export default { name: 'InstructionDetails' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		inject,
		onMounted,
		ref
	} from 'vue'

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		instruction: {
			type: Object,
			default: () => {return {}}
		},
		previous: {
			type: Object,
			default: () => {return {}}
		},
		showDetails: {
			type: Function, 
			default: () => {return}
		}
	})

	const _localesStore = localesStore()

	const {t} = useI18n()

	const loading = ref(true)
	const s3: any = inject('s3')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	onMounted(async () => {
		try { // fetching image and video from s3
			loading.value = true
			const photoS3 = props.instruction?.['jobs_scenes_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesRolesID::s3JSONPictureRole'] || 
				props.instruction?.['jobs_scenes_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::s3JSONPictureRole']
			const photoS3Json = JSON.parse(photoS3 || "{}")
			const photoKey = photoS3Json?.s3FilePathShortRaw
			
			const videoS3 = props.instruction?.['jobs_scenes_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesRolesID::s3JSONVideoRole'] || 
				props.instruction?.['jobs_scenes_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::s3JSONVideoRole']
			const videoS3Json = JSON.parse(videoS3 || "{}")
			const videoKey = videoS3Json?.s3FilePathShortRaw
			
			if(photoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: photoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlPhoto.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
			if(videoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: videoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlVideo.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
		}
		catch(e) {
			console.log(e)
		}
		finally {
			loading.value = false
		}
	})
</script>