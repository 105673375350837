<template>
	<div>
		<div class="flex justify-between">
			<label
				:for="id"
				class="block text-sm font-medium text-gray-700">{{ label }}</label>
			<span
				v-if="hint"
				class="text-sm text-gray-500"
				:id="`hint-${label}`">{{ hint }}</span>
		</div>
		<div class="mt-1 flex relative rounded-md shadow-sm">
			<span
				v-if="$slots.leading_addon"
				class="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
				<span><slot name="leading_addon" /></span>
			</span>
			<div class="flex relative w-full">
				<div class="flex absolute inset-y-0 left-0">
					<div
						v-if="$slots.icon"
						class="pl-3 flex items-center pointer-events-none">
						<span
							class="h-5 w-5 text-gray-400"
							aria-hidden="true"><slot name="icon" /></span>
					</div>
					<div
						v-if="$slots.leading_inline"
						:id="`leading-inline-${label}`"
						class="pl-3 flex items-center pointer-events-none">
						<span class="text-gray-500 sm:text-sm"><slot name="leading_inline" /></span>
					</div>
				</div>
				<input 
					:type="type ?? 'text'" 
					:name="label" 
					:id="id"
					class="flex-1 min-w-0 block focus:ring-brand-500 focus:border-brand-500 w-full sm:text-sm border-gray-300" 
					:class="[{
							'invalid ': !valid,
							'rounded-none rounded-r-md': $slots.leading_addon && !$slots.trailing_addon, 
							'rounded-none rounded-l-md': !$slots.leading_addon && $slots.trailing_addon, 
							'rounded-none': $slots.leading_addon && $slots.trailing_addon, 
							'rounded-md': !$slots.leading_addon && !$slots.trailing_addon
						},
						$slots.leading_inline ? `pl-${leadingInlinePadding}` : '',
						$slots.trailing_inline ? `pr-${trailingInlinePadding}` : '',
					]"
					:placeholder="placeholder"
					@input="onInput"
					:value="modelValue">
				<div class="flex absolute inset-y-0 right-0">
					<div
						v-show="$slots.trailing_inline && valid"
						:id="`trailing-inline-${label}`"
						class="pr-3 flex items-center">
						<span class="text-gray-500 sm:text-sm"><slot name="trailing_inline" /></span>
					</div>
					<div
						v-show="!valid"
						class="pr-3 flex items-center pointer-events-none">
						<ExclamationCircleIcon
							class="h-5 w-5 text-red-500"
							aria-hidden="true" />
					</div>
				</div>
			</div>
			<span
				v-if="$slots.trailing_addon"
				class="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
				<span><slot name="trailing_addon" /></span>
			</span>
		</div>
		<p
			v-show="valid && help || !valid && !validation"
			class="mt-2 text-sm text-gray-500"
			:id="`help-${label}`">
			{{ help }}
		</p>
		<p
			v-show="!valid"
			class="mt-2 text-sm text-red-600">
			{{ validation }}
		</p>
	</div>
</template>

<script setup lang="ts">
	import {
		ref,
		nextTick,
		onMounted,
		useSlots
	} from "vue"

	const props = defineProps({
		modelValue: {
			type: [String, Number],
			default: null
		},
		help: {
			type: String,
			default: ''
		},
		hint: {
			type: String,
			default: ''
		},
		id: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		}, 
		placeholder: {
			type: String,
			default: ''
		},
		required: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'text'
		},
		validation: {
			type: String,
			default: ''
		}
	})

	const emit = defineEmits([
		'update:modelValue'
	])

	const slots = useSlots()

	// padding for inline input elements
	const leadingInlinePadding = ref('none')
	const trailingInlinePadding = ref('none')
	let leadingInlineWidth = 0
	let trailingInlineWidth = 0
	onMounted(() => {
		const leadingEl = document.getElementById(`leading-inline-${props.label}`)
		if(leadingEl) {
			leadingInlineWidth = leadingEl.offsetWidth
			leadingInlinePadding.value = String(Math.ceil(leadingInlineWidth / 4) + 1 + (slots.icon ? 8 : 0))
		}

		const trailingEl = document.getElementById(`trailing-inline-${props.label}`)
		if(trailingEl) {
			trailingInlineWidth = trailingEl.offsetWidth
			trailingInlinePadding.value = String(Math.ceil(trailingInlineWidth / 4) + 1 + (!valid.value ? 8 : 0))
		}
	})

	// update modeled value
	const onInput = async ({ target }: any) => {
		emit('update:modelValue', target.value)
		await nextTick()
		validate()
		return
	}

	// input validation
	const valid = ref(true)


	function validate() {
		if(props.required && !props.modelValue) {
			valid.value = false
		}
		else if(props.required && props.modelValue) {
			valid.value = true
		}
	}

	defineExpose({
		valid
	})
</script>
<style>
    .invalid{
        @apply pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 !important;
    }
</style>