<template>
	<div class="grid grid-cols-1 gap-4 lg:gap-6 px-4">
		<div class="col-span-1">
			<!-- DOB -->
			<data-display				
				:label="localesStore.getLabel('24')"
				:value="contact?.fieldData?.dob || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-1">
			<!-- nationality -->
			<data-display				
				:label="localesStore.getLabel('27')"
				:value="contact?.fieldData?.['contact__contact_info__NATIONALITIES__nationalityID::name_display'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-1">
			<!-- University -->
			<data-display
				:label="localesStore.getLabel('30')"
				:value="contact?.fieldData?.['contact__contact_info__UNIVERSITIES__universityID::name_display'] || '-' " 
				:loading="loading" />
		</div>		
	</div>
</template>

<script lang="ts">
	export default { name: 'PersonalInformation' } // name the component
</script>

<script setup lang="ts">
	import {  // store
		localesStore as _localesStore
	} from '@/store/locales'
	
	const props = defineProps({
		contact: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})
	
	const localesStore = _localesStore()
</script>