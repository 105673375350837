import { 
	defineStore
} from 'pinia'
import { 
	useStorage
} from '@vueuse/core'

interface ValueListValue {
	label: string
	value: string
}

interface ValueListStore {
	[ k: string ]: ValueListValue[]
}

export const store = defineStore('valueList', {
	// a function that returns a fresh state
	state: () => ({
		valueList: useStorage('valueList', {
			serviceTypes: [{label: "", value: ""}],
			meetingTypes: [{label: "", value: ""}],
			meetingTypesClient: [{label: "", value: ""}],
			covidRestrictionsEn: [{label: "", value: ""}],
			covidRestrictionsJa: [{label: "", value: ""}],
			covidRestrictionsKana: [{label: "", value: ""}],
			covidRehearsalRestrictionsEn: [{label: "", value: ""}],
			covidRehearsalRestrictionsJa: [{label: "", value: ""}],
			covidRehearsalRestrictionsKana: [{label: "", value: ""}],
        } as ValueListStore)
	}),
	
	// optional getters
	getters: {

	},

	// optional actions
	actions: {
		// setUserData(state :any, payload :any) {
        //     state.user = payload
		// }
	}
})