<template>
	<div 
		class="link">
		<div 
			@click="showDetails(role, isStaffLoggedIn() ? 4 : 1)"
			class="flex justify-start items-center cursor-pointer">			
			<ArrowLeftIcon
				class="w-5 h-5" />
			<div
				class="truncate inline-block space-x-1 text-sm w-full">
				<span>{{ t("nav.returnToInstructions") }}</span>
			</div>
		</div>
	</div>
	<div class="my-6">
		<data-display
			:label="_localesStore.getLabel('181')"
			:value="instruction?.['jobs_roles__instructions_join_jobs__INSTRUCTIONS_JOIN__instructionsJoinID::instructions_display_edit']" />
	</div>
	<div class="grid grid-cols-3 gap-6">
		<div class="col-span-3 lg:col-span-1">
			<!-- photo -->
			<data-display
				:label="_localesStore.getLabel('293')">
				<template #custom>
					<div
						v-if="filesLoading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<img
						v-else-if="signedUrlPhoto"
						class="object-cover w-96 h-75 rounded-md"
						:src="signedUrlPhoto">
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/image-fill.svg"
							alt="picture icon">
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-3 lg:col-span-1">
			<!-- video -->
			<data-display
				:label="_localesStore.getLabel('294')">
				<template #custom>
					<div
						v-if="filesLoading"
						class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
					<video
						v-else-if="signedUrlVideo"
						controls
						class="object-cover w-96 h-75 rounded-md">
						<source
							type="video/mp4"
							:src="signedUrlVideo">
					</video>
					<div
						v-else
						class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
						<img
							class="grayed-icon h-10 w-10"
							src="@/assets/icons/solid/video-fill.svg"
							alt="video icon">
					</div>
				</template>
			</data-display>
		</div>			
	</div>
</template>

<script lang="ts">
	export default { name: 'InstructionDetails' } // name the component
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as userStore
	} from '@/store/user'

	import { // vue
		inject,
		onMounted,
		ref, 
		computed
	} from 'vue'

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		instruction: {
			type: Object,
			default: () => {return {}}
		},
		showDetails: {
			type: Function, 
			default: () => {return }
		},
		role:{
			type: Object,
			default: () => {return {}}
		}
	})

	const _localesStore = localesStore()
	const _userStore = userStore()

	const {t} = useI18n()

	function isStaffLoggedIn() {
		if(_userStore.getContactType === 'Staff') {
			return props.role?.['job__JOBS_ROLES__liveJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId ||
				props.role?.['job__JOBS_ROLES__instrumentalJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId ||
				props.role?.['job__JOBS_ROLES__standardJobID_gStaffID::_kf_contacts_id'] === _userStore.user.contactId
		}
		else {
			return true
		}
	}

	onMounted(async () => {
		fetchInstructionMedia()
	})

	const filesLoading = ref(true)
	const fetchS3File: any = inject('fetchS3File')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	async function fetchInstructionMedia() {
		try { // fetching instruction image and video from s3
			filesLoading.value = true			
			signedUrlPhoto.value = await fetchS3File(props.scene?.['job__job_scene__CONTACTS_SCENES__contactSceneID::s3JSONPicture'])
			signedUrlVideo.value = await fetchS3File(props.scene?.['job__job_scene__CONTACTS_SCENES__contactSceneID::s3JSONVideo'])
		}
		catch(e) {
			console.error(e)
		}
		finally {
			filesLoading.value = false
		}
	}
</script>