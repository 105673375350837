<template>
	<th 
		v-if="parentName === 'TwTableHeader'"
		colspan="1"
		scope="col"
		class="min-w-full px-4 py-3 text-sm font-semibold text-gray-500 whitespace-nowrap"
		:class="[headerClassName,'text-' + alignText]">
		<a 
			href="#"
			@click="sortCol()" 
			class="group inline-flex items-center">
			{{ header }}
			<span				 
				v-if="sortable"
				:class="[sortOrder ? 'ml-2 flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200' : 'invisible ml-2 flex-none rounded text-gray-400 group-hover:visible group-focus:visible']">
				<ChevronDownIconMini
					class="h-5 w-5 transition-all"
					:class="{'transform rotate-180': sortOrder === 'ASC'}"
					aria-hidden="true" />
			</span>
		</a>
		<slot 
			v-if="$slots.header"
			name="header" />		
	</th>
	<td 
		v-if="parentName === 'TwTableRow'"
		colspan="1"
		class="min-w-full whitespace-nowrap px-4 py-2 text-sm text-gray-900"
		:class="[className, 'text-' + alignText]">
		<slot 
			v-if="$slots.default" 
			:row="row" />
		<template v-else-if="prop"> 
			{{ row[prop] || "—" }} 
		</template>
	</td>
</template>

<script setup>
	import {
		defineProps,
		computed,
		getCurrentInstance,
		ref,
		onMounted,
		onBeforeUnmount
	} from 'vue'
	import EventBus from './event'

	const props = defineProps({
		alignText: {
			type: String,
			default: "left"
		},
		className:{
			type: String,
			default: ""
		},
		header: {
			type: String,
			default: ""
		},
		headerClassName: {
			type: String,
			default: ""
		},
		prop:{
			type: String,
			default: ""
		},
		sortable:{
			type: Boolean,
			default: false
		},
		sortMethod: {
			type: Function,
			default: () => {return}
		}
	})

	onMounted(() => {
		EventBus.on('column-sort-trigger', (payload) => {
			// clear out sorting on other columns of the same table
			if(payload.prop != props.prop && payload.id === parentUid.value) sortOrder.value = null
		})
	})

	onBeforeUnmount(() => {
		EventBus.off('column-sort-trigger', null)
	})

	const parent = computed(() => {
		return getCurrentInstance().parent
	})

	const parentUid = computed(() => {
		return parent.value.uid
	})

	const parentName = computed(() => {
		return parent.value.vnode.type.name
	})

	const row = computed(() => {
		if(parentName.value === 'TwTableRow') {
			return parent.value.vnode.props.row
		}
		return {}
	})
	
	const defaultSort = computed(() => {
		if(parentName.value === 'TwTableHeader') {
			return parent.value.vnode.props['default-sort']
		}
		return {}
	})

	const sortOrder = ref(defaultSort.value.prop === props.prop ? defaultSort.value.order : null)

	function sortCol() {
		if(!props.sortable) return
		EventBus.trigger('column-sort-trigger', {prop: props.prop, id: parentUid.value})
		if(!sortOrder.value) sortOrder.value = "DESC"
		else if(sortOrder.value === "ASC") sortOrder.value = "DESC"
		else if(sortOrder.value === "DESC") sortOrder.value = "ASC"
		props.sortMethod(sortOrder.value)
	}

</script>