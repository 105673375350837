<template>
	<div class="px-4">
		<tw-tabs-underline
			ref="childTabs"
			:tabs="tabs" />
		<div v-show="childTabs?._tabs?.[0]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<tw-table>
					<!-- Genre -->
					<tw-table-column 
						:header="store.getLabel('330')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[1]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<tw-table>
					<!-- Decade -->
					<tw-table-column 
						:header="store.getLabel('518')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[2]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<tw-table>
					<!-- Tags -->
					<tw-table-column 
						:header="store.getLabel('503')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[3]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<!-- Common Uses -->
				<tw-table>
					<!-- Type					 -->
					<tw-table-column 
						:header="store.getLabel('1')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
					<!-- Use -->
					<tw-table-column 
						:header="store.getLabel('280')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
					<!-- Description -->
					<tw-table-column 
						:header="store.getLabel('190')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>			
		</div>
		<div v-show="childTabs?._tabs?.[4]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<tw-table>
					<!-- Artist -->
					<tw-table-column 
						:header="store.getLabel('282')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[5]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<!-- Albums -->
				<tw-table>
					<!-- Album -->
					<tw-table-column 
						:header="store.getLabel('281')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
					<!-- Artist -->
					<tw-table-column 
						:header="store.getLabel('282')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[6]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<!-- Songs -->
				<tw-table>
					<!-- Song -->
					<tw-table-column 
						:header="store.getLabel('177')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
					<!-- Artist -->
					<tw-table-column 
						:header="store.getLabel('282')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[7]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<!-- Borrowed Items -->
				<tw-table>
					<!-- Borrowed Items -->
					<tw-table-column 
						:header="store.getLabel('625')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
					<!-- CD? -->
					<tw-table-column 
						:header="store.getLabel('157') + '?'">
						<template #default="{row}">
							<CheckIconMini
								v-if="row['fc_calendar_sample_event__ATTENDEES__calendarID::meeting_with']"
								class="h-5 w-5 text-black" />
							<MinusIconMini
								v-else
								class="h-5 w-5 text-gray-300" />
						</template>
					</tw-table-column>
					<!-- BGM? -->
					<tw-table-column 
						:header="store.getLabel('158') + '?'">
						<template #default="{row}">
							<CheckIconMini
								v-if="row['fc_calendar_sample_event__ATTENDEES__calendarID::meeting_with']"
								class="h-5 w-5 text-black" />
							<MinusIconMini
								v-else
								class="h-5 w-5 text-gray-300" />
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[8]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<tw-table>
					<!-- BGM -->
					<tw-table-column 
						:header="store.getLabel('158')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
		<div v-show="childTabs?._tabs?.[9]?.current">
			<div class="mt-6 lg:w-1/2 xl:w-1/3">
				<tw-table>
					<!-- Notes -->
					<tw-table-column 
						:header="store.getLabel('205')">
						<template #default="{row}">
							{{ row["fc_calendar_sample_event__attendee__ROLES__roleID::name_display"] }}
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'SoundDetails' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		ref 
	} from 'vue'

	const props = defineProps({
		meeting: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()

	const tabs = ref([
		{ name: store.getLabel('533'), current: true }, // Genres
		{ name: store.getLabel('517'), current: false },  // Decades
		{ name: store.getLabel('504'), current: false }, // Tag
		{ name: store.getLabel('367'), current: false },  // Common Uses
		{ name: store.getLabel('403'), current: false },  // Artists
		{ name: store.getLabel('184'), current: false },  // Albums
		{ name: store.getLabel('182'), current: false },  // Songs
		{ name: store.getLabel('219'), current: false },  // Borrowed Items
		{ name: store.getLabel('165'), current: false },  // BGMs
		{ name: store.getLabel('205'), current: false },  // Notes
	])

	const childTabs = ref<{_tabs: []}>({_tabs: []})
</script>