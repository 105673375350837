<template>
	<div class="grid grid-cols-2 gap-4 divide-y lg:divide-y-0 lg:divide-x px-4">
		<div class="col-span-2 lg:col-span-1">
			<!-- service restrictions -->
			<data-display
				:label="store.getLabel('260')">
				<template #custom>
					<div class="grid grid-cols-2 gap-4 grid-flow-col grid-rows-4">
						<div
							v-for="restriction in covidService"
							:key="restriction.label"
							class="flex justify-start gap-2 items-center"
							:class="{'opacity-30': !restriction.checked}">
							<CheckIconMini
								v-if="restriction.checked"
								class="h-5 w-5" />
							<MinusIconMini
								v-else
								class="h-5 w-5" />
							<span class="font-medium">{{ restriction.label }}</span>
						</div>
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-2 lg:col-span-1 pt-4 lg:pl-4 lg:pt-0">
			<!-- rehearsal restrictions -->
			<data-display
				:label="store.getLabel('261')">
				<template #custom>
					<div class="grid grid-cols-2 gap-4 grid-flow-col grid-rows-4">
						<div
							v-for="restriction in covidRehearsal"
							:key="restriction.label"
							class="flex justify-start gap-2 items-center"
							:class="{'opacity-30': !restriction.checked}">
							<CheckIconMini
								v-if="restriction.checked"
								class="h-5 w-5" />
							<MinusIconMini
								v-else
								class="h-5 w-5" />
							<span class="font-medium">{{ restriction.label }}</span>
						</div>
					</div>
				</template>
			</data-display>
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobCovidRestrictions' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import {
		store as _valueListStore
	} from '@/store/valueLists'
	
	import { // vue
		computed
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()
	const valueListStore = _valueListStore()

	const covidService = computed(() => {
		let vl
		switch(store.locales.selected) {
		case 'en':
			vl = valueListStore.valueList.covidRestrictionsEn
			break
		case 'ja':
			vl = valueListStore.valueList.covidRestrictionsJa
			break
		case 'kana':
			vl = valueListStore.valueList.covidRestrictionsKana
			break
		default:
			vl = valueListStore.valueList.covidRestrictionsEn
		}

		let restrictions = []
		for(let i = 0; i < vl.length ; i++) {
			const value = vl[i].value
			restrictions.push({label: value, checked: false},)
		}
		
		restrictions.forEach(restriction => {
			if(props.job?.fieldData?.covid_service.includes(restriction.label)) {
				restriction.checked = true
			}
		})

		return restrictions
	})

	const covidRehearsal = computed(() => {
		let vl
		switch(store.locales.selected) {
		case 'en':
			vl = valueListStore.valueList.covidRehearsalRestrictionsEn
			break
		case 'ja':
			vl = valueListStore.valueList.covidRehearsalRestrictionsJa
			break
		case 'kana':
			vl = valueListStore.valueList.covidRehearsalRestrictionsKana
			break
		default:
			vl = valueListStore.valueList.covidRehearsalRestrictionsEn
		}

		let restrictions = []

		for(let i = 0; i < vl.length ; i++) {
			const value = vl[i].value
			restrictions.push({label: value, checked: false},)
		}

		restrictions.forEach(restriction => {
			if(props.job?.fieldData?.covid_rehearsal.includes(restriction.label)) {
				restriction.checked = true
			}
		})

		return restrictions
	})

</script>