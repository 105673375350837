<template>
	<DialogDescription
		as="h3"
		class="mb-6">
		<p class="mb-2">
			{{ t('changeEmail.descriptionStub') }}
		</p>
		<p class="font-bold mb-2 text-center">
			{{ userStore?.user?.email }}
		</p> 
		<p>{{ t('changeEmail.description') }}</p>
	</DialogDescription>
	<form @submit.prevent="updateEmail">
		<div class="space-y-4">
			<tw-input
				v-model="currentPassword"
				id="currentPassword"
				ref="inputPassword"
				:required="true"
				type="password"
				:label="t('changeEmail.currentPass')"
				:validation="t('updatePassword.currentPasswordValidation')"
				class="text-md" />

			<tw-input
				v-model="newEmail"
				id="newEmail"
				ref="inputNewEmail"
				:required="true"
				type="email"
				:label="t('changeEmail.newEmail')"
				:validation="emailValidateMessage"
				class="text-md" />

			<tw-input
				v-model="confirmNewEmail"
				id="confirmNewEmail"
				ref="inputConfirmNewEmail"
				:required="true"
				type="email"
				:label="t('changeEmail.confirmEmail')"
				:validation="emailConfirmMessage" />
		</div>
		<div
			v-if="error"
			class="text-red-500 mt-4 -mb-16">
			{{ errorMsg }}
		</div>
		<div class="mt-20">
			<tw-button
				:disabled="formLoading"
				:loading="formLoading"
				type="submit"
				class="btn-base btn-brand w-full"
				@click="updateEmail">
				{{ t('changeEmail.title') }}
			</tw-button>
		</div>
	</form>
</template>

<script lang="ts">
	export default { name: "ChangeEmailForm" } 	// name the component
</script>

<script setup lang="ts">
	import { // auth
		Auth
	} from '@/api/Auth'
	
	import { // components
		DialogDescription
	} from '@headlessui/vue'

	import { // languages
		useI18n
	} from 'vue-i18n'
	
	import { // router
		useRouter
	} from 'vue-router'

	import { // stores
		store as _userStore
	} from '@/store/user'
	
	import { // types
		Notification
	} from '@/types'
	
	import { // vue
		ref
	} from 'vue'

	const userStore = _userStore()
	const router = useRouter()

	const {t} = useI18n()
	
	let email = userStore.user.email
	
	const currentPassword = ref("")
	const newEmail = ref("")
	const confirmNewEmail = ref("")
	const error = ref(false)
	const errorMsg = ref('')
	const formLoading = ref(false)
	const inputPassword = ref<null | {valid: Boolean}>(null)
	const inputNewEmail = ref<null | {valid: Boolean}>(null)
	const inputConfirmNewEmail = ref<null | {valid: Boolean}>(null)
	const emailConfirmMessage = ref(t('changeEmail.newEmailValidation'))
	const emailValidateMessage = ref(t('changeEmail.newEmailValidation'))

	const emit = defineEmits(['closeEmailModal'])

	async function updateEmail() {
		formLoading.value = true
		let formError = false
		
		if (currentPassword.value == "") {
			document.getElementById('currentPassword')?.focus()
			if(inputPassword.value)inputPassword.value.valid = false
			formError = true
		}
		if (newEmail.value == "") {
			document.getElementById('newEmail')?.focus()
			emailValidateMessage.value = t('changeEmail.newEmailValidation')
			if(inputNewEmail.value)inputNewEmail.value.valid = false
			formError = true
		}
		if (newEmail.value == email) {
			document.getElementById('newEmail')?.focus()
			emailValidateMessage.value = t('changeEmail.differentEmailError')
			if(inputNewEmail.value)inputNewEmail.value.valid = false
			formError = true
		}
		if (confirmNewEmail.value == "") {
			document.getElementById('email')?.focus()
			if(inputConfirmNewEmail.value)inputConfirmNewEmail.value.valid = false
			formError = true
		}
		if (newEmail.value != confirmNewEmail.value) {
			document.getElementById('confirmNewEmail')?.focus()
			emailConfirmMessage.value = t('changeEmail.emailMismatch')
			if(inputConfirmNewEmail.value)inputConfirmNewEmail.value.valid = false
			formError = true
		}	
		
		if(formError) {
			formLoading.value = false
			return
		}

		try {
			error.value = false
			errorMsg.value = ''

			let response = await Auth.changeEmail(email, newEmail.value, currentPassword.value)

			if(!response.success) {
				error.value = true
				errorMsg.value = response?.messages?.[0]?.message ?? t('changeEmail.generalError')
				throw errorMsg.value
			}

			const notification: Notification = {
				title: t('placeholders.success'),
				subtitle: t('changeEmail.emailUpdated'),
				icon: 0
			}

			emit('closeEmailModal', notification)
			userStore.user.email = newEmail.value
			email = newEmail.value
			currentPassword.value = ""
			newEmail.value = ""
			confirmNewEmail.value = ""
			router.push({name: 'Account'})
		} 
		catch(e) {
			console.log(e)
		}
		finally {
			formLoading.value = false
		}
	}
</script>