<template>
	<div class="grid grid-cols-4 gap-4 lg:gap-6 px-4">
		<div class="col-span-2 lg:col-span-1">
			<!-- bank name -->
			<data-display
				:label="localesStore.getLabel('79')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::bank_name'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- branch code -->
			<data-display
				:label="localesStore.getLabel('82')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::bank_code'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- account type -->
			<data-display
				:label="localesStore.getLabel('85')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::account_type'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- invoice number -->
			<data-display
				:label="localesStore.getLabel('611')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::invoice_number'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- branch name -->
			<data-display
				:label="localesStore.getLabel('80')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::branch_name'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- account # -->
			<data-display
				:label="localesStore.getLabel('83')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::account_num'] || '-' " 
				:loading="loading" />
		</div>
		<div
			v-if="contactType === 'Staff'"
			class="col-span-2 lg:col-span-1">
			<!-- kigou # -->
			<data-display
				:label="localesStore.getLabel('86')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::kigou_num'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- tax -->
			<data-display
				:label="localesStore.getLabel('753')"
				:value="$filters.percentage(contact?.fieldData?.['contact__CONTACTS_INFO__contactID::tax_percent']) || '-' " 
				:loading="loading" />
		</div>
		<div
			v-if="contactType === 'Staff'"
			class="col-span-2 lg:col-span-1">
			<!-- my number -->
			<data-display
				:label="localesStore.getLabel('29')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::my_number'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- branch # -->
			<data-display
				:label="localesStore.getLabel('81')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::branch_num'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- account name -->
			<data-display
				:label="localesStore.getLabel('84')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::account_name'] || '-' " 
				:loading="loading" />
		</div>
		<div class="col-span-2 lg:col-span-1">
			<!-- tax name -->
			<data-display
				:label="localesStore.getLabel('230')"
				:value="contact?.fieldData?.['contact__CONTACTS_INFO__contactID::tax_name'] || '-' " 
				:loading="loading" />
		</div>		
	</div>
</template>

<script lang="ts">
	export default { name: 'BankInformation' } 	// name the component
</script>

<script setup lang="ts">
	// store
	import { 
		localesStore as _localesStore
	} from '@/store/locales'

	const props = defineProps({
		contact: {
			type: Object,
			default: () => {return {}}
		},
		contactType: {
			type: String,
			default: ""
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const localesStore = _localesStore()
	
</script>