<template>
	<div>
		<div class="mt-4 flex items-center justify-between">
			<div>
				<h1 class="text-xl font-semibold text-gray-900">
					{{ title }}
				</h1>
				<p 
					v-if="description" 
					class="mt-2 text-sm text-gray-700">
					{{ description }}
				</p>			
			</div>
			<TransitionRoot
				v-if="filterable"
				as="template"
				:show="filtersOpen">
				<Dialog
					as="div"
					class="relative z-40 sm:hidden"
					@close="filtersOpen = false">
					<TransitionChild
						as="template"
						enter="transition-opacity ease-linear duration-300"
						enter-from="opacity-0"
						enter-to="opacity-100"
						leave="transition-opacity ease-linear duration-300"
						leave-from="opacity-100"
						leave-to="opacity-0">
						<div class="fixed inset-0 bg-black bg-opacity-25" />
					</TransitionChild>

					<div class="fixed inset-0 z-40 flex">
						<TransitionChild
							as="template"
							enter="transition ease-in-out duration-300 transform"
							enter-from="translate-x-full"
							enter-to="translate-x-0"
							leave="transition ease-in-out duration-300 transform"
							leave-from="translate-x-0"
							leave-to="translate-x-full">
							<DialogPanel class="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
								<div class="flex items-center justify-between px-4">
									<h2 class="text-lg font-medium text-gray-900">
										Filters
									</h2>
									
									<button
										type="button"
										class="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-brand-500"
										@click="filtersOpen = false">
										<span class="sr-only">Close menu</span>
										<XMarkIcon
											class="h-6 w-6"
											aria-hidden="true" />
									</button>
								</div>
								<!-- Filters -->
								<form class="mt-4">
									<Disclosure
										as="div"
										v-for="(filter, filterIndex) in filters"
										:key="filter.label"
										class="border-t border-gray-200 px-4 py-6"
										v-slot="{ open }">
										<h3 class="-mx-2 -my-3 flow-root">
											<DisclosureButton class="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
												<span>
													<span class="font-medium text-gray-900">{{ filter.label }}</span>
													<span
														v-show="numFilterOptionsSelected[filterIndex] > 0"
														class="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
														{{ numFilterOptionsSelected[filterIndex] }}
													</span>	
												</span>
												<span class="ml-6 flex items-center">
													<ChevronDownIconMini
														:class="[open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform transition-all duration-300']"
														aria-hidden="true" />
												</span>
											</DisclosureButton>
										</h3>
										<DisclosurePanel class="space-y-2 divide-y divide-gray-200">
											<div class="mt-4">
												<input
													:id="`filter-all-mobile-${filter.label}`"	
													type="checkbox"
													:checked="filtersIndeterminate[filterIndex] || filterOptionsSelected[filterIndex].length === filter.options.length"
													:indeterminate="filtersIndeterminate[filterIndex]" 
													@change="(e) => selectAll(e, filterIndex)"
													class="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500">
												<label
													:for="`filter-all-mobile-${filter.label}`"												
													class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"> Select All </label>
											</div>
											<div class="space-y-6 pt-4">
												<div
													v-for="(option, optionIdx) in filter.options"
													:key="option.value + '-mobile'"
													class="flex items-center">
													<input
														:id="`filter-mobile-${filter.label}-${optionIdx}`"
														:name="`${filter.label}`"
														:value="option.value"
														v-model="filterOptionsSelected[filterIndex]"
														@change="doFiltering()"
														type="checkbox"
														class="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500">
													<label
														:for="`filter-mobile-${filter.label}-${optionIdx}`"
														class="ml-3 text-sm text-gray-500">{{ option.label }}</label>
												</div>
											</div>
										</DisclosurePanel>
									</Disclosure>
								</form>
							</DialogPanel>
						</TransitionChild>
					</div>
				</Dialog>
			</TransitionRoot>
			<section
				v-if="filterable"
				aria-labelledby="filter-heading">
				<h2
					id="filter-heading"
					class="sr-only">
					Filters
				</h2>

				<div class="flex items-center justify-between">					
					<button
						type="button"
						class="flex items-center group text-sm font-medium text-gray-700 sm:hidden"
						@click="filtersOpen = true">
						<FunnelIconMini 
							class="inline h-5 w-5 mr-1 text-gray-400 group-hover:text-gray-500" 
							aria-hidden="true" />
						{{ filtersApplied }}
					</button>
					
					<PopoverGroup class="hidden sm:flex sm:items-baseline sm:space-x-8">
						<div class="flext items-center justify-center text-sm font-medium text-gray-700">
							<FunnelIconMini 
								class="inline h-5 w-5 mr-1 text-gray-400" 
								aria-hidden="true" />
							{{ filtersApplied }}
						</div>
						<Popover
							as="div"
							v-for="(filter, filterIndex) in filters"
							:key="filter.label"
							:id="`desktop-menu-${filterIndex}`"
							class="relative inline-block text-left">							
							<div>
								<PopoverButton class="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
									<span>{{ filter.label }}</span>
									<span
										v-show="numFilterOptionsSelected[filterIndex] > 0"
										class="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
										{{ numFilterOptionsSelected[filterIndex] }}
									</span>				
									<ChevronDownIconMini
										class="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
										aria-hidden="true" />
								</PopoverButton>
							</div>

							<transition
								enter-active-class="transition ease-out duration-100"
								enter-from-class="transform opacity-0 scale-95"
								enter-to-class="transform opacity-100 scale-100"
								leave-active-class="transition ease-in duration-75"
								leave-from-class="transform opacity-100 scale-100"
								leave-to-class="transform opacity-0 scale-95">
								<PopoverPanel class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white py-3 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
									<form class="space-y-2 divide-y divide-gray-200">
										<div class="px-3 w-40">
											<input
												:id="`filter-all-${filter.label}`"	
												type="checkbox"
												:checked="filtersIndeterminate[filterIndex] || filterOptionsSelected[filterIndex].length === filter.options.length"
												:indeterminate="filtersIndeterminate[filterIndex]" 
												@change="(e) => selectAll(e, filterIndex)"
												class="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500">
											<label		
												:for="`filter-all-${filter.label}`"										
												class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"> Select All </label>
										</div>
										<div class="space-y-2 pt-3 px-3">
											<div
												v-for="(option, optionIdx) in filter.options"
												:key="option.value"
												class="flex items-center">
												<input
													:id="`filter-${filter.label}-${optionIdx}`"
													:name="`${filter.label}[]`"
													:value="option.value"
													v-model="filterOptionsSelected[filterIndex]"
													@change="doFiltering()"
													type="checkbox"
													class="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500">
												<label
													:for="`filter-${filter.label}-${optionIdx}`"
													class="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900">{{ option.label }}</label>
											</div>
										</div>
									</form>
								</PopoverPanel>
							</transition>
						</Popover>
					</PopoverGroup>
				</div>
			</section>
		</div>
		<div 
			v-if="searchable" 
			class="w-full">					
			<div class="relative mt-3 flex items-center">
				<div class="absolute items-center left-0 pl-1.5">
					<MagnifyingGlassIcon 
						class="h-5 w-5 text-gray-400" 
						aria-hidden="true" />
				</div>
				<label 
					for="search" 
					class="sr-only">
					Search
				</label>
				<input 
					type="text" 
					name="search" 
					id="search"
					v-model="searchInput"
					:placeholder="searchPlaceholder"
					@change="searchMethod(searchInput)"
					class="block rounded-md border-gray-300 pl-8 pr-12 shadow-sm focus:border-brand-500 focus:ring-brand-500 sm:text-sm w-full">					
			</div>
		</div>	
		<div class="mt-4 flex flex-col">
			<div class="">
				<div class="inline-block min-w-full align-middle">
					<div class="overflow-x-auto rounded-lg">
						<table class="min-w-full divide-y divide-gray-300 table-fixed">
							<thead class="bg-white border-b-2 border-gray-300">
								<tr>
									<th 	
										v-if="checkable" 
										scope="col" 
										class="relative items-center sm:w-16 sm:px-3">
										<input 
											type="checkbox" 
											class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-brand-500 focus:ring-brand-500 sm:left-6"
											:checked="indeterminate || selectedRows.length === data.length"
											:indeterminate="indeterminate"
											@change="selectedRows == $event.target.checked ? data.map((r:any) => r.id) : []">
										<TwTableDropdown
											v-if="selectedRows.length > 0"
											class="py-3 -mr-12 ml-4 pr-4" 
											:items="multiRowActions" 
											position="left"
											:data="data"
											:selected-table-rows="selectedRows">
											<template #icon>
												<ChevronDownIconMini />
											</template>										
										</TwTableDropdown>																												
									</th>
									<TwTableHeader :default-sort="defaultSort">
										<slot />
									</TwTableHeader>
								</tr>
							</thead>
							<tbody 
								v-if="data.length > 0"
								class="divide-y divide-gray-200 bg-white">								
								<tr
									v-for="row in data" 
									:key="row">
									<th
										v-if="row.subheading"
										colspan="100%"
										scope="colgroup" 
										class="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
										{{ row.subheading }}
									</th>
									<template v-else>
										<td 
											v-if="checkable" 
											class="relative w-12 px-6 sm:w-16 sm:px-8">
											<div 
												v-if="selectedRows.includes(row.id)" 
												class="absolute inset-y-0 left-0 w-0.5 bg-brand-500" />
											<input 
												type="checkbox"
												class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-brand-500 focus:ring-brand-500 sm:left-6"
												:value="row.id"
												v-model="selectedRows">
										</td>
										<TwTableRow :row="row">
											<slot />
										</TwTableRow>
									</template>
								</tr>
								<tr v-show="loading">
									<td 
										class="text-center w-full px-4" 
										colspan="100%">
										<tw-loading />
									</td>
								</tr>
							</tbody>
							<tbody 
								v-else
								class="divide-y divide-gray-200 bg-white">	
								<tr v-show="!loading">									
									<td 
										colspan="100%" 
										class="text-center whitespace-nowrap px-3 py-2 text-sm text-gray-900">
										{{ placeholder }}
									</td>
								</tr>
								<tr v-show="loading">
									<td 
										class="text-center w-full p-4" 
										colspan="100%">
										<tw-loading />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
// name the component
	export default { name: 'TwTable' }
</script>


<script setup lang="ts">
	import TwTableRow from '@/components/Tables/TwTableRow.vue'
	import TwTableHeader from '@/components/Tables/TwTableHeader.vue'
	import TwTableDropdown from '@/components/Tables/TwTableDropdown.vue'
	import {
		defineProps,
		ref,
		computed
	} from 'vue'
	
	import {
		Dialog,
		DialogPanel,
		Disclosure,
		DisclosureButton,
		DisclosurePanel,
		Popover,
		PopoverButton,
		PopoverGroup,
		PopoverPanel,
		TransitionChild,
		TransitionRoot
	} from '@headlessui/vue'

	const props = defineProps({
		checkable: {
			type: Boolean,
			default: false
		},
		data: {
			type: Array,
			default: () => {return []}
		},
		defaultSort: {
			type: Object,
			default: () => {return {prop: null, order: 'ASC'}}
		},
		description: {
			type: String,
			default: ""
		},
		filterable:{
			type: Boolean,
			default: false
		},
		filters:{
			type: Array[{label: String, options: Array}],
			default: () => {return []}
		},
		filterFunction:{
			type: Function,
			default: () => {return}
		},
		loading: {
			type: Boolean,
			default: false
		},
		multiRowActions: {
			type: Array, 
			default: () => {return []}
		},
		placeholder: {
			type: String,
			default: "No Data"
		},
		rowActions: {
			type: Array,
			default: () => {return []}
		},
		searchable:{
			type: Boolean,
			default: false
		},
		searchMethod:{
			type: Function,
			default: () => {return}
		},
		searchPlaceholder: {
			type: String,
			default: "Search..."
		},
		title: {
			type: String,
			default: ""
		}
	})

	const selectedRows = ref([])
	const indeterminate = computed(() => selectedRows.value.length > 0 && selectedRows.value.length < props.data.length)
	const searchInput = ref('')


	// #region filters
	const filtersOpen = ref(true)

	/**
	 * The value of options selected for each filter
	 * @yields {array}
	 */
	const filterOptionsSelected = ref(props.filters.map(() => {return []}))

	/**
	 * The number of options selected for each filter
	 * @yields {array}
	 */
	const numFilterOptionsSelected = computed(() => {
		return filterOptionsSelected.value.map((options: []) => {
			return options.length
		})
	})

	/**
	 * If the selected options for each filter is mixed checked/unchecked
	 * @yields {array}
	 */
	const filtersIndeterminate = computed(() => {
		return filterOptionsSelected.value.map((options: [], i: number) => {
			return options.length > 0 && options.length < props.filters[i].options.length
		})
	})

	/**
	 * Generates text to indicate how many filters are applied
	 * @yields {String}
	 */
	const filtersApplied = computed(() => {
		let numFiltersApplied = filterOptionsSelected.value.filter((options: []) => {
			return options.length > 0
		}).length

		return `${numFiltersApplied || 'No'} Filter${numFiltersApplied !== 1 ? 's' : ''} Applied`
	})

	/**
	 * Runs filter function specified in the fitlers prop after selecting/deselecting all
	 * @function selectAll
	 * @param {Object} event - The event being fired
	 * @param {Number} filterIndex - The index of the filter being selected
	 */
	function selectAll(event: any, filterIndex: number) {
		filterOptionsSelected.value[filterIndex] = event.target.checked ? props.filters[filterIndex].options.map((o: {value: string,label: string}) => o.value) : []
		doFiltering()
	}

	/**
	 * Runs filter function specified in the fitlers prop. Function parameter in parent component is a 2d array of selected options
	 * @function doFiltering
	 */
	function doFiltering() {
		props.filterFunction(filterOptionsSelected.value)
	}
	// #endregion
</script>