<template>
	<div 
		class="grid grid-cols-4 gap-4 px-4">
		<div class="col-span-1 lg:col-span-1">
			<!-- main train station -->
			<data-display
				:loading="loading"
				:label="store.getLabel('662')"
				:value="job?.fieldData?.['job__CONTACTS__locationID::train_station_name_display'] || '—'" />

			<!-- picture -->			
			<div
				:class="[job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPictureTrain'] ? 'linked-icon' : 'grayed-icon']"
				class="h-6 w-6 cursor-pointer"
				@click="openModalDetails(job?.fieldData?.['job__CONTACTS__locationID::train_station_name_display'],
					job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPictureTrain'], 
					job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONVideoTrain'])">
				<img
					src="@/assets/icons/solid/image-fill.svg"
					alt="list icon">
			</div>
		</div>
		<div class="col-span-1">
			<!-- main walking time -->
			<data-display
				:loading="loading"
				:label="store.getLabel('381')"
				:value="job?.fieldData?.['job__CONTACTS__locationID::train_station_walk'] || '—'" />
		</div>
		<div class="col-span-1">
			<data-display>
				<template #custom>
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !job?.fieldData?.['job__CONTACTS__locationID::train_station_taxi']}">
						<CheckIconMini
							v-if="job?.fieldData?.['job__CONTACTS__locationID::train_station_taxi']"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<!-- main taxi -->
						<span class="font-medium">{{ store.getLabel('380') }}</span>
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-1">
			<data-display>
				<template #custom>
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !job?.fieldData?.['job__CONTACTS__locationID::pick_up_display']}">
						<CheckIconMini
							v-if="job?.fieldData?.['job__CONTACTS__locationID::pick_up_display']"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<!-- main pick up -->
						<span class="font-medium">{{ store.getLabel('22') }}</span>
					</div>
					<div
						:class="[job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPicturePickup'] ? 'linked-icon' : 'grayed-icon ']"
						class="h-6 w-6 cursor-pointer"
						@click="openModalDetails(store.getLabel('22'),
							job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPicturePickup'],
							job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONVideoPickup'])">
						<img
							src="@/assets/icons/solid/image-fill.svg"
							alt="list icon">
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-1 lg:col-span-1">
			<!-- other train station -->
			<data-display
				:loading="loading"
				:label="store.getLabel('661')"
				:value="job?.fieldData?.['job__CONTACTS__locationID::train_station_name_other_display'] || '—'" />
			<div
				:class="[job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPictureTrainOther'] ? 'linked-icon' : 'grayed-icon ']"
				class="h-6 w-6 cursor-pointer"
				@click="openModalDetails(job?.fieldData?.['job__CONTACTS__locationID::train_station_name_other_display'],
					job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPictureTrainOther'],
					job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONVideoTrainOther'])">
				<img
					src="@/assets/icons/solid/image-fill.svg"
					alt="list icon">
			</div>
		</div>
		<div class="col-span-1 lg:col-span-1">
			<!-- walking time -->
			<data-display
				:loading="loading"
				:label="store.getLabel('381')"
				:value="job?.fieldData?.['job__CONTACTS__locationID::train_station_walk_other'] || '—'" />
		</div>
		<div class="col-span-2">
			<data-display>
				<template #custom>
					<div
						class="flex justify-start gap-2 items-center"
						:class="{'opacity-30': !job?.fieldData?.['job__CONTACTS__locationID::train_station_taxi_other']}">
						<CheckIconMini
							v-if="job?.fieldData?.['job__CONTACTS__locationID::train_station_taxi_other']"
							class="h-5 w-5" />
						<MinusIconMini
							v-else
							class="h-5 w-5" />
						<!-- taxi -->
						<span class="font-medium">{{ store.getLabel('380') }}</span>
					</div>
				</template>
			</data-display>
		</div>
		<div class="col-span-1">
			<!-- bus stop -->
			<data-display
				:loading="loading"
				:label="store.getLabel('660')"
				:value="job?.fieldData?.['job__CONTACTS__locationID::bus_stop_name_display'] || '—'" />
			<div
				:class="[job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPictureBus'] ? 'linked-icon' : 'grayed-icon ']"
				class="h-6 w-6 cursor-pointer"
				@click="openModalDetails(job?.fieldData?.['job__CONTACTS__locationID::bus_stop_name_display'],
					job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONPictureBus'],
					job?.fieldData?.['job__contacts__CONTACTS_INFO__contactsID::s3JSONVideoBus'])">
				<img
					src="@/assets/icons/solid/image-fill.svg"
					alt="list icon">
			</div>
		</div>
		<div class="col-span-1 lg:col-span-1">
			<!-- walking time -->
			<data-display
				:loading="loading"
				:label="store.getLabel('381')"
				:value="job?.fieldData?.['job__CONTACTS__locationID::bus_stop_walk'] || '—'" />
		</div>
	</div>
	<tw-modal
		:title="modalTitle"
		:breadcrumb="modalBreadcrumb"
		ref="transportationDetailsModal">
		<div class="grid grid-cols-3 gap-6">
			<div class="col-span-3 lg:col-span-1">
				<!-- photo -->
				<data-display
					:label="store.getLabel('293')">
					<template #custom>
						<div
							v-if="mediaLoading"
							class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
						<img
							v-else-if="signedUrlPhoto"
							class="object-cover w-96 h-75 rounded-md"
							:src="signedUrlPhoto">
						<div
							v-else
							class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
							<img
								class="grayed-icon h-10 w-10"
								src="@/assets/icons/solid/image-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</data-display>
			</div>
			<div class="col-span-3 lg:col-span-1">
				<!-- video -->
				<data-display
					:label="store.getLabel('294')">
					<template #custom>
						<div
							v-if="mediaLoading"
							class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
						<video
							v-else-if="signedUrlVideo"
							controls
							class="object-cover w-96 h-75 rounded-md">
							<source
								type="video/mp4"
								:src="signedUrlVideo">
						</video>
						<div
							v-else
							class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
							<img
								class="grayed-icon h-10 w-10"
								src="@/assets/icons/solid/video-fill.svg"
								alt="video icon">
						</div>
					</template>
				</data-display>
			</div>			
		</div>
	</tw-modal>
</template>

<script lang="ts">
	export default { name: 'TransportationDetails' } // name the component
</script>

<script setup lang="ts">
	import { // language
		useI18n
	} from 'vue-i18n'
	
	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { // vue
		inject,
		ref
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const {t} = useI18n()

	const store = localesStore()

	const selectedOption = ref({})
	const transportationDetailsModal = ref<{open: boolean}>({open: false})

	const modalBreadcrumb = [
		{label: t('nav.jobsMeetings'), to: {name: 'Jobs'}}, // Jobs & Meetings
		{label: props?.job?.fieldData?.name_bride_groom_display, to: {name: 'JobDetails', params: {recordId: props?.job?.recordId} }},
		{label: store.getLabel('185'), to: {name: 'JobDetails', params: {recordId: props?.job?.recordId}, hash: '#transportation' }}, // scenes
	]

	const modalTitle = ref()
	const mediaLoading = ref(false)
	const fetchS3File: any = inject('fetchS3File')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')

	async function openModalDetails(title: string, pictureS3Json: string, videoS3Json: string) {
		modalTitle.value = title
		transportationDetailsModal.value.open = true
		try {
			mediaLoading.value = true
			signedUrlPhoto.value = await fetchS3File(pictureS3Json)
			signedUrlVideo.value = await fetchS3File(videoS3Json)
		}
		catch(e) {
			console.error(e)
		}
		finally {
			mediaLoading.value = false
		}
	}

	function hasPicture(row: any) {
		return row?.['job__JOBS_OPTIONS__jobID::picture']
	}
</script>