import {
	createRouter,
	createWebHistory
} from 'vue-router'
import {
	Route
} from '@/types' // must be imported since file contains an export statement
import { 
	store as _userStore
} from '@/store/user'


// Import all of the resource routes files
function loadRoutes() {
	const context = require.context('@/modules', true, /routes.ts$/i)
	return context.keys()
		.map(context) // import module
		.map((m: any) => m.route) // get `default` export from each resolved module
}

// Define the routes
const routes: Route[] = []
const resourceRoutes = loadRoutes()

resourceRoutes.forEach((moduleRoutes) => {
	moduleRoutes.forEach((route: Route) => {
		routes.push(route)
	})
})

// Create the router instance and pass the `routes` option
export const router = createRouter({
	history: createWebHistory(), // use history mode
	routes, // short for `routes: routes`
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	}
})

// Redirect user if they are not authorized to view pages
router.beforeEach(async (to, from, next) => {
    const userStore = _userStore()
    if(to.name !== 'Login' && 
	to.name !== 'ResetPassword' && 
	to.name !== 'ResetPasswordSuccess' &&
	to.name !== 'UpdatePassword' &&
	!userStore?.user.loggedIn) {
        next({name: 'Login', query: {redirect: to.fullPath}})
    }
    else next()
})
