<template>
	<div 
		class="card hover:bg-gray-50 cursor-pointer p-4">
		<router-link
			:to="{name: 'MeetingDetails', params: {recordId: props.meeting.recordId}}">
			<div class="grid grid-cols-12 gap-2 ">
				<div class="col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-3">
					<div class="flex justify-start items-center gap-2">
						<img
							:class="['h-6 w-6']"
							src="@/assets/icons/solid/meeting-fill.svg"
							alt="meetings icon">
						<h2
							:title="props.meeting.Title || props.meeting['job__FC_CALENDAR_EVENT__jobID::Title']"
							class="text-lg truncate">
							{{ props.meeting.Title || props.meeting["job__FC_CALENDAR_EVENT__jobID::Title"] }}
						</h2>
					</div>
					<div class="flex">
						<p class="font-bold text-sm">
							{{ props.meeting.StartDate || props.meeting["job__FC_CALENDAR_EVENT__jobID::StartDate"] }} &#x2022;
						</p>
						<p class="text-sm italic ml-1">
							{{ props.meeting.Type_display || props.meeting["job__FC_CALENDAR_EVENT__jobID::Type_display"] }} <span class="font-bold not-italic">&#x2022;</span>
						</p>
						<p class="text-sm italic ml-1">
							ID: {{ props.meeting.Id || props.meeting["job__FC_CALENDAR_EVENT__jobID::_kp_fccalendarevents_id"] }}
						</p>
					</div>
				</div>
				<!-- <div class="hidden xl:block col-span-0 xl:col-span-1" />
				<div class="hidden xl:block col-span-0 xl:col-span-1" />
				<div class="hidden xl:block col-span-0 xl:col-span-1" /> -->
				<div class="col-span-3 md:col-span-2 lg:col-span-2 xl:col-span-1">
					<!-- start time -->
					<data-display
						:label="store.getLabel('144')"
						:value="$filters.convertTime(startTime) || '—'" />
				</div>
				<div class="col-span-4 md:col-span-3 lg:col-span-2 xl:col-span-2">
					<!-- end time -->
					<data-display
						:label="store.getLabel('322')"
						:value="$filters.convertTime(endTime) || '—'" />
				</div>
			</div>
		</router-link>
	</div>
</template>

<script lang="ts">
	export default { name: 'MeetingCard' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import { // vue
		computed
	} from 'vue'

	const props = defineProps({ 
		meeting: {
			type: Object,
			default: () => {return {}}
		}
	})

	const store = localesStore()

	const startTime = computed(() => {
		return props.meeting.StartTime || props.meeting["job__FC_CALENDAR_EVENT__jobID::StartTime"]
	})

	const endTime = computed(() => {
		return props.meeting.EndTime || props.meeting["job__FC_CALENDAR_EVENT__jobID::EndTime"]
	})
</script>