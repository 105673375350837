<template>
	<div>
		<div class="mb-6">
			<tw-tabs-underline
				ref="childTabs"
				:tabs="tabs" />
		</div>

		<!-- scene details -->
		<div v-show="childTabs?._tabs?.[0]?.current">
			<div class="grid grid-cols-3 gap-6">
				<div class="col-span-3 lg:col-span-1">
					<!-- photo -->
					<data-display
						:label="_localesStore.getLabel('293')">
						<template #custom>
							<div
								v-if="filesLoading"
								class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
							<img
								v-else-if="signedUrlPhoto"
								class="object-cover w-96 h-75 rounded-md"
								:src="signedUrlPhoto">
							<div
								v-else
								class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
								<img
									class="grayed-icon h-10 w-10"
									src="@/assets/icons/solid/image-fill.svg"
									alt="picture icon">
							</div>
						</template>
					</data-display>
				</div>
				<div class="col-span-3 lg:col-span-1">
					<!-- video -->
					<data-display
						:label="_localesStore.getLabel('294')">
						<template #custom>
							<div
								v-if="filesLoading"
								class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
							<video
								v-else-if="signedUrlVideo"
								controls
								class="object-cover w-96 h-75 rounded-md">
								<source
									type="video/mp4"
									:src="signedUrlVideo">
							</video>
							<div
								v-else
								class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
								<img
									class="grayed-icon h-10 w-10"
									src="@/assets/icons/solid/video-fill.svg"
									alt="video icon">
							</div>
						</template>
					</data-display>
				</div>
				<div class="col-span-3 lg:col-span-1">
					<div class="grid grid-cols-1">
						<div>				
							<!-- notes -->
							<data-display
								:label="_localesStore.getLabel('205')"
								:value="_userStore.getContactType === 'Staff' ? scene?.['job__JOBS_SCENES__jobID::notes_staff_display'] : scene?.['job__JOBS_SCENES__jobID::notes_client_display'] || '—'" />
						</div>
						<div 
							v-if="userStore.getContactType === 'Staff'"
							class="col-span-1 lg:col-span-1 mt-10">
							<!-- description -->
							<data-display
								:label="_localesStore.getLabel('190')"
								:value="scene?.['job__JOBS_SCENES__jobID::description_display'] || '—'" />
						</div>
					</div>	
				</div>				
			</div>
			<div class="grid grid-cols-3 gap-6 grid-flow-rows mt-6">
				<div class="text-gray-800 col-span-3 lg:col-span-1">
					Scene Details
				</div>
				<div class="text-gray-800 col-span-3 lg:col-span-2">
					{{ _localesStore.getLabel('676') }}
				</div>
				<div class="col-span-3 lg:col-span-1 grid grid-cols-3 gap-4">
					<!-- main scene -->
					<div class="col-span-1">
						<data-display
							:label="_localesStore.getLabel('388')">
							<template #custom>
								<CheckIconMini
									v-if="scene?.['job__JOBS_SCENES__jobID::main_scene']"
									class="h-5 w-5 text-black" />
								<MinusIconMini
									v-else
									class="h-5 w-5 text-gray-300" />
							</template>
						</data-display>
					</div>
					<!-- standard -->
					<div class="col-span-1">
						<data-display
							:label="_localesStore.getLabel('411')">
							<template #custom>
								<CheckIconMini
									v-if="scene?.['job__JOBS_SCENES__jobID::standard']"
									class="h-5 w-5 text-black" />
								<MinusIconMini
									v-else
									class="h-5 w-5 text-gray-300" />
							</template>
						</data-display>
					</div>
					<!-- placement -->
					<div class="col-span-1">
						<data-display
							:label="_localesStore.getLabel('192')"
							:value="scene?.['job__JOBS_SCENES__jobID::placement_display'] || '—'" />
					</div>
					<div class="col-span-1">
						<!-- song/bgm/live -->
						<data-display
							:label="`${_localesStore.getLabel('177')}/${_localesStore.getLabel('158')}/${_localesStore.getLabel('520')}`"
							:value="scene?.['job__JOBS_SCENES__jobID::sound_choice_display_first'] || '—'" />
					</div>
					<div class="col-span-1">
						<!-- spotlight -->
						<data-display
							:label="_localesStore.getLabel('389')">
							<template #default="{row}">
								<CheckIconMini
									v-if="row['job__JOBS_SCENES__jobID::spotlight']"
									class="h-5 w-5 text-black" />
								<MinusIconMini
									v-else
									class="h-5 w-5 text-gray-300" />
							</template>
						</data-display>
					</div>
					<div class="col-span-1">
						<!-- lighting -->
						<data-display
							:label="_localesStore.getLabel('541')"
							:value="scene?.['job__JOBS_SCENES__jobID::lighting'] || '—'" />
					</div>
				</div>
				<div class="col-span-3 lg:col-span-1 grid grid-cols-3 gap-4">
					<div class="col-span-1">
						<!-- type -->
						<data-display
							:label="_localesStore.getLabel('1')"
							:value="scene?.['job__JOBS_SCENES__jobID::time_type_display'] || '—'" />
					</div>
					<div class="col-span-1">
						<!-- relationship -->
						<data-display
							:label="_localesStore.getLabel('681')"
							:value="scene?.['job__JOBS_SCENES__jobID::time_relation_display'] || '—'" />
					</div>
					<div class="col-span-1">
						<!-- amount -->
						<data-display
							:label="_localesStore.getLabel('376')"
							:value="scene?.['job__JOBS_SCENES__jobID::time_amount'] || '—'" />
					</div>
					<div class="col-span-1">
						<!-- start time -->
						<data-display
							:label="_localesStore.getLabel('144')"
							:value="$filters.hour24Time(scene?.['job__JOBS_SCENES__jobID::time_start']) || '—'" />
					</div>
					<div class="col-span-1">
						<!-- end time -->
						<data-display
							:label="_localesStore.getLabel('322')"
							:value="$filters.hour24Time(scene?.['job__JOBS_SCENES__jobID::time_end']) || '—'" />
					</div>
				</div>
			</div>
		</div>

		<!-- particpants -->
		<div v-show="childTabs?._tabs?.[1]?.current">
			<tw-table
				:placeholder="t('placeholders.noParticipants')"
				:data="participants"
				:loading="loading">
				<!-- type -->
				<tw-table-column 
					:header="_localesStore.getLabel('1')"
					prop="jobs_scenes__JOBS_PARTICIPANTS__jobsSceneID::type_display" />

				<!-- unknown -->
				<tw-table-column
					v-if="userStore.getContactType == 'Staff'" 
					:header="_localesStore.getLabel('386')">
					<template #default="{row}">
						<CheckIconMini
							v-if="row?.['jobs_scenes__JOBS_PARTICIPANTS__jobsSceneID::unknown']"
							class="h-5 w-5 text-black" />
						<MinusIconMini
							v-else
							class="h-5 w-5 text-gray-300" />
					</template>
				</tw-table-column>

				<!-- participant -->
				<tw-table-column 
					:header="_localesStore.getLabel('225')"
					prop="jobs_scenes__jobs_participants_PARTICIPANTS__participantID::name_display" />

				<!-- rehearsal -->
				<tw-table-column 
					:header="_localesStore.getLabel('387')">
					<template #default="{row}">
						<CheckIconMini
							v-if="row?.['jobs_scenes__JOBS_PARTICIPANTS__jobsSceneID::rehearsal']"
							class="h-5 w-5 text-black" />
						<MinusIconMini
							v-else
							class="h-5 w-5 text-gray-300" />
					</template>
				</tw-table-column>
				<!-- role -->
				<tw-table-column
					v-if="userStore.getContactType == 'Staff'" 
					:header="_localesStore.getLabel('102')"
					prop="jobs_scenes__jobs_participants_ROLES__roleID::name_display" />
			</tw-table>
		</div>

		<!-- variations -->
		<div v-show="childTabs?._tabs?.[2]?.current">
			<tw-table
				:placeholder="t('placeholders.noVariations')"
				:data="variations"
				:loading="loading">
				<!-- variation -->
				<tw-table-column 
					:header="_localesStore.getLabel('395')" 
					prop="jobs_scenes__jobs_variations__VARIATIONS__variationID::name_display" />
				<!-- standard -->
				<tw-table-column 
					:header="_localesStore.getLabel('411')">
					<template #default="{row}">
						<CheckIconMini
							v-if="row['jobs_scenes__JOBS_VARIATIONS__jobsSceneID::standard']"
							class="h-5 w-5 text-black" />
						<MinusIconMini
							v-else
							class="h-5 w-5 text-gray-300" />
					</template>
				</tw-table-column>
				<tw-table-column
					v-if="userStore.getContactType == 'Staff'"
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div
							class="h-6 w-6 cursor-pointer"
							@click="showDetails(row, 'varRoleDetails', 1)">
							<img
								:class="[hasRoles(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/roles-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</tw-table-column>
			</tw-table>
		</div>

		<!-- options -->
		<div v-show="childTabs?._tabs?.[3]?.current">
			<tw-table
				:placeholder="t('placeholders.noOptions')"
				:data="options"
				:loading="loading">
				<!-- option -->
				<tw-table-column 
					:header="_localesStore.getLabel('329')" 
					prop="jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::name_display" />

				<!-- standard -->
				<tw-table-column 
					:header="_localesStore.getLabel('411')">
					<template #default="{row}">
						<CheckIconMini
							v-if="row['jobs_scenes__JOBS_SCENES_OPTIONS__jobsSceneID::standard']"
							class="h-5 w-5 text-black" />
						<MinusIconMini
							v-else
							class="h-5 w-5 text-gray-300" />
					</template>
				</tw-table-column>

				<!-- category -->
				<tw-table-column 
					:header="_localesStore.getLabel('11')"
					prop="jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::category_display" />

				<!-- description -->
				<tw-table-column
					v-if="userStore.getContactType == 'Staff'" 
					:header="_localesStore.getLabel('190')"
					prop="jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::description_display" />

				<!-- picture -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div
							class="h-6 w-6 cursor-pointer"
							@click="showDetails(row, 'optionsTabs', 0, null)">
							<img
								:class="[hasPicture(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/image-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</tw-table-column>

				<!-- video -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div
							class="h-6 w-6 cursor-pointer"
							@click="showDetails(row, 'optionsTabs', 0, null)">
							<img
								:class="[hasVideo(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/video-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</tw-table-column>

				<!-- roles -->	
				<tw-table-column
					v-if="userStore.getContactType == 'Staff'" 
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div
							class="h-6 w-6 cursor-pointer"
							@click="showDetails(row, 'optionsTabs', 1, null)">
							<img
								:class="[hasOptionRoles(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/roles-fill.svg"
								alt="picture icon">
						</div>
					</template>
				</tw-table-column>
			</tw-table>
		</div>

		<!-- roles -->
		<div 
			v-if="userStore.getContactType == 'Staff'"
			v-show="childTabs?._tabs?.[userStore.getContactType == 'Staff' ? 4 : 6]?.current">
			<tw-table
				:placeholder="t('placeholders.noRoles')"
				:data="groupedRoles"
				:loading="loading">
				<!-- role -->
				<tw-table-column 
					:header="_localesStore.getLabel('102')">
					<template #default="{row}">
						{{ row['jobs_scenes__jobs_scenes_roles_standard__ROLES_roleID::name_display'] ||
							row['jobs_scenes__jobs_scenes_roles_musician__ROLES_roleID::name_display']
						}}
					</template>
				</tw-table-column>

				<!-- instructions -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div 
							@click="showDetails(row, 'scnRolesInstructionsList', null, null)"
							class="h-6 w-6 cursor-pointer">
							<img
								:class="[ hasInstructions(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/list-bullet-fill.svg"
								alt="list icon">
						</div>
					</template>
				</tw-table-column>
			</tw-table>
		</div>

		<!-- sound -->
		<div						
			v-show="childTabs?._tabs?.[userStore.getContactType == 'Staff' ? 5 : 4]?.current">
			<!-- song -->
			<tw-table
				v-if="isSong"
				:placeholder="t('placeholders.noSongs')"
				:data="songList"
				:loading="loading">
				<!-- song -->
				<tw-table-column 
					:header="_localesStore.getLabel('177')" 
					prop="jobs_scenes__jobs_songs__SONGS__songsId::name_display" />

				<!-- order -->
				<tw-table-column 
					:header="_localesStore.getLabel('529')" 
					prop="jobs_scenes__JOBS_SONGS__jobsSceneID::order" />

				<!-- song -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<audio
							controls
							:src="row.signedUrl">
							<source
								:src="row.signedUrl"
								type="audio/mpeg">
							{{ t("placeholders.audioNotSupported") }}
						</audio>
					</template>
				</tw-table-column>

				<!-- score -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div 
							@click="showDetails(row, 'songScore', null, null)"
							class="h-6 w-6 cursor-pointer">
							<img
								:class="[songHasScores(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/music-fill.svg"
								alt="list icon">
						</div>
					</template>
				</tw-table-column>
			</tw-table>

			<!-- bgm -->
			<tw-table
				v-else-if="isBgm"
				:placeholder="t('placeholders.noBgms')"
				:data="bgmList"
				:loading="loading">
				<!-- BGM -->
				<tw-table-column 
					:header="_localesStore.getLabel('158')" 
					prop="jobs_scenes__jobs_bgms__BGMS__bgmID::name_display" />

				<!-- Genre -->
				<tw-table-column 
					:header="_localesStore.getLabel('330')" 
					prop="jobs_scenes__jobs_bgms__BGMS__bgmID::genre_display" />

				<!-- Description -->
				<tw-table-column 
					:header="_localesStore.getLabel('190')" 
					prop="jobs_scenes__jobs_bgms__BGMS__bgmID::description_display" />

				<!-- song -->
				<tw-table-column
					class-name="w-6"
					header-class-name="w-6"
					align-text="right">
					<template #default="{row}">
						<div 
							@click="showDetails(row, 'bgmSong', null, null)"
							class="h-6 w-6 cursor-pointer">
							<img
								:class="[bgmHasSongs(row) ? 'linked-icon' : 'grayed-icon']"
								src="@/assets/icons/solid/music-fill.svg"
								alt="list icon">
						</div>
					</template>
				</tw-table-column>
			</tw-table>

			<!-- live -->
			<div
				v-else-if="isLive">
				<div class="grid grid-cols-3 gap-6">
					<div class="col-span-3 lg:col-span-1">
						<!-- photo -->
						<data-display
							:label="_localesStore.getLabel('293')">
							<template #custom>
								<div
									v-if="liveFilesLoading"
									class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
								<img
									v-else-if="signedUrlLivePhoto"
									class="object-cover w-96 h-75 rounded-md"
									:src="signedUrlLivePhoto">
								<div
									v-else
									class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
									<img
										class="grayed-icon h-10 w-10"
										src="@/assets/icons/solid/image-fill.svg"
										alt="picture icon">
								</div>
							</template>
						</data-display>
					</div>
					<div class="col-span-3 lg:col-span-1">
						<!-- video -->
						<data-display
							:label="_localesStore.getLabel('294')">
							<template #custom>
								<div
									v-if="liveFilesLoading"
									class="w-96 h-75 rounded-md bg-gray-300 animate-pulse" />
								<video
									v-else-if="signedUrlLiveVideo"
									controls
									class="object-cover w-96 h-75 rounded-md">
									<source
										type="video/mp4"
										:src="signedUrlLiveVideo">
								</video>
								<div
									v-else
									class="flex justify-center items-center object-cover w-96 h-75 rounded-md bg-gray-300">
									<img
										class="grayed-icon h-10 w-10"
										src="@/assets/icons/solid/video-fill.svg"
										alt="video icon">
								</div>
							</template>
						</data-display>
					</div>
					<div class="col-span-3 lg:col-span-1">
						<div class="grid grid-cols-1">
							<div>				
								<!-- Name -->
								<data-display
									:label="_localesStore.getLabel('149')"
									:value="data?.['jobs_scenes__LIVE__jobsSceneID::name_display'] || '—'" />
							</div>
							<div
								class="mt-8">				
								<!-- Genre -->
								<data-display
									:label="_localesStore.getLabel('330')"
									:value="data?.genre_display || '—'" />
							</div>
							<div 
								v-if="userStore.getContactType == 'Staff'"
								class="col-span-1 lg:col-span-1 mt-8">
								<!-- Live Type -->
								<data-display
									:label="_localesStore.getLabel('573')"
									:value="data?.live_type_display || '—'" />
							</div>
						</div>	
					</div>				
				</div>
				<tw-table
					:placeholder="t('placeholders.noLive')"
					:data="liveList"
					:loading="loading">
					<!-- Role -->
					<tw-table-column 
						:header="_localesStore.getLabel('102')" 
						prop="jobs_scenes__jobs_live_ROLES__roleID::name_display" />

					<!-- Payment -->
					<tw-table-column 
						:header="_localesStore.getLabel('448')" 
						prop="jobs_scenes__JOBS_LIVE__jobsSceneID::cost" />

					<!-- song -->
					<tw-table-column
						class-name="w-6"
						header-class-name="w-6"
						align-text="right">
						<template #default="{row}">
							<div 
								@click="showDetails(row, 'liveSong', null, null)"
								class="h-6 w-6 cursor-pointer">
								<img
									:class="[liveHasSongs(row) ? 'linked-icon' : 'grayed-icon']"
									src="@/assets/icons/solid/music-fill.svg"
									alt="list icon">
							</div>
						</template>
					</tw-table-column>
				</tw-table>
			</div>
		</div>
	</div>	
</template>

<script lang="ts">
// name the component
	export default { name: 'SceneDetails' }
</script>

<script setup lang="ts">
	import { // languages
		useI18n
	} from 'vue-i18n'

	import { 
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { 
		getSceneDetailsByRecordId
	} from '../../../queries'

	import {
		computed,
		inject,
		onMounted,
		ref 
	} from 'vue'

	const props = defineProps({
		openToTab: {
			type: Number,
			default: 0
		},
		scene: {
			type: Object,
			default: () => {return {}}
		},
		showDetails: {
			type: Function, 
			default: () => {return}
		}
	})

	const _localesStore = localesStore()
	const userStore = _userStore()

	const {t} = useI18n()
	
	const loading = ref(false)

	const tabs = ref()

	if(userStore.getContactType == 'Staff')
	{
		tabs.value =  [
			{ name: _localesStore.getLabel('176'), current: false },  // details
			{ name: _localesStore.getLabel('156'), current: false },  // participants
			{ name: _localesStore.getLabel('394'), current: false },  // variations
			{ name: _localesStore.getLabel('175'), current: false },  // options
			{ name: _localesStore.getLabel('179'), current: false },  // roles
			{ name: _localesStore.getLabel('694'), current: false }   // sound
		]
	}
	else
	{
		tabs.value =  [
			{ name: _localesStore.getLabel('176'), current: false },  // details
			{ name: _localesStore.getLabel('156'), current: false },  // participants
			{ name: _localesStore.getLabel('394'), current: false },  // variations
			{ name: _localesStore.getLabel('175'), current: false },  // options
			{ name: _localesStore.getLabel('694'), current: false }   // sound
		]
	}


	const childTabs = ref<{_tabs: []}>({_tabs: []})
	const standardRoles = ref()
	const musicianRoles = ref()

	const groupedRoles = computed(() => {
		let headings = []

		if(standardRoles.value?.length) {
			headings.push(
				{subheading: 'Standard Roles'},
				...standardRoles.value
			)
		}

		if(musicianRoles.value?.length) {
			headings.push(
				{subheading: 'Musician Roles'},
				...musicianRoles.value
			)
		}
		
		return headings
	})

	const participants = ref()
	const variations = ref()
	const options = ref()
	const songList = ref()
	const bgmList = ref()
	const liveList = ref()
	const data = ref()

	
	const filesLoading = ref(true)
	const liveFilesLoading = ref(true)
	const s3: any = inject('s3')
	const signedUrlPhoto = ref('')
	const signedUrlVideo = ref('')
	const signedUrlLivePhoto = ref('')
	const signedUrlLiveVideo = ref('')

	onMounted(async () => {
		// set active tab
		tabs.value[props.openToTab].current = true
		
		await fetchSceneDetails()

		fetchSceneMedia()

		if(isSong.value) { 
			fetchSongMedia()
		}
		else if(isLive.value) { 
			fetchLiveMedia()
		}
	})

	async function fetchSceneDetails() {
		try { // fetching scene details
			loading.value = true

			const recordId = props.scene.recordId
			const contactId = userStore.user.contactId
			const sessionId = userStore.user.sessionId
			const jobId = props.scene['job__JOBS_SCENES__jobID::_kf_jobs_id']
			const scriptParams = JSON.stringify({
				language: _localesStore.getFmLanguage,
				sessionId,
				contactId,
				jobId
			})

			const params = `?script.prerequest=${encodeURI("Set Language")}&script.prerequest.param=${encodeURI(scriptParams)}`
			let res = await getSceneDetailsByRecordId(recordId, params)
			const record = res.response.data[0]
			
			data.value = record.fieldData
			participants.value = record.portalData.jobs_scenes__JOBS_PARTICIPANTS__jobsSceneID
			variations.value = record.portalData.jobs_scenes__JOBS_VARIATIONS__jobsSceneID
			options.value = record.portalData.jobs_scenes__JOBS_SCENES_OPTIONS__jobsSceneID
			standardRoles.value = userStore.getContactType == 'Staff' ? record.portalData.standard_roles_staff : record.portalData.jobs_scenes__JOBS_SCENES_ROLES__jobsSceneStandardID
			musicianRoles.value = userStore.getContactType == 'Staff' ? record.portalData.musician_roles_staff : record.portalData.jobs_scenes__JOBS_SCENES_ROLES__jobsSceneInstrumentalID
			songList.value = record.portalData.jobs_scenes__JOBS_SONGS__jobsSceneID
			bgmList.value = record.portalData.jobs_scenes__JOBS_BGMS__jobsSceneID
			liveList.value = record.portalData.jobs_scenes__JOBS_LIVE__jobsSceneID
		}
		catch(e) {
			console.error(e)
		}
		finally {
			loading.value = false
		}
	}

	async function fetchSceneMedia() {
		try { // fetching scene image and video from s3
			filesLoading.value = true
			const photoS3Json = JSON.parse(props.scene?.['job__job_scene__CONTACTS_SCENES__contactSceneID::s3JSONPicture'] || "{}")
			const photoKey = photoS3Json?.s3FilePathShortRaw
			const videoS3Json = JSON.parse(props.scene?.['job__job_scene__CONTACTS_SCENES__contactSceneID::s3JSONVideo'] || "{}")
			const videoKey = videoS3Json?.s3FilePathShortRaw
			
			if(photoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: photoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlPhoto.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
			if(videoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: videoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlVideo.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
		}
		catch(e) {
			console.error(e)
		}
		finally {
			filesLoading.value = false
		}
	}

	async function fetchSongMedia() {
		try { // fetching song files from s3
			songList.value.forEach((sound: any) => {
				const soundS3Json = JSON.parse(sound?.['jobs_scenes__jobs_songs__songs__SONGS_INFO__songsId::s3JSONSong'] || "{}")
				const soundKey = soundS3Json?.s3FilePathShortRaw
				
				if(soundKey) {
					let params = {
						Bucket: process.env.VUE_APP_S3_BUCKET,
						Key: soundKey
					}
		
					s3.getSignedUrlPromise('getObject', params).then(
						function (url: string) {
							sound.signedUrl = url
						}, 
						function (err: Error) { 
							throw err
						}
					)
				}
			})
		}
		catch(e) {
			console.error(e)
		}
	}

	async function fetchLiveMedia() {
		try { // fetching live image and video from s3
			liveFilesLoading.value = true
			const livePhotoS3Json = JSON.parse(props.scene?.["job__job_scene__LIVE__liveID::s3JSONPicture"] || "{}")
			const livePhotoKey = livePhotoS3Json?.s3FilePathShortRaw
			const liveVideoS3Json = JSON.parse(props.scene?.["job__job_scene__LIVE__liveID::s3JSONVideo"] || "{}")
			const liveVideoKey = liveVideoS3Json?.s3FilePathShortRaw
			
			if(livePhotoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: livePhotoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlLivePhoto.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
			if(liveVideoKey) {
				let params = {
					Bucket: process.env.VUE_APP_S3_BUCKET,
					Key: liveVideoKey
				}
	
				s3.getSignedUrlPromise('getObject', params).then(
					function (url: string) {
						signedUrlLiveVideo.value = url
					}, 
					function (err: Error) { 
						throw err
					}
				)
			}
		}
		catch(e) {
			console.error(e)
		}
		finally {
			liveFilesLoading.value = false
		}
	}
	
	function hasPicture(row: any) {
		return row?.['jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::s3JSONPicture'] ||
			row?.['jobs_scenes__jobs_scenes_options__CONTACTS_SCENES_OPTIONS__contactsScenesOptionsID::s3JSONPicture']
	}

	function hasVideo(row: any) {
		return row?.['jobs_scenes__jobs_scenes_options__SERVICES_OPTIONS__servicesOptionsID::s3JSONVideo'] ||
			row?.['jobs_scenes__jobs_scenes_options__CONTACTS_SCENES_OPTIONS__contactsScenesOptionsID::s3JSONVideo']
	}
	// Update when field is known
	function hasOptionRoles(row: any) {
		return row?.['jobs_scenes__jobs_scenes_options__JOBS_SCENES_OPTIONS_ROLES__jobsScenesOptionsInstrumentalID::_kp_jobs_scenes_options_roles_id'] ||
			row?.['jobs_scenes__jobs_scenes_options__JOBS_SCENES_OPTIONS_ROLES__jobsScenesOptionsStandardID::_kp_jobs_scenes_options_roles_id']
	}

	// Update when field is known
	function hasRoles(row: any) {
		return row?.['jobs_scenes__jobs_scenes_options__JOBS_SCENES_OPTIONS_ROLES__jobsScenesOptionsInstrumentalID::_kp_jobs_scenes_options_roles_id'] ||
			row?.['jobs_scenes__jobs_scenes_options__JOBS_SCENES_OPTIONS_ROLES__jobsScenesOptionsStandardID::_kp_jobs_scenes_options_roles_id']
	}

	// Update when field is known
	function hasInstructions(row: any) {
		return row?.['jobs_scenes__jobs_scenes_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesRolesID_standard::_kp_instructions_join_jobs_id'] ||
			row?.['jobs_scenes__jobs_scenes_roles__INSTRUCTIONS_JOIN_JOBS_jobsScenesRolesID_instrumental::_kp_instructions_join_jobs_id'] 
	}
	
	const isSong = computed(() => {
		return props.scene?.['job__JOBS_SCENES__jobID::sound_choice'].toLowerCase().includes('song')
	})

	const isBgm = computed(() => {
		return props.scene?.['job__JOBS_SCENES__jobID::sound_choice'].toLowerCase().includes('bgm')
	})

	const isLive = computed(() => {
		return props.scene?.['job__JOBS_SCENES__jobID::sound_choice'].toLowerCase().includes('live')
	})

	function songHasScores(row: any) {
		return row?.['jobs_scenes__JOBS_SONGS_SCORES__jobsSceneID::_kp_jobs_songs_scores_id']
	}
	
	function bgmHasSongs(row: any) {
		return row?.['jobs_scenes__jobs_bgms__jobs_bgms_songs__SONGS__songsId::_kp_songs_id']
	}

	function liveHasSongs(row: any) {
		return row?.['jobs_scenes__jobs_live__LIVE_SONGS__jobsLiveID::_kp_live_songs_id']
	}
</script>