<template>
	<div class="grid grid-cols-3 gap-4 divide-y lg:divide-y-0 lg:divide-x px-4">
		<div class="col-span-3 lg:col-span-1">
			<div class="grid grid-cols-2 gap-4">
				<div class="col-span-1">
					<!-- groom or contact 1-->
					<data-display
						:loading="loading"
						:label="isWeddingType || isWeddingCategory ? store.getLabel('238') : store.getLabel('628')"
						:value="job?.fieldData?.name_groom_display || '—'" />
				</div>
				<div 
					v-if="isWeddingType"
					class="col-span-1">
					<!-- groom count -->
					<data-display
						:loading="loading"
						:label="store.getLabel('240')"
						:value="job?.fieldData?.groom_side_count || '—'" />
				</div>
				<div class="col-span-1">
					<!-- bride or contact 2-->
					<data-display
						:loading="loading"
						:label="isWeddingType || isWeddingCategory ? store.getLabel('237') : store.getLabel('629')"
						:value="job?.fieldData?.name_bride_display || '—'" />
				</div>
				<div 
					v-if="isWeddingType"
					class="col-span-1">
					<!-- bride count -->
					<data-display
						:loading="loading"
						:label="store.getLabel('241')"
						:value="job?.fieldData?.bride_side_count || '—'" />
				</div>
				<div
					v-if="isWeddingType || isWeddingCategory" 
					class="col-span-1">
					<!-- married name -->
					<data-display
						:loading="loading"
						:label="store.getLabel('239')"
						:value="job?.fieldData?.married_name_display || '—'" />
				</div>
			</div>
		</div>
		<div class="col-span-3 lg:col-span-1 pt-4 lg:pl-4 lg:pt-0">
			<div class="grid grid-cols-2 gap-4">
				<div class="col-span-1">
					<data-display>
						<template #custom>
							<div
								class="flex justify-start gap-2 items-center"
								:class="{'opacity-30': !job?.fieldData?.rehearsal_checkbox}">
								<CheckIconMini
									v-if="job?.fieldData?.rehearsal_checkbox"
									class="h-5 w-5" />
								<MinusIconMini
									v-else
									class="h-5 w-5" />
								<!-- rehearsal -->
								<span class="font-medium">{{ store.getLabel('244') }}</span>
							</div>
						</template>
					</data-display>
				</div>
				<div class="col-span-1">
					<data-display>
						<template #custom>
							<div
								class="flex justify-start gap-2 items-center"
								:class="{'opacity-30': !job?.fieldData?.demo_checkbox}">
								<CheckIconMini
									v-if="job?.fieldData?.demo_checkbox"
									class="h-5 w-5" />
								<MinusIconMini
									v-else
									class="h-5 w-5" />
								<!-- demo -->
								<span class="font-medium">{{ store.getLabel('319') }}</span>
							</div>
						</template>
					</data-display>
				</div>
				<div class="col-span-1">
					<!-- date rehearsal-->
					<data-display
						:loading="loading"
						:label="store.getLabel('242')"
						:value="job?.fieldData?.rehearsal_checkbox ? job?.fieldData?.date_rehearsal || '—' : '—'" />
				</div>
				<div class="col-span-1">
					<!-- adults count -->
					<data-display
						:loading="loading"
						:label="store.getLabel('245')"
						:value="job?.fieldData?.rehearsal_checkbox ? rehearsalCountAdults : '—'" />
				</div>
				<div class="col-span-1">
					<!-- time rehearsal -->
					<data-display
						:loading="loading"
						:label="store.getLabel('243')"
						:value="job?.fieldData?.rehearsal_checkbox ? $filters.hour24Time(job?.fieldData?.time_rehearsal) || '—' : '—'" />
				</div>
				<div class="col-span-1">
					<!-- kids count -->
					<data-display
						:loading="loading"
						:label="store.getLabel('246')"
						:value="job?.fieldData?.rehearsal_checkbox ? rehearsalCountKids : '—'" />
				</div>
			</div>
		</div>
		<div class="col-span-3 lg:col-span-1">
			<div class="col-span-3 lg:col-span-1">
				<div class="grid grid-cols-2 gap-4 pt-4 lg:pl-4 lg:pt-0">
					<div class="col-span-1">
						<!-- status -->
						<data-display
							:loading="loading"
							:label="store.getLabel('196')"
							:value="job?.fieldData?.status_display || '—'" />
					</div>
					<div class="col-span-1">
						<!-- arrival time -->
						<data-display
							:loading="loading"
							:label="store.getLabel('233')"
							:value="$filters.hour24Time(job?.fieldData?.time_arrival) || '—'" />
					</div>
					<div class="col-span-1">
						<!-- start date -->
						<data-display
							:loading="loading"
							:label="store.getLabel('143')"
							:value="job?.fieldData?.date_start || '—'" />
					</div>
					<div class="col-span-1">
						<!-- standby time -->
						<data-display
							:loading="loading"
							:label="store.getLabel('234')"
							:value="$filters.hour24Time(job?.fieldData?.time_standby) || '—'" />
					</div>
					<div class="col-span-1">
						<!-- start time -->
						<data-display
							:loading="loading"
							:label="store.getLabel('144')"
							:value="$filters.hour24Time(job?.fieldData?.time_start) || '—'" />
					</div>
					<div class="col-span-1">
						<!-- final time -->
						<data-display
							:loading="loading"
							:label="store.getLabel('499')"
							:value="$filters.hour24Time(job?.fieldData?.time_final) || '—'" />
					</div>
					<div class="col-span-1">
						<!-- planner or organizer-->
						<data-display
							:loading="loading"
							:label="isWeddingType || isWeddingCategory ? store.getLabel('140') : store.getLabel('636')"
							:value="job?.fieldData?.['job__CONTACTS__plannerID::name_display']?.trim() || '—'" />
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="userStore.getContactType != 'Staff'"
			class="w-full mt-6">
			<!-- notes -->
			<data-display
				:loading="loading"
				:label="store.getLabel('205')"
				:value="job?.fieldData?.['job__JOBS_INFO__jobID::notes_jobs_client_display'] || '—'" />
		</div>
	</div>
</template>

<script lang="ts">
	export default { name: 'JobAdditionalDetails' } // name the component
</script>

<script setup lang="ts">
	import { // store
		localesStore
	} from '@/store/locales'

	import { 
		store as _userStore
	} from '@/store/user'

	import { // vue
		computed
	} from 'vue'

	const props = defineProps({
		job: {
			type: Object,
			default: () => {return {}}
		},
		loading: {
			type: Boolean,
			default: false
		}
	})

	const store = localesStore()
	const userStore = _userStore()

	const isWeddingCategory = computed(() => {
		return props?.job?.fieldData?.category?.toLowerCase()?.includes('wedding') 
	})

	const isWeddingType = computed(() => {
		return props?.job?.fieldData?.type?.toLowerCase()?.includes('wedding')
	})

	const rehearsalCountAdults = computed(() => {
		if(props?.job?.fieldData?.rehearsal_adults_count > -1) return props?.job?.fieldData?.rehearsal_adults_count
		else if(props?.job?.fieldData?.rehearsal_adults_count_party > -1) return props?.job?.fieldData?.rehearsal_adults_count_party
		else return ''
	}
	)
	const rehearsalCountKids = computed(() => {
		if(props?.job?.fieldData?.rehearsal_kids_count > -1) return props?.job?.fieldData?.rehearsal_kids_count
		else if(props?.job?.fieldData?.rehearsal_kids_count_party > -1) return props?.job?.fieldData?.rehearsal_kids_count_party
		else return ''
	})
</script>