<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
	<div v-if="_tabs.length">
		<div
			class="sm:hidden"
			id="tabs-select">
			<label
				for="tabs"
				class="sr-only">Select a tab</label>
			<!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
			<select
				id="tabs"
				name="tabs"
				class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-brand-500 focus:outline-none focus:ring-brand-500 sm:text-sm"
				@change="(e) => changeTab(e.target.value)">
				<option
					v-for="(tab, i) in _tabs"
					:value="i"
					:key="tab.name"
					:selected="tab.current"
					@change="(e) => changeTab(i)">
					{{ tab.name }}
				</option>
			</select>
		</div>
		<div class="hidden sm:block">
			<div class="border-b border-gray-200">
				<nav
					class="flex space-x-8"
					aria-label="Tabs">
					<a
						v-for="(tab, i) in _tabs"
						:key="tab.name"
						@click="changeTab(i)"
						class="cursor-pointer"
						:class="[tab.current ? 'border-brand-500 text-brand-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium']"
						:aria-current="tab.current ? 'page' : undefined">{{ tab.name }}</a>
				</nav>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
// name the component
	export default { name: 'TwTabsUnderline' }
</script>

<script setup lang="ts">

	import {
		defineExpose,
		ref,
		PropType
	} from 'vue'

	interface tab {
		current: boolean,
		name: string
	}

	const props = defineProps({
		tabs: {
			type: Array as PropType<Array<tab>>,
			default: () => {return []}
		}
	})
	

	const _tabs = ref<tab[]>(props.tabs)

	function changeTab(i: number) {
		_tabs.value.forEach(tab => {tab.current = false})
		
		_tabs.value[i].current = true
	}

	defineExpose({
		_tabs
	})
</script>