<template>
	<div>
		<h1 class="text-2xl font-medium text-gray-900">
			{{ t('resetPassword.resetYourPassword') }}
		</h1>
	</div>

	<form
		class="mt-8"
		@submit.prevent="resetPassword">
		<div class="text-sm">
			<tw-input
				v-model="email"
				id="email"
				:required="true"
				type="email"
				:label="t('resetPassword.email')"
				:validation="t('login.emailValidation')"
				class="text-md" />
		</div>

		<div class="mt-4">
			<tw-button 
				class="w-full btn-brand font-medium"
				:disabled="resetLoading"
				type="submit">
				{{ t('resetPassword.submit') }}
			</tw-button>
		</div>

		<div class="flex items-center mt-3 text-sm text-brand-600">
			<div class="mx-auto">
				<router-link
					class="link"
					:to="{name: 'Login'}">
					{{ t('resetPassword.returnToLoginScreen') }}
				</router-link>
			</div>
		</div>
	</form>
</template>

<script lang="ts">
	export default { name: 'ResetPasswordForm' } // name the component
</script>

<script setup lang="ts">
	import { 
		Auth
	} from '@/api/Auth'

	import { 
		useI18n
	} from 'vue-i18n'

	import {
		useRouter
	} from 'vue-router'
	
	import { 
		ref
	} from 'vue'

	const {t} = useI18n()

	const router = useRouter()
	const email = ref("")
	const resetLoading = ref(false)

	async function resetPassword() { 
		resetLoading.value = true
		if (email.value == "") {
			document.getElementById('email')?.focus()
			return
		}

		try {
			await Auth.forgotPassword(email.value)
			resetLoading.value = false
			router.push({name: 'ResetPasswordSuccess'})
		}
		catch(e) {
			console.log(e)
			resetLoading.value = false
			// do something with the error
		}
	}
</script>